import api, {BaseResponse, RequestMethod} from "../api";
import {CreditScheduleState}              from "pages/Specialist/Credits/elements/CreditScheduleItem";
import {AppMode}                          from "store/features";


interface CreditsList {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  color: string;
  buttons: string[];
}

interface SchedulePaymentItem {
  id: number;
  date: string;
  sum: number;
  state: CreditScheduleState;
}

interface CreditsResponse {
  credits: CreditsList[];
  tabs: {
    id: number;
    name: string;
    is_selected: boolean;
  }[];
}

interface CreditScheduleResponse {
  payments: SchedulePaymentItem[];
  buttons: string[];
  subtitle: string;
}

const credits = api.injectEndpoints({
  endpoints: (build) => ({
    getCreditsList:            build.query<BaseResponse<CreditsResponse>, { id?: number, appMode: AppMode }>({
      query:             ({id, appMode}) => ({
        url:    appMode === AppMode.SPECIALIST ? "pro/balance/credit" : "app/balance/credit",
        method: RequestMethod.PUT,
        body:   {tab_id: id},
      }),
      keepUnusedDataFor: 0,
    }),
    getCreditPaymentsSchedule: build.query<BaseResponse<CreditScheduleResponse>, number>({
      query:             (id) => ({
        url:    "pro/balance/credit/payments/schedule",
        method: RequestMethod.PUT,
        body:   {credit_id: id},
      }),
      keepUnusedDataFor: 0,
    }),
    creditCartInit:            build.mutation<BaseResponse<{ payment_id: string }>, { id: number, type: string }>({
      query: ({id, type}) => ({
        url:    "pro/balance/credit/cart/init",
        method: RequestMethod.PUT,
        body:   {credit_id: id, type},
      }),
    }),
  }),
});

export const {
  useGetCreditsListQuery,
  useGetCreditPaymentsScheduleQuery,
  useCreditCartInitMutation,
} = credits;