import React, {useEffect, useState}                                  from "react";
import {SubmitHandler, useForm}                                      from "react-hook-form";
import {useTranslation}                                              from "react-i18next";
import {WrongPhoneNumberInApp}                                       from "components/WrongPhoneNumberInApp";
import {Props}                                                       from "components/SSNRegistration";
import {CloseRegistrationInApp}                                      from "components/CloseRegistrationInApp";
import {TextBlock, BaseInput, InputPhone, ButtonDefault, PageHeader} from "shared/ui";
import {useAppSelector}                                              from "store";
import {selectMethodType, selectRegistrationInfo}                    from "store/features";
import {RegistrationMethodType, useSendEgovSmsMutation}              from "services/registration";
import {SsnExistInApp}                                               from "../SsnExistInApp";
import {InAppLayout, MainLayout}                                     from "entities/general";
import styles                                                        from "./styles.module.scss";


interface Inputs {
  ssn: string;
  phone: string;
}

export default function SSNRegistration({
  text,
  navSubtitle,
  onConfirmClick = () => {
  },
  onSetData,
  hasTryAgain = false,
}: Props) {
  const {t} = useTranslation();
  const methodType = useAppSelector(selectMethodType);
  const registrationInfo = useAppSelector(selectRegistrationInfo);

  const [sendSmsEgov, {data, isSuccess, isLoading}] = useSendEgovSmsMutation();
  const [wrongNumberInApp, setWrongNumberInApp] = useState(false);
  const [ssnExistInApp, setSsnExistInApp] = useState(false);
  const [ssnBlockedInApp, setSsnBlockedInApp] = useState(false);

  const {register, watch, setError, trigger, formState: {errors, isValid}} = useForm<Inputs>({
    defaultValues: {
      ssn:   registrationInfo?.ssn ?? "",
      phone: registrationInfo?.phone?.slice(2, registrationInfo?.phone?.length) ?? "",
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => sendSmsEgov({ssn: data.ssn, phone: data.phone});

  const onHandleSubmit = () => {
    const data = {
      ssn:   watch("ssn"),
      phone: watch("phone").replace(/\D/g, ""),
    };
    onSubmit(data);
  }

  const handleCancel = () => {
    setSsnBlockedInApp(false);
  }

  useEffect(() => {
    if (hasTryAgain) {
      onHandleSubmit();
      onConfirmClick();
    }
  }, [hasTryAgain]);

  useEffect(() => {
    if (isSuccess && data && data.content) {
      onSetData && onSetData(data?.content.request_id);
      onConfirmClick();
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (data && data.errors) {
      if (data.errors.profile === "another_phone") {
        return setWrongNumberInApp(true);
      }
      if (data.errors.profile === "ssn_exists") {
        return setSsnExistInApp(true);
      }
      if (data.errors.profile === "ssn_blocked") {
        return setSsnBlockedInApp(true);
      }
    }
    if (data?.errors) {
      for (const [fieldName, message] of Object.entries(data.errors)) {
        setError(fieldName as any, {
          type: "server",
          message,
        });
      }
    }
  }, [data?.errors]);

  useEffect(() => {
    if (methodType !== RegistrationMethodType.VERIFICATION) return;
    if (registrationInfo?.phone && registrationInfo.phone) {
      trigger("ssn");
      trigger("phone");
    }
  }, [methodType, registrationInfo]);

  return <MainLayout>
    <PageHeader
      title={methodType === RegistrationMethodType.VERIFICATION ? t("page.verification.header") : t("page.registration.header")}
      subtitle={navSubtitle}
      rightIcon={<CloseRegistrationInApp />}
    />

    <div className={styles.content}>
      <BaseInput
        label={t("page.registration.egov.form.ssn_label")}
        maxLength={12}
        max={12}
        type="tel"
        error={!!errors.ssn}
        labelIcon="asterix"
        disabled={methodType === RegistrationMethodType.VERIFICATION}
        placeholder={t("page.registration.egov.form.ssn_placeholder")}
        subtext={errors.ssn ? errors.ssn.message : t("page.registration.egov.form.ssn_subtext")}
        {...register("ssn", {required: true, minLength: 12, maxLength: 12})}
      />

      <InputPhone
        label={t("page.registration.egov.form.phone_label")}
        labelIcon="asterix"
        icon={<div className={styles.phone_prefix + " mgr-2"}>
          <img src={"images/country_kz.svg"} className={styles.phone_prefix_icon} alt="img" />
        </div>}
        placeholder="000 000-00-00"
        mask={"000 000-00-00"}
        error={!!errors.phone}
        subtext={errors.phone && errors.phone.message}
        type="tel"
        {...register("phone", {
          required:  true,
          minLength: methodType === RegistrationMethodType.VERIFICATION ? undefined : 13,
        })}
      />

      <TextBlock description={text} />
    </div>

    <div className={styles.footer}>
      <ButtonDefault
        disabled={!isValid}
        loading={isLoading}
        onClick={onHandleSubmit}
      >
        {t("common.next")}
      </ButtonDefault>

      <WrongPhoneNumberInApp open={wrongNumberInApp} onClose={() => setWrongNumberInApp(false)} />

      <SsnExistInApp
        ssn={watch("ssn")}
        open={ssnExistInApp}
        onClose={() => setSsnExistInApp(false)}
      />

      <InAppLayout
        isOpen={ssnBlockedInApp}
        img={"/illustrations/no-conversation-3.svg"}
        title={t("in_app.ssn_blocked.title")}
        description={t("in_app.ssn_blocked.description")}
        onClose={handleCancel}
      >
        <ButtonDefault hasSpace onClick={handleCancel}>
          {t("in_app.ssn_blocked.button")}
        </ButtonDefault>
      </InAppLayout>
    </div>
  </MainLayout>
}