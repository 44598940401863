import {useState, useEffect} from "react";

export function useFakeProgressBar({isSuccess}: { isSuccess: boolean }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress >= 100) return;

    const interval = setInterval(() => {
      setProgress(prev => {
        const increment = isSuccess
          ? Math.random() * 50 + 1
          : Math.random() * (2 - prev / 100);

        const nextProgress = Math.min(prev + increment, isSuccess ? 100 : 99);

        return nextProgress;
      });
    }, 300);

    return () => clearInterval(interval);
  }, [progress, isSuccess]);

  return {progress: `${Math.floor(progress)}%`, completeProgress: progress === 100}
}
