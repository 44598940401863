export function checkProgress(starCount?: number, count?: number) {
  if (!starCount || !count) return 0;
  const percentage = Math.round(count / starCount * 100);
  return percentage === 0 ? 1 : percentage;
}

export function formatRate(rate?: number) {
  if (typeof rate === "undefined") return "0";
  if (rate === null) return "0.0";
  if (rate === 0) return "0.0";

  if (Number.isInteger(rate) && rate !== 0) {
    return `${rate}.0`;
  }

  return rate.toString();
}