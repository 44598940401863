import React, {useEffect, useState}     from "react";
import {useParams}                      from "react-router-dom";
import {useTranslation}                 from "react-i18next";
import {LoadingScreen, PageLayout}      from "entities/general";
import {
  AboutBlock,
  AlertBlock,
  AvatarBlock,
  MediaBlock,
  PricesBlock,
  ProfileSettings,
  SpecialistProfileReviews,
}                                       from "widgets/specialist/profile";
import {SpecialistProfileFavourite}     from "features/specialist/profile";
import {ProfileFooter}                  from "features/client/profile";
import {AppMode}                        from "store/features";
import {
  defineOpenShareLinkType,
  setActionForNative,
  useCheckPaymentStatus,
  useNativeHandler,
  useSetHeaders,
  useSkeletonThrottling,
}                                       from "shared/lib";
import {Icon, InApp}                    from "shared/ui";
import {
  NativeActions,
  selectMyAnketyOpenedIsSend,
  selectProfile,
  selectProfileIsCashed,
  setMyAnketyOpenedIsSend,
  setProfile,
  setProfileIsCashed,
}                                       from "shared/model";
import {useAppDispatch, useAppSelector} from "store";
import {fetchProfile}                   from "./lib/fetchProfile";
import styles                           from "./SpecialistProfile.module.scss";


export default function SpecialistProfile() {
  const {t} = useTranslation();
  const {id: paramId} = useParams();
  const {isSuccess, appMode, ticketId, isDemo, workId, entryPoint} = useSetHeaders();
  const id = paramId ? parseInt(paramId) : null;
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const hasUpdate = useAppSelector(selectProfileIsCashed);
  const analtyicsIsSend = useAppSelector(selectMyAnketyOpenedIsSend);

  const {fetchSpecialistProfile, profile: fetchedProfile, isLoading} = fetchProfile({appMode});

  useEffect(() => {
    if (fetchedProfile) {
      dispatch(setProfile({content: fetchedProfile.content, ui: fetchedProfile.ui}));
    }
  }, [fetchedProfile]);

  const {extendedLoading} = useSkeletonThrottling({isLoading});

  useEffect(() => {
    if (profile && !extendedLoading) {
      dispatch(setProfileIsCashed(true));
    }
  }, [profile, extendedLoading]);

  const {paymentStatusLoading} = useCheckPaymentStatus({
    refetch: () => fetchSpecialistProfile({
      specialist_id: id,
      ticket_id:     ticketId,
      work_id:       workId,
    }),
    mode:    appMode === AppMode.SPECIALIST ? "pro" : "app",
  });

  const [openSettings, setOpenSettings] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);

  const handleToggleSettings = () => {
    if (extendedLoading) return;
    setOpenSettings(prev => !prev);
  }

  const handleBackClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleToggleClientWatch = () => {
    setIsViewMode(prev => !prev);
    setOpenSettings(false);
  }

  const rightIcon = () => {
    if (appMode === AppMode.SPECIALIST && !id) {
      return <Icon onClick={handleToggleSettings}>more_horizontal</Icon>;
    }
    if (appMode === AppMode.CLIENT) {
      return <SpecialistProfileFavourite
        isLoading={extendedLoading}
        id={id}
        workId={workId}
        isFavourite={profile?.content.specialist?.is_favourite}
      />
    }
    return <Icon onClick={handleBackClick}>close</Icon>;
  }

  const handleOpenShare = () => {
    if (extendedLoading) return;
    setActionForNative
    (NativeActions.OPEN_SHARE,
      {text: t(`page.specialist.profile.more.open_share`, {link: defineOpenShareLinkType(profile?.content.specialist?.id)})},
    );
  }

  const handleAndroidBackTapClick = () => {
    if (openSettings) {
      return handleToggleSettings();
    }
    if (isViewMode) {
      return handleToggleClientWatch();
    }
    return setActionForNative(NativeActions.TERMINATE);
  }

  useEffect(() => {
    if (profile) return;
    if (!isSuccess) return;
    if (appMode === AppMode.CLIENT) {
      if (!id) return;
      fetchSpecialistProfile({specialist_id: id, ticket_id: ticketId, work_id: workId});
    }
    if (appMode === AppMode.SPECIALIST) {
      fetchSpecialistProfile({specialist_id: id});
    }
  }, [appMode, isSuccess, id, workId, ticketId, profile]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (profile && profile.content && profile.content.photos && profile?.content?.photos?.length > 0) return;

    handleAndroidBackTapClick();
  });

  useEffect(() => {
    if (!appMode || appMode === AppMode.CLIENT) return;
    if (analtyicsIsSend) return;
    const entry = entryPoint && entryPoint !== "" ? entryPoint : "none";
    setActionForNative(NativeActions.ANALYTICS, {
      event:      "specialist my ankety opened",
      properties: {
        "entry point": entry,
      },
    });
    dispatch(setMyAnketyOpenedIsSend(true));
  }, [analtyicsIsSend, appMode, entryPoint]);

  useEffect(() => {
    if (!appMode || appMode === AppMode.SPECIALIST) return;
    if (analtyicsIsSend) return;

    if (fetchedProfile?.analytics) {
      const entry = entryPoint && entryPoint !== "" ? {"entry point": entryPoint} : {"entry point": "none"};
      const analytics = {...fetchedProfile.analytics, ...entry};

      setActionForNative(NativeActions.ANALYTICS, {
        event:      "client specialist ankety opened",
        properties: analytics,
      });
      dispatch(setMyAnketyOpenedIsSend(true));
    }
  }, [analtyicsIsSend, appMode, entryPoint, fetchedProfile?.analytics]);

  return <>
    {paymentStatusLoading && <LoadingScreen />}

    {!profile?.content.specialist && profile?.content.alert
      ? <AlertBlock alert={profile.content.alert} state={profile.content.state} appMode={appMode} />

      : <PageLayout
        bgColor="#F7F7F9"
        rightIcon={rightIcon()}
        rightIcon2={appMode === AppMode.CLIENT ? <Icon onClick={handleOpenShare}>forward</Icon> : undefined}
        leftIcon={appMode === AppMode.SPECIALIST && id ? null :
          <Icon onClick={handleBackClick}>keyboard_arrow_left</Icon>}
        toastPosition={(!profile?.ui?.input || !profile?.ui) ? 12 : 82}
        footer={(!profile?.ui?.input || !profile?.ui || profile.ui.is_blocked)
          ? undefined
          : <ProfileFooter
            isDemo={isDemo}
            alert={profile.content.alert}
            isLoading={hasUpdate ? false : extendedLoading}
            id={profile.content.specialist?.id}
            workId={workId}
            ticketId={profile.content.ticket_id ?? ticketId}
            croppedPhone={profile.content.specialist?.phone}
            ui={profile?.ui}
          />}
      >
        <div>
          <AvatarBlock
            labels={profile?.content.specialist?.labels}
            appMode={appMode}
            isLoading={hasUpdate ? false : extendedLoading}
          />

          <div className={styles.content}>
            <AboutBlock
              about={profile?.content.specialist?.about}
              moderated={profile?.content.specialist?.about_moderated}
              appMode={appMode}
              id={id}
              isLoading={hasUpdate ? false : extendedLoading}
            />

            <SpecialistProfileReviews
              id={id}
              appMode={appMode}
              reviews={profile?.content.review}
              isLoading={hasUpdate ? false : extendedLoading}
            />

            <MediaBlock
              id={id}
              appMode={appMode}
              media={profile?.content.photos}
              isLoading={hasUpdate ? false : extendedLoading}
              onBackClick={handleAndroidBackTapClick}
            />

            <PricesBlock
              id={id}
              appMode={appMode}
              prices={profile?.content.prices}
              isLoading={hasUpdate ? false : extendedLoading}
            />
          </div>
        </div>
      </PageLayout>}

    <ProfileSettings
      id={profile?.content.specialist?.id}
      isOpen={openSettings}
      onToggle={handleToggleSettings}
      onToggleClientWatch={handleToggleClientWatch}
    />

    <InApp open={isViewMode} onClose={handleToggleClientWatch}>
      <div className={styles.client_view_wrapper}>
        <AvatarBlock
          labels={profile?.content.specialist?.labels}
          appMode={appMode}
          isViewMode
        />

        <div className={styles.content}>
          <AboutBlock
            about={profile?.content.specialist?.about}
            moderated={profile?.content.specialist?.about_moderated}
            appMode={appMode}
            id={id}
            isViewMode
          />

          <SpecialistProfileReviews
            appMode={appMode}
            id={id}
            reviews={profile?.content.review}
            isViewMode
          />

          <MediaBlock
            media={profile?.content.photos}
            id={id}
            appMode={appMode}
            isViewMode
            isLoading={false}
          />

          <PricesBlock
            prices={profile?.content.prices}
            appMode={appMode}
            id={id}
            isViewMode
          />
        </div>
      </div>
    </InApp>
  </>
}