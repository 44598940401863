import {useEffect, useRef} from "react";

type FunctionType = (...args: any[]) => void;
type TimerType = ReturnType<typeof setTimeout>;

export function useDebounce(foo: FunctionType, delay = 1000) {
  const timer = useRef<TimerType>();

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current)
    }
  }, []);

  return ((...args) => {
    const newTimer = setTimeout(() => {
      foo(...args);
    }, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  }) as FunctionType
}