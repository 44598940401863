import React            from "react";
import styles           from "./styles.module.scss";
import {SkeletonLayout} from "../../../entities/skeleton";


interface Props {
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  rightIcon2?: React.ReactNode;
  title?: string;
  subtitle?: string;
  hasClose?: boolean;
  hasSpace?: boolean;
  isLoading?: boolean;
}

export default function PageHeader({
  title,
  subtitle,
  icon,
  rightIcon,
  rightIcon2,
  hasSpace = true,
  isLoading = false,
}: Props) {
  return <div className={hasSpace ? `${styles.wrapper} ${styles.wrapper_space}` : styles.wrapper}>
    <div className={styles.icon_holder}>{icon ?? ""}</div>

    {!isLoading
      ? <div className={!icon ? `${styles.text} ${styles.text_margin}` : styles.text}>
        <div className={styles.text_title}>{title ?? " "}</div>
        {subtitle && <div className={styles.text_subtitle}>{subtitle}</div>}
      </div>

      : <div className={styles.loading_wrapper}>
        <SkeletonLayout height={22} width={200} borderRadius={12} theme="dark" />
      </div>}

    <div className={styles.right_icons_wrapper}>
      <div className={styles.icon_holder}>{rightIcon ?? ""}</div>
      {rightIcon2 && <div className={styles.icon_holder}>{rightIcon2 ?? ""}</div>}
    </div>
  </div>
}