import React           from "react";
import ButtonBase      from "../ButtonBase";
import BaseButtonProps from "../BaseButtonProps";
import styles          from "./styles.module.scss";


interface Props extends Omit<BaseButtonProps, "children" | "buttonRound"> {
  title: string;
  subtitle?: string;
}

export default function ButtonTwoLines({title, subtitle, loading, ...props}: Props) {
  return <ButtonBase loading={loading} {...{...props}}>
    <div className={loading ? `${styles.children_wrapper} ${styles.hidden}` : styles.children_wrapper}>
      <span className={styles.title}>{title}</span>
      {subtitle && <span className={styles.description}>{subtitle}</span>}
    </div>
  </ButtonBase>
}