import {t}                from "i18next";
import {ButtonRenderType} from "shared/model";

export function buttonRender(name: string) {
  const url = new URL(name, "naimi://");
  const params = new URLSearchParams(url.search);
  const days = params.get("days");
  const partnerUrl = params.get("url");
  let id = params.get("id");

  let type = ButtonRenderType.EMPTY;
  let icon;
  let text;

  switch (true) {
    case name.includes("naimi://balance/credit/payments/schedule"):
      icon = "calendar";
      text = t("card_buttons.credits_schedule");
      type = ButtonRenderType.CREDIT_SCHEDULE;
      break;
    case name.includes("naimi://balance/credit/cart/init" && "credit_prepayment"):
      icon = "wallet";
      text = t("card_buttons.credit_prepayment");
      type = ButtonRenderType.CREDIT_PREPAYMENT;
      break;
    case name.includes("naimi://balance/credit/cart/init" && "loan_expired"):
      icon = "wallet";
      text = t("card_buttons.loan_expired");
      type = ButtonRenderType.CREDIT_ERROR;
      break;
    case name.includes("naimi://balance/tariff/buy"):
      icon = "wallet";
      text = t("card_buttons.loan_expired");
      type = ButtonRenderType.TARIFF_CREATE;
      break;
    case name.includes("naimi://balance/tariff/edit"):
      icon = "edit";
      text = t("card_buttons.edit");
      type = ButtonRenderType.TARIFF_EDIT;
      break;
    case name.includes("naimi://balance/tariff/freeze"):
      icon = "snow_flake";
      text = t("card_buttons.freeze");
      type = ButtonRenderType.TARIFF_FREEZE;
      break;
    case name.includes("naimi://balance/tariff/unfreeze"):
      icon = "snow_flake";
      text = t("card_buttons.unfreeze");
      type = ButtonRenderType.TARIFF_UNFREEZE;
      break;
    case name.includes("naimi://balance/tariff/subscribe/buy"):
      icon = "wallet";
      text = t("card_buttons.loan_expired");
      type = ButtonRenderType.TARIFF_SUBSCRIBE_ERROR;
      break;
    case name.includes("naimi://credit/redirect"):
      icon = "open_full";
      text = t("card_buttons.partner_site");
      type = ButtonRenderType.OPEN_PARTNER_SITE;
      break;
    default:
      icon = "";
      text = "";
      break;
  }

  return {icon, text, type, id, days, partnerUrl}
}