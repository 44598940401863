import React  from "react";
import cn     from "classnames";
import styles from "./TextBlock.module.scss";


interface Props {
  title?: string;
  description?: string;
  className?: string;
  smallGap?: boolean;
}

export default function TextBlock({title, description, className, smallGap = false}: Props) {
  return <div className={cn(styles.text_block, className, {[styles.small_gap]: smallGap})}>
    {title && <div className={styles.text_block_title}>{title}</div>}
    {description && <div className={styles.text_block_description}>{description}</div>}
  </div>
}