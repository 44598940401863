import api, {BaseResponse, RequestMethod} from "../api";

interface PurchaseCatalogueServiceItem {
  id: number;
  name: string;
  description: string;
  color: string;
}

interface PurchaseCatalogue {
  title: string;
  subtitle: string;
  description: string;
  price_per_addition?: number;
  button: {
    title: string;
    subtitle?: string;
    is_disabled: boolean;
  };
  services: PurchaseCatalogueServiceItem[];
}

interface PurchaseFavouriteCatalogueService {
  services: {
    id: number;
    name: string;
    is_selected: boolean;
  }[];
}

const catalogue = api.injectEndpoints({
  endpoints: (build) => ({
    getCatalogue:              build.query<BaseResponse<PurchaseCatalogue>, null>({
      query:             () => ({
        url:    'pro/balance/purchase/catalogue',
        method: RequestMethod.PUT,
      }),
      keepUnusedDataFor: 0,
    }),
    getCatalogueServices:      build.query<BaseResponse<PurchaseFavouriteCatalogueService>, void>({
      query:             () => ({
        url:    'pro/balance/purchase/catalogue/services',
        method: RequestMethod.PUT,
      }),
      keepUnusedDataFor: 0,
    }),
    catalogueServicesSave:     build.mutation<BaseResponse<boolean>, number[]>({
      query: (services) => ({
        url:    'pro/balance/purchase/catalogue/services/save',
        method: RequestMethod.PUT,
        body:   {services},
      }),
    }),
    catalogueServiceDelete:    build.mutation<BaseResponse<boolean>, number>({
      query: (id) => ({
        url:    'pro/balance/purchase/catalogue/services/delete',
        method: RequestMethod.PUT,
        body:   {service_id: id},
      }),
    }),
    catalogueServicesCartInit: build.mutation<BaseResponse<{ payment_id: string }>, void>({
      query: () => ({
        url:    'pro/balance/purchase/catalogue/cart/init',
        method: RequestMethod.PUT,
      }),
    }),
  }),
});

export const {
  useGetCatalogueQuery,
  useGetCatalogueServicesQuery,
  useCatalogueServicesSaveMutation,
  useCatalogueServiceDeleteMutation,
  useCatalogueServicesCartInitMutation,
} = catalogue;