import * as React                                          from "react";
import {FC, useEffect, useState}                           from "react";
import {TextBlock, ButtonDefault, PageHeader, List, InApp} from "shared/ui";
import {CloseRegistrationInApp}                            from "components/CloseRegistrationInApp";
import {Nullable, NativeActions}                           from "shared/model";
import {
  RegistrationMethodType,
  RegistrationType,
  useBiometricRecognitionMutation,
  useUploadManualPhotoMutation,
}                                                          from "services/registration";
import {setActionForNative, useNativeHandler}              from "shared/lib";
import {useAppSelector}                                    from "store";
import {selectMethodType, selectRegistrationType}          from "store/features";
import {selectBiometricUrl}                                from "store/features/registration";
import styles                                              from "./styles.module.scss";
import {useTranslation}                                    from "react-i18next";
import {useUploadNonResidentFacePhotoMutation}             from "services/registration/registration";
import {MainLayout}                                        from "entities/general";
import {ImageLoadError, ImageLoading}                      from "entities/registration";
import {useNavigate}                                       from "react-router-dom";

interface Props {
  onClick?: () => void;
}

const IdentityVerification: FC<Props> = ({
  onClick = () => {
  },
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const registrationMethod = useAppSelector(selectRegistrationType);
  const biometricUrl = useAppSelector(selectBiometricUrl);
  const methodType = useAppSelector(selectMethodType);

  const [checkBiometricRecognition, {
    isSuccess,
    data,
    isLoading: recognitionLoading,
  }] = useBiometricRecognitionMutation();
  const [uploadManualPhoto, {
    data:      manualPhotoData,
    isSuccess: successManualPhoto,
    isLoading: uploadManualPhotoLoading,
  }] = useUploadManualPhotoMutation();
  const [uploadFacePhoto, {
    isSuccess: uploadFacePhotoSuccess,
    isLoading: facePhotoIsLoading,
  }] = useUploadNonResidentFacePhotoMutation();

  const loading = recognitionLoading || uploadManualPhotoLoading || facePhotoIsLoading;

  const [biometricTryAgain, setBiometricTryAgain] = useState(false);
  const [inProgressingView, setIsProgressingView] = useState(false);
  const [faceError, setFaceError] = useState(false);
  const [photo, setPhoto] = useState<Nullable<string>>(null);
  const [photoIsLoading, setPhotoIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const [session, setSession] = useState<Nullable<string>>(null);

  useNativeHandler<{ session: string | null }>("registration", NativeActions.OPEN_BIOMETRIC, (data) => {
    if (data.session && data.session !== "null") {
      return setSession(data.session);
    }
    if (data.session === "" || data.session === "null") {
      return setFaceError(true);
    }
  });


  useEffect(() => {
    if (session === null) return;
    if (session === "null") return;
    if (session && session.length > 0 && registrationMethod) {
      checkBiometricRecognition({session: session, from: registrationMethod});
    }
  }, [session, registrationMethod, checkBiometricRecognition]);

  const handleOpenOnboarding = () => setActionForNative(NativeActions.OPEN_ONBOARDING);

  const handleOpenTickets = () => setActionForNative(NativeActions.OPEN_TICKETS);

  const handleOnClick = () => {
    setFaceError(false);
    setBiometricTryAgain(false);
    if (registrationMethod === RegistrationType.DOCUMENT) {
      setActionForNative(NativeActions.OPEN_BIOMETRIC, {url: biometricUrl});
    } else {
      onClick();
    }
  }

  useNativeHandler<{ digest: string }>("registration", NativeActions.MAKE_FACE_PHOTO, data => {
    setPhoto(data.digest);
  });

  useNativeHandler("registration", NativeActions.OPEN_UPLOAD_LOADER, () => {
    if (typeof photo === "string") return;
    setPhotoIsLoading(true);
  });

  useNativeHandler("registration", NativeActions.OPEN_UPLOAD_ERROR, () => {
    if (typeof photo === "string") return;
    setUploadError(true);
  });

  useEffect(() => {
    if (manualPhotoData?.status && successManualPhoto) {
      setPhotoIsLoading(false);
      setIsProgressingView(true);
    }
  }, [manualPhotoData, successManualPhoto]);

  useEffect(() => {
    if (uploadFacePhotoSuccess) {
      setPhotoIsLoading(false);
      setIsProgressingView(true);
    }
  }, [uploadFacePhotoSuccess]);

  useEffect(() => {
    if (!photo) return;
    if (registrationMethod === RegistrationType.NON_RESIDENT) {
      uploadFacePhoto({photo});
    }
    if (registrationMethod === RegistrationType.MANUAL) {
      uploadManualPhoto({photo});
    }
  }, [photo, registrationMethod, uploadFacePhoto, uploadManualPhoto]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.content?.status === "success" && registrationMethod !== RegistrationType.TRANSFER) {
        return handleOpenOnboarding();
      }
      if (data?.content?.status === "success" && registrationMethod === RegistrationType.TRANSFER) {
        navigate("/transfer-complete");
        // return setIsProgressingView(true);
      }
      if (data?.content?.status === "in_processing") { // лента заявок
        return setIsProgressingView(true);
      }
      if (data?.errors?.profile === "biometric_try_again") {
        return setBiometricTryAgain(true); // личность не подтверждена
      }
    }
  }, [data, isSuccess, navigate, registrationMethod]);

  useEffect(() => {
    if (registrationMethod && methodType !== RegistrationMethodType.VERIFICATION) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist registration method opened",
        properties: {
          "registration method": registrationMethod,
          "registration step":   "2",
        },
      });
    }
  }, [registrationMethod, methodType]);

  const handleTryAgain = () => {
    setUploadError(false);
    setPhotoIsLoading(false);
  }

  return <>
    {uploadError && <ImageLoadError onClick={handleTryAgain} />}

    {photoIsLoading ? <ImageLoading /> : <>{!inProgressingView ? <MainLayout>
        <PageHeader title={t("component.identity.header")} rightIcon={<CloseRegistrationInApp />} />

        <div className={styles.content}>
          <div className={styles.content_illustration}>
            <img src={"/illustrations/face-id-2.svg"} alt="img" />
          </div>

          <List
            icon="validation"
            title={registrationMethod === RegistrationType.EGOV || registrationMethod === RegistrationType.DOCUMENT
            || registrationMethod === RegistrationType.TRANSFER
              ? t("component.identity.list.item_1")
              : t("component.identity.list_photo.item_1")}
          />

          <List
            icon="validation"
            title={registrationMethod === RegistrationType.EGOV || registrationMethod === RegistrationType.DOCUMENT
            || registrationMethod === RegistrationType.TRANSFER
              ? t("component.identity.list.item_2")
              : t("component.identity.list_photo.item_2")}
          />

          <List
            icon="validation"
            title={registrationMethod === RegistrationType.EGOV || registrationMethod === RegistrationType.DOCUMENT
            || registrationMethod === RegistrationType.TRANSFER
              ? t("component.identity.list.item_3")
              : t("component.identity.list_photo.item_3")}
          />

          <TextBlock description={t("component.identity.text_block")} />
        </div>

        <div className={styles.footer}>
          <ButtonDefault
            onClick={handleOnClick}
            loading={loading}
          >
            {t("component.identity.confirm_button")}
          </ButtonDefault>
        </div>

        <InApp open={biometricTryAgain} onClose={() => setBiometricTryAgain(false)}>
          <div className={styles.inApp_wrapper}>
            <img src={"/illustrations/no-conversation-3.svg"} alt="illustration" />

            <TextBlock
              title={t("in_app.biometric_error.title")}
              description={t("in_app.biometric_error.description")}
            />

            <div className={styles.inApp_buttons}>
              <ButtonDefault onClick={handleOnClick} hasSpace>
                {t("in_app.biometric_error.buttons.try_again")}
              </ButtonDefault>
            </div>
          </div>
        </InApp>

        <InApp open={faceError} onClose={() => setFaceError(false)}>
          <div className={styles.inApp_wrapper}>
            <img src={"/illustrations/no-conversation-3.svg"} alt="illustration" />

            <TextBlock
              title={t("in_app.biometric_error.title_face")}
              description={t("in_app.biometric_error.description_face")}
            />

            <div className={styles.inApp_buttons}>
              <ButtonDefault onClick={handleOnClick} hasSpace>
                {t("in_app.biometric_error.buttons.try_again")}
              </ButtonDefault>
            </div>
          </div>
        </InApp>
      </MainLayout>

      : <div className={styles.inProgressing_wrapper}>
        <div className={styles.inProgressing_icon_holder}>
          <div className={styles.inProgressing_icon}>watch</div>
        </div>

        <div className={styles.inProgressing_text}>
          <div className={styles.inProgressing_text_title}>
            {t("component.identity.verification.title")}
          </div>

          <div className={styles.inProgressing_text_subtitle}>
            {registrationMethod === RegistrationType.TRANSFER && t("component.identity.verification.transfer_subtitle")}
            {registrationMethod === RegistrationType.NON_RESIDENT && t("component.identity.verification.manual_subtitle")}
            {registrationMethod === RegistrationType.MANUAL && t("component.identity.verification.manual_subtitle")}
            {registrationMethod === RegistrationType.EGOV && t("component.identity.verification.subtitle")}
            {registrationMethod === RegistrationType.DOCUMENT && t("component.identity.verification.manual_subtitle")}
          </div>
        </div>

        <ButtonDefault buttonRound hasSpace onClick={handleOpenTickets}>
          {t("component.identity.verification.button")}
        </ButtonDefault>
      </div>
    }</>}
  </>
}

export default IdentityVerification;