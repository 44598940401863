import React, {useEffect, useState}                          from "react";
import {useParams}                                           from "react-router-dom";
import {ReviewFilterTypes, ReviewsOverview, ReviewsListItem} from "services/reviews/types";
import {useGetSpecialistReviewsMutation}                     from "services/reviews";
import {ReviewsList}                                         from "components/ReviewsList";
import {useSetHeaders, useSkeletonThrottling}                from "shared/lib";


const SpecialistReviews = () => {
  const {id: paramId, service_id: paramServiceId} = useParams();
  const id = paramId ? parseInt(paramId) : null;
  const service_id = paramServiceId ? parseInt(paramServiceId) : null;

  const [getSpecialistReviews, {data, isLoading}] = useGetSpecialistReviewsMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading});
  const {isSuccess} = useSetHeaders();

  const [serviceId, setServiceId] = useState<number | null>(null);
  const [filters, setFilters] = useState<ReviewFilterTypes>(ReviewFilterTypes.DATE_DESC);
  const [page, setPage] = useState(1);
  const [reviewsList, setReviewsList] = useState<ReviewsListItem[]>([]);
  const [overviews, setOverviews] = useState<ReviewsOverview>();

  const handleSetServiceId = (id: number | null) => {
    setServiceId(id);
  }

  useEffect(() => {
    if (!isSuccess) return;
    if (!id) return;
    getSpecialistReviews({specialist_id: id, page, service_id: serviceId, filter: filters});
  }, [id, filters, serviceId, page, getSpecialistReviews, isSuccess]);

  useEffect(() => {
    if (!data?.reviews_overview) return;
    if (data?.reviews_overview) {
      setOverviews(data.reviews_overview);
    }
  }, [data?.reviews_overview]);

  useEffect(() => {
    if (data?.reviews) {
      setReviewsList(prev => ([...prev, ...data?.reviews]));
    }
  }, [data?.reviews, data?.has_more]);

  useEffect(() => {
    if (serviceId) {
      setReviewsList([]);
      setPage(1);
    }
    if (!serviceId) {
      setReviewsList([]);
      setPage(1);
    }
  }, [serviceId]);

  useEffect(() => {
    if (filters) {
      setReviewsList([]);
    }
  }, [filters]);

  useEffect(() => {
    if (!service_id) return;
    setServiceId(service_id);
  }, [service_id]);

  return <ReviewsList
    hasMore={data?.has_more}
    mode="specialist"
    serviceId={serviceId}
    onSetServiceId={handleSetServiceId}
    id={id}
    page={page}
    reviews={reviewsList}
    overview={overviews}
    isLoading={extendedLoading}
    onSetPage={setPage}
    filters={filters}
    setFilters={setFilters}
  />
}

export default SpecialistReviews;