import React, {ReactNode}       from "react";
import {Icon, PageHeader, List} from "shared/ui";
import styles                   from "./styles.module.scss";


interface Props {
  title: string;
  subtitle?: string;
  onClose: () => void;
  img: string;
  list: { id: number, icon: string, iconColor?: string, text: string }[];
  children?: ReactNode;
}

export default function StatePageLayout({title, subtitle, img, children, list, onClose}: Props) {
  return <div className={styles.layout}>
    <PageHeader title={title} subtitle={subtitle} rightIcon={<Icon onClick={onClose}>close</Icon>} />
    <div className={styles.content}>
      <div className={styles.image}>
        <img src={img} alt="illustration" />
      </div>

      {list.map((item, index) =>
        <List
          key={item.id ?? index}
          iconColor={item.iconColor}
          title={item.text}
          icon={item.icon}
        />)}
    </div>

    <div className={styles.footer}>{children}</div>
  </div>
}