import {
  useFetchSpecialistAppPhotosListMutation,
  useFetchSpecialistAppPhotoTabsMutation,
  useFetchSpecialistProPhotosListMutation,
  useFetchSpecialistProPhotoTabsMutation,
}                 from "shared/api";
import {Nullable} from "shared/model";
import {AppMode}  from "store/features";

export const fetchPhotos = ({appMode}: { appMode: Nullable<string> }) => {
  const [getTabs, {data: tabs, isLoading: tabsIsLoading}] = (() => {
    const [getAppTabs, {data: appTabs, isLoading: appTabsIsLoading}] = useFetchSpecialistAppPhotoTabsMutation();
    const [getProTabs, {data: proTabs, isLoading: proTabsIsLoading}] = useFetchSpecialistProPhotoTabsMutation();

    if (appMode === AppMode.SPECIALIST) {
      return [getProTabs, {data: proTabs, isLoading: proTabsIsLoading}];
    } else {
      return [getAppTabs, {data: appTabs, isLoading: appTabsIsLoading}];
    }
  })();

  const [getMediaList, {data: list, isLoading: listIsLoading}] = (() => {
    const [getAppMediaList, {
      data:      appMediaList,
      isLoading: appMediaListIsLoading,
    }] = useFetchSpecialistAppPhotosListMutation();
    const [getProMediaList, {
      data:      proMediaList,
      isLoading: proMediaListIsLoading,
    }] = useFetchSpecialistProPhotosListMutation();

    if (appMode === AppMode.SPECIALIST) {
      return [getProMediaList, {data: proMediaList, isLoading: proMediaListIsLoading}];
    } else {
      return [getAppMediaList, {data: appMediaList, isLoading: appMediaListIsLoading}];
    }
  })();

  return {getTabs, tabs, getMediaList, list, tabsIsLoading, listIsLoading}
}