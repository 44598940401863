import {useEffect, useRef, useState} from "react";


export function useCountdown() {
  const [timer, setTimer] = useState<number>(0);
  const intervalRef = useRef<number | undefined>();

  const CountingDown = () => {
    setTimer(value => value - 1);
  };

  const seconds = timer;

  useEffect(() => {
    if (timer > 0) {
      intervalRef.current = window.setInterval(CountingDown, 1000);

      return () => {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      };
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }, [timer]);

  const startCountdown = () => setTimer(60);

  return {startCountdown, seconds, timer}
}
