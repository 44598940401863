import {Route, Routes}       from "react-router-dom";
import {SpecialistProfile}   from "pages/Specialist/profile";
import {ProfileAbout}        from "pages/Specialist/profile-about";
import {ProfilePrices}       from "pages/Specialist/profile-prices";
import {PricesService}       from "pages/Specialist/profile-prices-service";
import {PricesWork}          from "pages/Specialist/profile-prices-work";
import {RemoveProfile}       from "pages/Specialist/profile-remove";
import {ProfileMedia}        from "pages/Specialist/profile-media";
import {ProfileAvatarChange} from "pages/Specialist/profile-avatar-change";
import {ProfileMediaUpload}  from "pages/Specialist/profile-media-upload";


export default function SpecialistProfileRoute() {
  return <Routes>
    <Route path="/*" element={<SpecialistProfile />} />
    <Route path="/:id/*" element={<SpecialistProfile />} />
    <Route path="/about" element={<ProfileAbout />} />
    <Route path="/prices" element={<ProfilePrices />} />
    <Route path="/prices-service/:id" element={<PricesService />} />
    <Route path="/prices-work/:id" element={<PricesWork />} />
    <Route path="/remove" element={<RemoveProfile />} />
    <Route path="/media/:id" element={<ProfileMedia />} />
    <Route path="/media" element={<ProfileMedia />} />
    <Route path="/media/add" element={<ProfileMediaUpload />} />
    <Route path="/avatar-change" element={<ProfileAvatarChange />} />
  </Routes>
}