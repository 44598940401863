import React            from "react";
import {SkeletonLayout} from "entities/skeleton";
import styles           from "./SkeletonPrices.module.scss";


export default function SkeletonPrices({hasDark = false, count = 2}: { hasDark?: boolean, count?: number }) {
  return <>
    {Array.from({length: count}, (_, index) => <div key={index} className={styles.skeleton_prices}>
      <div className={styles.skeleton_prices_left}>
        <SkeletonLayout width={197} height={22} borderRadius={6} theme={hasDark ? "dark" : "light"} />
        <SkeletonLayout width={110} height={19} borderRadius={6} />
      </div>
      <SkeletonLayout width={24} height={24} borderRadius={4} />
    </div>)}
  </>
};