import React            from "react";
import {SkeletonLayout} from "entities/skeleton";
import styles           from "./SkeletonTabs.module.scss";


const SkeletonTabs = () => {
  return <div className={styles.wrapper}>
    {Array.from({length: 4}, (_, index) => <SkeletonLayout key={index} height={33} borderRadius={45} />)}
  </div>
};

export default SkeletonTabs;