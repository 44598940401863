const loaderAnimation = {
  "v":       "5.10.2",
  "fr":      24,
  "ip":      0,
  "op":      36,
  "w":       150,
  "h":       150,
  "nm":      "naimi loading indicator",
  "ddd":     0,
  "assets":  [],
  "layers":  [
    {
      "ddd":    0,
      "ind":    1,
      "ty":     4,
      "nm":     "loading",
      "sr":     1,
      "ks":     {
        "o": {
          "a":  0,
          "k":  100,
          "ix": 11,
        },
        "r": {
          "a":  0,
          "k":  4,
          "ix": 10,
        },
        "p": {
          "a":  0,
          "k":  [
            75,
            75,
            0,
          ],
          "ix": 2,
          "l":  2,
        },
        "a": {
          "a":  0,
          "k":  [
            -1,
            1.75,
            0,
          ],
          "ix": 1,
          "l":  2,
        },
        "s": {
          "a":  0,
          "k":  [
            100,
            100,
            100,
          ],
          "ix": 6,
          "l":  2,
        },
      },
      "ao":     0,
      "shapes": [
        {
          "ty":  "gr",
          "it":  [
            {
              "d":  1,
              "ty": "el",
              "s":  {
                "a":  0,
                "k":  [
                  125,
                  125,
                ],
                "ix": 2,
              },
              "p":  {
                "a":  0,
                "k":  [
                  0,
                  0,
                ],
                "ix": 3,
              },
              "nm": "Контур эллипса 1",
              "mn": "ADBE Vector Shape - Ellipse",
              "hd": false,
            },
            {
              "ty": "st",
              "c":  {
                "a":  0,
                "k":  [
                  0.945098039216,
                  0.945098039216,
                  0.945098039216,
                  1,
                ],
                "ix": 3,
              },
              "o":  {
                "a":  0,
                "k":  100,
                "ix": 4,
              },
              "w":  {
                "a":  0,
                "k":  16,
                "ix": 5,
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Обводка 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false,
            },
            {
              "ty": "tr",
              "p":  {
                "a":  0,
                "k":  [
                  -1,
                  1.75,
                ],
                "ix": 2,
              },
              "a":  {
                "a":  0,
                "k":  [
                  0,
                  0,
                ],
                "ix": 1,
              },
              "s":  {
                "a":  0,
                "k":  [
                  100,
                  100,
                ],
                "ix": 3,
              },
              "r":  {
                "a":  0,
                "k":  0,
                "ix": 6,
              },
              "o":  {
                "a":  0,
                "k":  100,
                "ix": 7,
              },
              "sk": {
                "a":  0,
                "k":  0,
                "ix": 4,
              },
              "sa": {
                "a":  0,
                "k":  0,
                "ix": 5,
              },
              "nm": "Преобразовать",
            },
          ],
          "nm":  "Эллипс 1",
          "np":  2,
          "cix": 2,
          "bm":  0,
          "ix":  1,
          "mn":  "ADBE Vector Group",
          "hd":  false,
        },
        {
          "ty": "tm",
          "s":  {
            "a":  1,
            "k":  [
              {
                "i": {
                  "x": [
                    0.667,
                  ],
                  "y": [
                    1,
                  ],
                },
                "o": {
                  "x": [
                    0.167,
                  ],
                  "y": [
                    0.167,
                  ],
                },
                "t": 0,
                "s": [
                  50,
                ],
              },
              {
                "i": {
                  "x": [
                    0.833,
                  ],
                  "y": [
                    0.833,
                  ],
                },
                "o": {
                  "x": [
                    0.333,
                  ],
                  "y": [
                    0,
                  ],
                },
                "t": 18,
                "s": [
                  27,
                ],
              },
              {
                "t": 36,
                "s": [
                  50,
                ],
              },
            ],
            "ix": 1,
          },
          "e":  {
            "a":  1,
            "k":  [
              {
                "i": {
                  "x": [
                    0.667,
                  ],
                  "y": [
                    1,
                  ],
                },
                "o": {
                  "x": [
                    0.167,
                  ],
                  "y": [
                    0.167,
                  ],
                },
                "t": 0,
                "s": [
                  51,
                ],
              },
              {
                "i": {
                  "x": [
                    0.833,
                  ],
                  "y": [
                    0.833,
                  ],
                },
                "o": {
                  "x": [
                    0.333,
                  ],
                  "y": [
                    0,
                  ],
                },
                "t": 18,
                "s": [
                  80,
                ],
              },
              {
                "t": 36,
                "s": [
                  51,
                ],
              },
            ],
            "ix": 2,
          },
          "o":  {
            "a":  1,
            "k":  [
              {
                "i": {
                  "x": [
                    0.833,
                  ],
                  "y": [
                    0.833,
                  ],
                },
                "o": {
                  "x": [
                    0.167,
                  ],
                  "y": [
                    0.167,
                  ],
                },
                "t": 0,
                "s": [
                  180,
                ],
              },
              {
                "t": 36,
                "s": [
                  540,
                ],
              },
            ],
            "ix": 3,
          },
          "m":  1,
          "ix": 2,
          "nm": "Обрезать контуры 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false,
        },
      ],
      "ip":     0,
      "op":     73,
      "st":     0,
      "ct":     1,
      "bm":     0,
    },
  ],
  "markers": [],
};

export default loaderAnimation;