import {ReactNode, useCallback, useRef} from "react";
import {Gallery}                        from "react-photoswipe-gallery";
import PhotoSwipeLightboxStub           from "react-photoswipe-gallery/src/lightbox-stub";
import PhotoSwipe                       from "photoswipe";
import {useNativeHandler}               from "../../lib";
import {NativeActions}                  from "../../model";
import "photoswipe/dist/photoswipe.css"


interface Props {
  children: ReactNode;
  onBackClick?: () => void;
}

export default function GalleryWrapper({children, onBackClick}: Props) {
  const {setPswpInstance, closeGallery, pspw} = usePhotoSwipe();

  const plugin = createPlugin(setPswpInstance);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (pspw.current !== null) {
      return closeGallery();
    }
    return onBackClick && onBackClick();
  });

  return <Gallery
    options={{
      showHideAnimationType:   "fade",
      loop:                    false,
      zoom:                    false,
      clickToCloseNonZoomable: false,
      imageClickAction:        false,
      bgClickAction:           false,
      tapAction:               false,
      closeOnVerticalDrag:     false,
      allowPanToNext:          false,
    }}
    plugins={plugin}
  >
    {children}
  </Gallery>
};

export const usePhotoSwipe = () => {
  const pswpRef = useRef<null | PhotoSwipe>(null);

  const setPswpInstance = (pswp: null | PhotoSwipe) => {
    pswpRef.current = pswp;
  };

  const closeGallery = useCallback(() => {
    if (pswpRef.current) {
      //@ts-ignore
      pswpRef.current.close();
    }
  }, []);

  return {setPswpInstance, closeGallery, pspw: pswpRef};
};

const createPlugin = (setPswpInstance: (value: null | PhotoSwipe) => void) => (photoswipeLightbox: PhotoSwipeLightboxStub) => {
  photoswipeLightbox.on("init", () => {
    const {pswp} = photoswipeLightbox;

    if (pswp) {
      setPswpInstance(pswp);

      pswp.on("destroy", () => {
        setPswpInstance(null);
      });
    }
  });
};