import * as React          from "react";
import {ChangeEvent}       from "react";
import {RadioInput, InApp} from "shared/ui";
import {ReviewFilterTypes} from "services/reviews/types";
import styles              from "./styles.module.scss";
import {useNativeHandler}  from "shared/lib";
import {NativeActions}     from "shared/model";
import {useTranslation}    from "react-i18next";


interface Props {
  isOpen: boolean;
  onClose: () => void;
  filters: ReviewFilterTypes;
  setFilters: React.Dispatch<React.SetStateAction<ReviewFilterTypes>>;
  onSetPage: (value: number) => void;
}

export default function ReviewsSort({isOpen, filters, setFilters, onClose, onSetPage}: Props) {
  const {t} = useTranslation();

  const sortList = [
    {id: 1, text: t("page.reviews.sort.date_desc"), value: ReviewFilterTypes.DATE_DESC},
    {id: 2, text: t("page.reviews.sort.date_asc"), value: ReviewFilterTypes.DATE_ASC},
    {id: 3, text: t("page.reviews.sort.rate_desc"), value: ReviewFilterTypes.RATE_DESC},
    {id: 4, text: t("page.reviews.sort.rate_asc"), value: ReviewFilterTypes.RATE_ASC},
  ];

  const handleOnchangeSort = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters(event.target.value as ReviewFilterTypes)
    onSetPage(1);
    onClose();
  }

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return onClose();
  });

  return <InApp headerText={t("page.reviews.sort.title")} open={isOpen} onClose={onClose}>
    <div className={styles.radio_wrapper}>
      <div className={styles.radio_items}>
        {sortList.map((sort) =>
          <RadioInput
            id={`${sort.id}`}
            name={"sort"}
            key={`${sort.id}`}
            value={sort.value}
            label={sort.text}
            checked={sort.value === filters}
            onChange={handleOnchangeSort}
          />)}
      </div>
    </div>
  </InApp>
};