import React, {useEffect, useState}     from "react";
import {useNavigate}                    from "react-router-dom";
import {useTranslation}                 from "react-i18next";
import {useAppDispatch, useAppSelector} from "store";
import {
  addNotification,
  selectEntryPoint,
  selectInAppInfoIsOpen,
  selectIsMyTariffsOpened,
  selectPurchaseFreezeUnfreezeIsLoading,
  selectPurchaseIsLoading,
  setIsMyAnketyOpened,
  setIsMyTariffsOpened,
}                                       from "store/features";
import {useGetTariffPurchaseListQuery}  from "services/specialists/tariffs";
import {Accordion, Icon}                from "shared/ui";
import {LoadingScreen, PageLayout}      from "entities/general";
import {SkeletonLayout}                 from "entities/skeleton";
import {SubscriptionCard}               from "components/SubscriptionCard";
import {TariffPaymentCards}             from "components/TariffPaymentCards";
import {NativeActions}                  from "shared/model";
import {
  useNativeHandler,
  useSetHeaders,
  setActionForNative,
  useSkeletonThrottling,
  useCheckPaymentStatus,
}                                       from "shared/lib";
import styles                           from "./styles.module.scss";


const TOAST_POSITION = 12;
const i18n_prefix = "page.specialist.tariffs.";
const PurchaseListPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMyTariffsOpened = useAppSelector(selectIsMyTariffsOpened);
  const entryPoint = useAppSelector(selectEntryPoint);
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);
  const inAppIsOpen = useAppSelector(selectInAppInfoIsOpen);
  const freezeUnfreezeIsLoading = useAppSelector(selectPurchaseFreezeUnfreezeIsLoading);

  const {isSuccess} = useSetHeaders();

  const {
    data:      tariffs,
    isLoading: purchaseLoading,
    refetch,
  } = useGetTariffPurchaseListQuery(null, {skip: !isSuccess});

  const {extendedLoading} = useSkeletonThrottling({isLoading: purchaseLoading});
  const [autoRenewalInfoIsOpen, setAutoRenewalInfoIsOpen] = useState(false);

  const handleSetAutoRenewalNotification = () => {
    dispatch(addNotification({icon: "validation", text: t(`${i18n_prefix}notification`)}));
  }

  const {paymentStatusLoading} = useCheckPaymentStatus({refetch, onSetNotification: handleSetAutoRenewalNotification});

  const handleCatalogueClick = () => navigate("/tariffs/catalogue");

  const handleOpenGidClick = () => setActionForNative(NativeActions.OPEN_GID, {id: tariffs?.content.faq?.button});

  const handleTerminateClick = () => setActionForNative(NativeActions.TERMINATE);

  useEffect(() => {
    dispatch(setIsMyAnketyOpened(false));
  }, [dispatch]);

  useEffect(() => {
    if (isMyTariffsOpened) return;
    const analytics = tariffs?.analytics?.["tariff list"];
    if (analytics && !isMyTariffsOpened) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist my tariff opened",
        properties: {
          "entry point": entryPoint,
          "tariff list": analytics,
        },
      });
      dispatch(setIsMyTariffsOpened(true));
    }
  }, [dispatch, entryPoint, tariffs, isMyTariffsOpened]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (autoRenewalInfoIsOpen) return;
    if (purchaseInProgress) return;
    if (inAppIsOpen) return;
    return setActionForNative(NativeActions.TERMINATE);
  });

  return <>
    {(paymentStatusLoading || freezeUnfreezeIsLoading) && <LoadingScreen />}

    <PageLayout
      headerTitle={t(`${i18n_prefix}header`)}
      leftIcon={<Icon onClick={handleTerminateClick}>keyboard_arrow_left</Icon>}
      refetch={refetch}
      toastPosition={TOAST_POSITION}
      isLoading={extendedLoading}
    >
      <>
        {tariffs?.content?.catalogue && !extendedLoading
          ? <div
            onClick={handleCatalogueClick}
            className={styles.catalogue_form_wrapper}
          >

            <Icon className="text gray">{tariffs?.content?.catalogue?.icon ?? "graph_bar_increase"}</Icon>

            <div className={styles.catalogue_form_text}>
              <div>{tariffs?.content?.catalogue?.title}</div>
              <div style={{color: tariffs.content.catalogue.color ?? "#828291"}}>
                {tariffs?.content?.catalogue?.subtitle}
              </div>
            </div>

            <Icon size={16}>keyboard_arrow_right</Icon>
          </div>

          : <SkeletonLayout height={58} theme="dark" />}

        <TariffPaymentCards
          purchaseItems={tariffs?.content?.items}
          isLoading={extendedLoading}
          refetch={refetch}
        />

        {extendedLoading ? <SkeletonLayout height={68} /> : tariffs?.content?.subscribe &&
          <SubscriptionCard
            subscription={tariffs.content.subscribe}
            isLoading={extendedLoading}
            setIsOpen={setAutoRenewalInfoIsOpen}
          />}

        {!extendedLoading && tariffs?.content?.faq && <div className={styles.questions_wrapper}>
          <div className={styles.questions_header}>
            <div>{t(`${i18n_prefix}faq.text`)}</div>

            <button onClick={handleOpenGidClick}>{t(`${i18n_prefix}faq.button`)}</button>
          </div>

          <div className={styles.questions_list}>
            {tariffs?.content?.faq.faqs.map((faq, index) =>
              <Accordion
                key={index}
                title={faq.title}
                description={faq.text}
              />)}
          </div>
        </div>}
      </>
    </PageLayout>
  </>
}

export default PurchaseListPage;