export enum PurchaseState {
  WAITING = "waiting",
  ENDED = "ended",
}

export enum ButtonRenderType {
  TARIFF_EDIT = "tariffEdit",
  TARIFF_CREATE = "tariffCreate",
  TARIFF_FREEZE = "tariffFreeze",
  TARIFF_UNFREEZE = "tariffUnfreeze",
  TARIFF_SUBSCRIBE_ERROR = "tariffSubscribeError",
  CREDIT_PREPAYMENT = "credit_prepayment",
  CREDIT_ERROR = "loan_expired",
  CREDIT_SCHEDULE = "creditSchedule",
  OPEN_PARTNER_SITE = "openPartnerSite",
  EMPTY = "",
}