import React, {useEffect, useRef}  from "react";
import {useTranslation}            from "react-i18next";
import {useAppSelector}            from "store";
import {selectLocale}              from "store/features";
import {checkProgress, formatRate} from "./lib";
import styles                      from "./styles.module.scss";


interface Props {
  nmbReviews?: number;
  rate?: number;
  stars?: number[];
}

export default function ReviewRating({nmbReviews, rate, stars}: Props) {
  const {t} = useTranslation();
  const locale = useAppSelector(selectLocale);

  const currentLocale = locale === "ru"
    ? `${t("page.reviews.rating.value.keyWithCount", {count: nmbReviews})}`
    : `${t("page.reviews.rating", {count: nmbReviews})}`;

  const [counterWidth, setCounterWidth] = React.useState(0);

  const countRefList = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (countRefList.current.length) {
      const widthArray = (countRefList.current.map((item) => item.scrollWidth));
      const maxWidth = Math.max(...widthArray);
      setCounterWidth(maxWidth);
    }
  }, [countRefList.current, nmbReviews]);

  return <div className={styles.wrapper}>
    <div className={styles.left_content}>
      <div className={styles.left_content_count}>
        {!nmbReviews ? t("state.guest") : currentLocale}
      </div>

      <div className={styles.left_content_rating}>
        <div className={styles.star}>star_filled</div>
        <div className={styles.rating}>{formatRate(rate)}</div>
      </div>
    </div>

    <div className={styles.stars_indicator}>
      {stars?.map((star, index) =>
        <div key={index} className={styles.rating_item_wrapper}>
          <div className={styles.stars_container}>
            <div className={styles.rating_item_count}>{5 - index}</div>
            <div>star_filled</div>
          </div>
          <div className={styles.progress_container}>
            <div className={styles.progress_bar} style={{width: `${checkProgress(nmbReviews, star)}%`}} />
          </div>
          <div ref={el => {
            if (el) countRefList.current[index] = el
          }} className={styles.count} style={{width: `${counterWidth}px`}}>{star}</div>
        </div>)}
    </div>
  </div>
}