import * as React                 from "react";
import {useTranslation}           from "react-i18next";
import {TextBlock, ButtonDefault} from "shared/ui";
import styles                     from "./styles.module.scss";
import {setActionForNative}       from "shared/lib";
import {NativeActions}            from "shared/model";


const WaitingScreen = () => {
  const {t} = useTranslation();

  const handleClick = () => setActionForNative(NativeActions.OPEN_TICKETS);

  return <div className={styles.wrapper}>
    <div className={styles.content}>
      <div className={styles.watch_icon}>watch</div>

      <div className={styles.content_text}>
        <div className={styles.text_title}>{t("component.identity.verification.title")}</div>

        <TextBlock description={t("component.identity.verification.freedom")} />
      </div>
    </div>
    <ButtonDefault buttonRound onClick={handleClick}>{t("component.identity.verification.button")}</ButtonDefault>
  </div>
}

export default WaitingScreen;