import { Nullable } from "shared/model";

export enum ReviewFilterTypes {
  DATE_ASC = "date_asc",
  DATE_DESC = "date_desc",
  RATE_ASC = "rate_asc",
  RATE_DESC = "rate_desc",
}

export interface SpecialistReviewsRequest {
  specialist_id?: number;
  service_id?: Nullable<number>;
  page?: number;
  filter: ReviewFilterTypes,
}

export interface ReviewTabs {
  tabs: { text: string, id: number, count: number, is_selected: boolean }[],
}

export interface ReviewMedia {
  mime?: string;
  type?: string;
  extension?: string;
  name?: string;
  digest?: string;
  size?: string;
}

export interface ReviewsOverview {
  nmb_reviews: number;
  rate: number;
  star1: number;
  star2: number;
  star3: number;
  star4: number;
  star5: number;
}

export interface ReviewsListItem {
  created_at: string;
  id: number;
  name: string;
  text: string;
  rate: number;
  sum: null | number;
  media?: ReviewMedia[],
  is_approved: boolean;
}

export interface ReviewsResponse {
  reviews_overview: ReviewsOverview,
  reviews: ReviewsListItem[],
  has_more: boolean,
}