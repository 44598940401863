import * as React                            from "react";
import {useCallback, useEffect, useRef}      from "react";
import {useAppDispatch, useAppSelector}      from "store";
import {selectErrorMessage, setErrorMessage} from "store/features/registration";
import styles                                from "./styles.module.scss";


const ErrorNotification = () => {
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector(selectErrorMessage);
  const timeoutListener = useRef<NodeJS.Timeout>();

  const handleCloseError = useCallback(() => dispatch(setErrorMessage(null)), [dispatch]);

  useEffect(() => {
    if (errorMessage) {
      timeoutListener.current = setTimeout(() => {
        handleCloseError();
      }, 5000);
    } else {
      clearTimeout(timeoutListener.current);
    }

    return () => {
      clearTimeout(timeoutListener.current);
    }
  }, [errorMessage, handleCloseError]);

  return <>
    {errorMessage && <div className={styles.error} onTouchStart={handleCloseError} onClick={handleCloseError}>{errorMessage}</div>}
  </>
};

export default ErrorNotification;