import React, {useEffect, useState}       from "react";
import {SSNRegistration}                  from "components/SSNRegistration";
import {OtpInput, ButtonDefault, InApp}   from "shared/ui";
import {IdentityVerification}             from "components/IdentityVerification";
import {useCountdown, setActionForNative} from "shared/lib";
import {useSendEgovCodeMutation}          from "services/registration";
import styles                             from "./styles.module.scss";
import {useTranslation}                   from "react-i18next";
import {Nullable, NativeActions}          from "shared/model";
import {useAppSelector}                   from "store";
import {selectBiometricUrl}               from "store/features/registration";
import {loaderAnimation}                  from "../../../assets/lotties";
import Lottie                             from "lottie-react";


const i18n_prefix = "page.registration.egov."
const Egov = () => {
  const {t} = useTranslation();
  const biometricUrlFromMethods = useAppSelector(selectBiometricUrl);

  const [sendSmsCode, {data, isLoading: smsCodeLoading}] = useSendEgovCodeMutation();

  const [smsVerificationInAppOpen, setSmsVerificationInAppOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [requestId, setRequestId] = useState<string>();
  const [biometricUrl, setBiometricUrl] = useState<Nullable<string>>(null);
  const [tryAgain, setTryAgain] = useState(false);

  const {startCountdown, seconds, timer} = useCountdown();

  const handleConfirmClick = () => {
    setSmsVerificationInAppOpen(true);
    startCountdown();
  }

  useEffect(() => {
    if (otp.length === 6 && requestId) {
      sendSmsCode({request_id: requestId, code: otp});
    }
  }, [otp.length, requestId]);

  useEffect(() => {
    if (!biometricUrlFromMethods) return;
    setBiometricUrl(biometricUrlFromMethods);
  }, [biometricUrlFromMethods]);

  const handleOpenBiometric = () => {
    setActionForNative(NativeActions.OPEN_BIOMETRIC, {url: biometricUrl});
  }

  useEffect(() => {
    if (data && data.content && data.content.url) {
      setBiometricUrl(data.content.url);
    }
  }, [data]);

  const handleOpenTerms = () => setActionForNative(NativeActions.OPEN_TERMS);

  const handleSetOtp = (value: string) => {
    setOtp(value);
    setError("");
  }

  useEffect(() => {
    if (data && data.errors?.code) {
      setError(data.errors.code);
    }
  }, [data?.errors]);

  useEffect(() => {
    return () => {
      setTryAgain(false);
    }
  }, []);

  return <>
    {smsCodeLoading && <>
      <div className={styles.loading_background} />
      <div className={styles.loading_holder}>
        <Lottie
          autoPlay
          animationData={loaderAnimation}
          style={{width: 32, height: 32}}
          loop
        />

        <div>{t("common.check_loading")}</div>
      </div>
    </>}
    {biometricUrl ? <IdentityVerification onClick={handleOpenBiometric} /> :

      <>
        <SSNRegistration
          text={t(`${i18n_prefix}form.text`)}
          navSubtitle={t(`${i18n_prefix}header.description`)}
          onSetData={setRequestId}
          onConfirmClick={handleConfirmClick}
          hasTryAgain={tryAgain}
        />

        <InApp
          headerText={t(`${i18n_prefix}otp.header`)}
          hasHeader
          backdropClose={false}
          open={smsVerificationInAppOpen}
          onClose={() => setSmsVerificationInAppOpen(false)}
        >
          <div className={styles.sms_inApp_wrapper}>
            <div className={styles.sms_inApp_inputs}>
              <OtpInput
                errorMessage={error}
                value={otp}
                triggerBlur={smsCodeLoading}
                handleChange={handleSetOtp}
              />

              <div className={styles.sms_inApp_text}>
                {t(`${i18n_prefix}otp.description_chunk_1`)}
                <a onClick={handleOpenTerms}> {t(`${i18n_prefix}otp.description_chunk_2`)}</a>
              </div>
            </div>

            <ButtonDefault onClick={() => setTryAgain(true)} disabled={timer !== 0} hasSpace>
              {timer > 0 ? t(`${i18n_prefix}otp.timer`, {count: seconds}) : t(`${i18n_prefix}otp.end_time`)}
            </ButtonDefault>
          </div>
        </InApp>
      </>}
  </>
};

export default Egov;