import React, {useEffect}                                                     from "react";
import {ButtonDefault, Icon}                                                  from "shared/ui";
import {NativeActions, Nullable, SpecialistAlertItem, SpecialistProfileState} from "shared/model";
import {useRestoreProfileCartInitMutation}                                    from "shared/api";
import {setActionForNative, useNativeHandler}                                 from "shared/lib";
import {SystemResponse, PageLayout}                                           from "entities/general";
import {AppMode, setPaymentId}                                                from "store/features";
import {useAppDispatch}                                                       from "store";
import styles                                                                 from "./AlertBlock.module.scss";


interface Props {
  alert?: SpecialistAlertItem;
  state: Nullable<SpecialistProfileState>;
  appMode: Nullable<AppMode>;
}

export default function AlertBlock({alert, state, appMode}: Props) {
  const dispatch = useAppDispatch();

  const [profileRestoreCartInit, {
    data:      profileInitResponse,
    isLoading: cartInitIsLoading,
  }] = useRestoreProfileCartInitMutation();

  const handleBackClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleAlertButtonClick = () => {
    switch (state) {
      case SpecialistProfileState.GUEST:
        return setActionForNative(NativeActions.OPEN_REGISTRATION);
      case SpecialistProfileState.LOCKED:
        return setActionForNative(NativeActions.OPEN_SUPPORT);
      case SpecialistProfileState.REJECT:
        return setActionForNative(NativeActions.OPEN_SUPPORT);
      case SpecialistProfileState.REMOVED:
        if (appMode === AppMode.SPECIALIST) {
          return profileRestoreCartInit();
        }
        return setActionForNative(NativeActions.OPEN_SUPPORT);
      case SpecialistProfileState.APPLIED:
        return setActionForNative(NativeActions.OPEN_NEWSLINE);
    }
  }

  useNativeHandler<{ is_payed: boolean }>("profile", NativeActions.OPEN_PAYMENTS, (data) => {
    if (data.is_payed && profileInitResponse?.payment_id) {
      dispatch(setPaymentId(profileInitResponse.payment_id));
    }
  });

  useEffect(() => {
    if (profileInitResponse && profileInitResponse.payment_id) {
      setActionForNative(NativeActions.OPEN_PAYMENTS, {payment_id: profileInitResponse.payment_id});
    }
  }, [profileInitResponse]);

  if (!alert) return null;

  return <PageLayout leftIcon={<Icon onClick={handleBackClick}>keyboard_arrow_left</Icon>} toastPosition={12}>
    <div className={styles.alert_wrapper}>

      <SystemResponse title={alert.title} description={alert.text} icon={alert.icon}>
        {alert.buttons?.map((button, index) => {
          return <ButtonDefault
            key={index}
            onClick={handleAlertButtonClick}
            buttonRound
            disabled={cartInitIsLoading}
            loading={cartInitIsLoading}
          >
            {button.title}
          </ButtonDefault>
        })}
      </SystemResponse>
    </div>
  </PageLayout>
};