import {Route, Routes}     from "react-router-dom";
import {AutoRenewalCancel} from "pages/StatePages/AutoRenewalCancel";


const StatePagesRoutes = () => {
  return <Routes>
    <Route path="/auto-renewal-cancel" element={<AutoRenewalCancel />} />
  </Routes>
};

export default StatePagesRoutes;