import React                 from "react";
import {useTranslation}      from "react-i18next";
import {Icon, ButtonDefault} from "shared/ui";
import {setActionForNative}  from "shared/lib";
import {NativeActions}       from "shared/model";
import styles                from "./StateScreen.module.scss";


export enum LoansPageType {
  INITIAL = "initial",
  DENIED = "denied",
  SELECT_CARD = "select_card",
  SUCCESS = "success",
  ERROR = "error",
  LOADING = "loading",
  EMPTY_CATEGORIES = "empty_categories",
}

const GRAY_COLOR = "#ADADC2";
const ERROR_COLOR = "#FF1507";
const i18n_prefix = "page.customers.cash_loans.state."
export default function StateScreen({state, message, onOpenCardClick}: {
  state: LoansPageType;
  message?: string;
  onOpenCardClick: () => void;
}) {
  const {t} = useTranslation();

  const handleOnClick = () => {
    if (state === LoansPageType.SELECT_CARD) {
      return onOpenCardClick();
    }
    if (state === LoansPageType.SUCCESS) {
      return setActionForNative(NativeActions.OPEN_NEWSLINE);
    }
    if (state === LoansPageType.ERROR) {
      return setActionForNative(NativeActions.OPEN_SUPPORT);
    }
    if (state === LoansPageType.EMPTY_CATEGORIES) {
      return setActionForNative(NativeActions.OPEN_NEWSLINE_FILTERS);
    }
  }

  if (state === LoansPageType.INITIAL) return null;

  return <div className={styles.centered_content}>
    <div className={styles.children_wrapper}>
      {state === LoansPageType.DENIED && <Icon size={40} iconColor={ERROR_COLOR}>cancel</Icon>}
      {state === LoansPageType.SELECT_CARD && <Icon size={40} iconColor={GRAY_COLOR}>check</Icon>}
      {state === LoansPageType.SUCCESS && <Icon size={40} iconColor={GRAY_COLOR}>check</Icon>}
      {state === LoansPageType.ERROR && <Icon size={40} iconColor={GRAY_COLOR}>cancel</Icon>}
      {state === LoansPageType.EMPTY_CATEGORIES && <Icon size={40} iconColor={GRAY_COLOR}>search</Icon>}

      <div className={styles.text_block}>
        <span className={styles.title_text}>{t(`${i18n_prefix}${state}.title`)}</span>
        <span className={styles.description_text}>
          {state === LoansPageType.EMPTY_CATEGORIES ? t(`${i18n_prefix}${state}.description`) : message}
        </span>
      </div>

      {state !== LoansPageType.DENIED &&
        <ButtonDefault onClick={handleOnClick} buttonRound>{t(`${i18n_prefix}${state}.button`)}</ButtonDefault>}
    </div>
  </div>
}