import * as React                        from "react";
import {FC}                              from "react";
import {useNavigate}                     from "react-router-dom";
import {Props}                           from "components/SsnExistInApp";
import {TextBlock, ButtonDefault, InApp} from "shared/ui";
import {useAppDispatch}                  from "store";
import {setRegistrationType, setSsn}     from "store/features";
import styles                            from "./styles.module.scss";
import {useTranslation}                  from "react-i18next";
import {RegistrationType}                from "services/registration";


const SsnExistInApp: FC<Props> = ({open, ssn, onClose}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleTransferProfile = () => {
    dispatch(setSsn(ssn));
    navigate("/transfer");
    dispatch(setRegistrationType(RegistrationType.TRANSFER));
    onClose();
  }

  return <InApp hasHeader open={open} onClose={onClose}>
    <div className={styles.inApp_wrapper}>

      <div className={styles.inApp_image}>
        <img src="/illustrations/searching-6.svg" alt="img" />
      </div>

      <TextBlock
        title={t("in_app.ssn_exist.title")}
        description={t("in_app.ssn_exist.description")}
      />

      <div className={styles.inApp_buttons}>
        <ButtonDefault
          hasSpace
          onClick={handleTransferProfile}
        >
          {t("in_app.ssn_exist.button")}
        </ButtonDefault>
      </div>
    </div>
  </InApp>
}

export default SsnExistInApp;