import {combineReducers, configureStore}                       from "@reduxjs/toolkit";
import api                                                     from "../services/api";
import {TypedUseSelectorHook, useDispatch, useSelector}        from "react-redux";
import {registration, generals, purchases, analytics, queries} from "store/features";
import {specialistProfile}                                     from "shared/model/slices/profile"
import {rtkQueryErrorLogger}                                   from "../services/rtkErrorLogger";


const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  registration,
  generals,
  purchases,
  analytics,
  queries,
  specialistProfile,
});

export const store = configureStore({
  reducer:    (state, action) => {
    return appReducer(state, action);
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat([api.middleware]).concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;