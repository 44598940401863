import * as React       from "react";
import classNames       from "classnames";
import {useTranslation} from "react-i18next";
import styles           from "./styles.module.scss";


export enum CreditScheduleState {
  PAID = "paid",
  NOT_PAID = "not_paid",
  ERROR = "error",
}

interface Props {
  date?: string;
  price?: string;
  state: CreditScheduleState;
}

const CreditScheduleItem = ({date, price, state}: Props) => {
  const {t} = useTranslation();

  const stateText = {
    [CreditScheduleState.PAID]:     `${t("state.payed")}`,
    [CreditScheduleState.NOT_PAID]: `${t("state.not_payed")}`,
    [CreditScheduleState.ERROR]:    `${t("state.pay_error")}`,
  }

  const wrapperClassNames = classNames(
    styles.schedule_item_wrapper,
    {[`${styles[state]}`]: state},
  );

  const stateClassNames = classNames(
    styles.state,
    {[`${styles[state]}`]: state},
  );

  return <div className={wrapperClassNames}>
    <div className={styles.date}>{date}</div>
    <div className={styles.price}>{price}</div>
    <div className={stateClassNames}>{stateText[state]}</div>
  </div>
};

export default CreditScheduleItem;