import * as React         from "react";
import {FC}               from "react";
import {TextBlock, InApp} from "shared/ui";
import styles             from "./styles.module.scss";


interface Props {
  isOpen: boolean;
  img: string;
  title: string;
  description: string;
  onClose: () => void;
  children?: React.ReactNode;
}

const InAppLayout: FC<Props> = ({isOpen, title, description, onClose, img, children}) => {
  return <InApp open={isOpen} onClose={onClose}>
    <div className={styles.inApp_wrapper}>
      <div className={styles.inApp_image}>
        <img src={img} alt="illustrations" />
      </div>

      <TextBlock title={title} description={description} />

      <div className={styles.inApp_buttons}>
        {children}
      </div>
    </div>
  </InApp>
}

export default InAppLayout;