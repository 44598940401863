import React, {useRef, useState} from "react";
import {Nullable}                from "shared/model";
import styles                    from "./GalleryDescription.module.scss";


interface Props {
  description?: Nullable<string>;
  children: React.ReactNode;
  isHide?: boolean;
  controlsHeight?: number;
  hasReview?: boolean;
}

export default function GalleryDescription({
  description,
  children,
  controlsHeight,
  isHide = false,
  hasReview = false,
}: Props) {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const oldHeight = useRef<number | null>(null);

  const handleDescriptionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const element = descriptionRef.current;

    const elementScrollHeight = element?.scrollHeight as number;
    const capHeight = (window.innerHeight / 2) - (hasReview ? 67 : 0) - (controlsHeight ?? 0);
    const maxHeight = elementScrollHeight > capHeight ? capHeight : elementScrollHeight;
    if (!isOpen) {
      setHeight(maxHeight);
      setIsOpen(true);
      oldHeight.current = height;
    } else {
      setIsOpen(false);
      setHeight(oldHeight.current);
    }
  }

  return <div className={styles.wrapper} onClick={handleDescriptionClick} style={{opacity: isHide ? 0 : 1}}>
    {children}
    {description && <div
      ref={descriptionRef}
      className={`${isOpen ? `${styles.open} ${styles.description}` : `${styles.description}`} `}
      style={{
        marginBottom: `${controlsHeight}px`,
        maxHeight:    height ? `${height}px` : "5.7rem",
      }}
    >
      {description}
    </div>}
  </div>
};