export function formatMoney(value: number | null, withCurrency: boolean = false) {
  const currency = withCurrency ? "₸" : "";

  if (!value) return null;

  const parts = value.toString().split("").reverse().join("").match(/.{1,3}/g);
  if (!parts) return `0 ${currency}`.trim();

  const formattedValue = parts.map(part => part.split("").reverse().join("")).reverse().join(" ");

  return `${formattedValue} ${currency}`.trim();
}

export function formatSpecialistPrices(sum: null | number, unit: null | string) {
  if (!sum) return;
  const currentUnit = unit === "шт" ? "шт" : "услугу";
  if (currentUnit) {
    return `до ${formatMoney(sum, true)} / за ${currentUnit}`;
  }
  return `до ${formatMoney(sum, true)}`;
}

export function formatRate(rate?: number) {
  if (typeof rate === "undefined") return "0";
  if (rate === null) return "0.0";
  if (rate === 0) return "0.0";

  if (Number.isInteger(rate) && rate !== 0) {
    return `${rate}.0`;
  }

  return rate.toString();
}

export function formatNumberWithSpaces(value?: number) {
  if (value === undefined || value === null) return "0";

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function defineOpenShareLinkType(id?: number) {
  if (`${process.env.REACT_APP_API_BASE_URL}`.includes("naimi.kz")) {
    return `https://naimi.kz/specialist/${id}`;
  }
  return `https://stage.naimi.me/specialist/${id}`;
}

export function onParentClick(event: React.MouseEvent<HTMLDivElement>, index: number, ref: React.MutableRefObject<HTMLInputElement[]>) {
  const target = event.target as HTMLInputElement;
  if (target && target.type !== "checkbox") {
    ref.current[index].click();
  }
}