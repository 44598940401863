export const client_profile = {
  rating:        {
    value: {
      keyWithCount_zero: "",
      keyWithCount_one:  "{{count}} оценка",
      keyWithCount_few:  "{{count}} оценки",
      keyWithCount_many: "{{count}} оценок",
    },
  },
  title:         "Отзывы",
  show_all:      "Смотреть все",
  empty_reviews: "У клиента еще нет отзывов. Оцените клиента в чате с ним после выполнения работы, и отзыв будет отображен здесь",
  newbie:        "Новичок",
}