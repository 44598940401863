import React, {useEffect, useRef, useState} from "react";
import styles                               from "./styles.module.scss";


interface Props {
  icon?: string;
  text: string;
  hasShow?: boolean;
  children: React.ReactNode;
}

export default function Tooltip({icon, text, children, hasShow = true}: Props) {
  const [open, setOpen] = useState(false);
  const timeoutListener = useRef<NodeJS.Timeout>();

  const handleOpenTooltip = () => {
    if (open) return;
    if (!hasShow) return;
    setOpen(true);
  }

  const handleCloseTooltip = () => setOpen(false);

  useEffect(() => {
    if (open) {
      timeoutListener.current = setTimeout(() => {
        setOpen(false);
      }, 2750);
    } else {
      clearTimeout(timeoutListener.current);
    }

    return () => {
      clearTimeout(timeoutListener.current);
    }
  }, [open]);

  return <div onClick={handleOpenTooltip} className={styles.children_wrapper}>
    {children}
    {open && <div onClick={handleCloseTooltip} className={styles.wrapper}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.text}>{text}</div>
    </div>}
  </div>
}