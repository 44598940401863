import api, {BaseResponse, RequestMethod} from "services/api";
import {ClientProfileItem}                from "../../model";


const clientApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchClientProfile: build.query<ClientProfileItem | undefined, number>({
      query:             (id) => ({
        url:    `pro/client`,
        method: RequestMethod.PUT,
        body:   {client_id: id},
      }),
      transformResponse: (response: BaseResponse<ClientProfileItem>) => response.status ? response.content : undefined,
    }),
    clientCartInit:     build.mutation<{ payment_id: string } | undefined, Record<string, number | string | boolean>>({
      query:             ({...params}) => ({
        url:    `app/balance/purchase/cart/init`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<{
        payment_id: string
      }>) => response.status ? response.content : undefined,
    }),
  }),
});

export const {useFetchClientProfileQuery, useClientCartInitMutation} = clientApi;