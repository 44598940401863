import React, {useEffect, useState}                             from "react";
import {useTranslation}                                         from "react-i18next";
import {useNavigate}                                            from "react-router-dom";
import {PageLayout, InAppLayout}                                from "entities/general";
import {SkeletonLayout}                                         from "entities/skeleton";
import {useAppDispatch, useAppSelector}                         from "store";
import {addNotification}                                        from "store/features";
import {useSaveAboutMutation, useFetchProfileAboutQuery}        from "shared/api";
import {ButtonColor, ButtonType, ButtonDefault, Textarea, Icon} from "shared/ui";
import {useNativeHandler, useSetHeaders, useSkeletonThrottling} from "shared/lib";
import {NativeActions, selectProfile, setProfile}               from "shared/model";
import styles                                                   from "./ProfileAbout.module.scss";


const i18n_prefix = "page.specialist.profile.about."
export default function ProfileAbout() {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {isSuccess} = useSetHeaders();
  const profile = useAppSelector(selectProfile);

  const {data, isLoading: aboutIsLoading} = useFetchProfileAboutQuery(null, {
    skip:                      !isSuccess,
    refetchOnMountOrArgChange: true,
  });
  const [updateAbout, {isLoading, isSuccess: updateAboutIsSuccess}] = useSaveAboutMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading: aboutIsLoading});

  const [value, setValue] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  const handleToggleHasChanges = () => setHasChanges(prev => !prev);

  const handleConfirmCloseClick = () => {
    setHasChanges(false);
    setValue("");
    navigate("/profile");
  }

  const handleOnCloseClick = () => {
    if (value !== data?.about) {
      return handleToggleHasChanges();
    }
    return navigate("/profile");
  }

  const handleUpdateAbout = () => updateAbout(value);

  const footer = extendedLoading ? <SkeletonLayout height={46} theme="dark" /> : <ButtonDefault
    disabled={value.length > 300 || isLoading}
    loading={isLoading}
    onClick={handleUpdateAbout}
  >
    {t(`${i18n_prefix}button.save`)}
  </ButtonDefault>

  useEffect(() => {
    if (data && data.about) {
      setValue(data.about);
    }
  }, [data]);

  useEffect(() => {
    if (updateAboutIsSuccess) {
      handleConfirmCloseClick();
      dispatch(addNotification({text: t(`${i18n_prefix}toast`)}));
      dispatch(setProfile({
        ...profile,
        content: {
          ...profile?.content,
          //@ts-ignore
          specialist: {
            ...profile?.content.specialist,
            about:           value,
            about_moderated: false,
          },
        },
      }))
    }
  }, [updateAboutIsSuccess]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (hasChanges) {
      return setHasChanges(false);
    }
    return navigate("/profile");
  });

  return <>
    <PageLayout
      headerTitle={t(`${i18n_prefix}title`)}
      leftIcon={<Icon onClick={handleOnCloseClick}>keyboard_arrow_left</Icon>}
      isLoading={extendedLoading}
      footer={footer}
    >
      {extendedLoading
        ? <div className={styles.skeleton_textarea}>
          <SkeletonLayout height={132} borderRadius={16} />
          <SkeletonLayout height={17} borderRadius={4} width="95%" />
          <SkeletonLayout height={17} borderRadius={4} width="70%" />
        </div>
        : <Textarea
          value={value}
          counterCount={value.length}
          placeholder={t(`${i18n_prefix}placeholder`)}
          onChange={(event) => setValue(event.target.value)}
          hint={t(`${i18n_prefix}hint`)}
          errorMessage={value.length > 300 ? t(`${i18n_prefix}error`) : undefined}
        />}
    </PageLayout>

    <InAppLayout
      isOpen={hasChanges}
      img={"/illustrations/no-conversation-3.svg"}
      title={t(`${i18n_prefix}inApp.title`)}
      description={t(`${i18n_prefix}inApp.description`)}
      onClose={handleToggleHasChanges}
    >
      <div className={styles.button_wrapper}>
        <ButtonDefault buttonType={ButtonType.WRAPPED} buttonColor={ButtonColor.GRAY} onClick={handleToggleHasChanges}>
          {t(`${i18n_prefix}inApp.button.cancel`)}
        </ButtonDefault>

        <ButtonDefault onClick={handleConfirmCloseClick} hasSpace buttonColor={ButtonColor.ERROR}>
          {t(`${i18n_prefix}inApp.button.close`)}
        </ButtonDefault>
      </div>
    </InAppLayout>
  </>
}