import * as React                              from "react";
import {FC, useEffect}                         from "react";
import {createPortal}                          from "react-dom";
import {useTranslation}                        from "react-i18next";
import {Icon, List, ButtonDefault, PageHeader} from "shared/ui";
import {NativeActions}                         from "shared/model";
import {useNativeHandler, setActionForNative}  from "shared/lib";
import styles                                  from "./styles.module.scss";


interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const i18n_prefix = "page.state.auto_renewal."
const AutoRenewalInfo: FC<Props> = ({isOpen, onClose}) => {
  const {t} = useTranslation();

  const handleBackClick = () => onClose();

  const listData = [
    {id: 1, icon: "terminal", text: `${t(`${i18n_prefix}list.row_1`)}`},
    {id: 2, icon: "history", text: `${t(`${i18n_prefix}list.row_2`)}`},
    {id: 3, icon: "terminal", text: `${t(`${i18n_prefix}list.row_3`)}`},
  ];

  useEffect(() => {
    setActionForNative(NativeActions.ANALYTICS, {
      event:      "specialist other",
      properties: {
        "autorenewal": "kak eto rabotaet otrkril",
      },
    });
  }, []);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return handleBackClick();
  });

  return <>
    {isOpen && createPortal(<div className={styles.wrapper}>
      <div className={styles.layout}>
        <PageHeader title={t(`${i18n_prefix}title`)} rightIcon={<Icon onClick={onClose}>close</Icon>} />
        <div className={styles.content}>
          <div className={styles.image}>
            <img src={"/illustrations/speacial_day_promo.svg"} alt="illustration" />
          </div>

          {listData.map((item, index) =>
            <List
              key={item.id ?? index}
              title={item.text}
              icon={item.icon}
            />)}
        </div>

        <div className={styles.footer}>{<ButtonDefault
          onClick={handleBackClick}>{t(`${i18n_prefix}button`)}</ButtonDefault>}</div>
      </div>
    </div>, document.body)}
  </>
}

export default AutoRenewalInfo;