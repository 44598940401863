import {useCallback, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector}           from "store";
import {
  addNotification,
  PurchaseType,
  selectPaymentId,
  selectPurchaseType,
  setFreezeWaiting,
  setPaymentId,
  setPurchaseIsLoading,
  setPurchaseType,
}                                                 from "store/features";
import {useTranslation}                           from "react-i18next";
import {useGetPurchaseStateMutation}              from "shared/api";
import {PurchaseState}                            from "shared/model";


interface Props {
  mode?: "app" | "pro";
  onSetNotification?: () => void;
  refetch?: () => void;
}

export const useCheckPaymentStatus = ({
  mode = "pro",
  onSetNotification = () => {
  },
  refetch = () => {
  },
}: Props) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const paymentId = useAppSelector(selectPaymentId);
  const purchaseType = useAppSelector(selectPurchaseType);

  const [getPurchaseState, {data: stateResponse}] = useGetPurchaseStateMutation();

  const timeoutListener = useRef<NodeJS.Timeout>();
  const [requestCount, setRequestCount] = useState(0);
  const [paymentStatusLoading, setPaymentStatusLoading] = useState(false);

  const sendQuery = useCallback(() => {
    if (paymentId) {
      getPurchaseState({id: paymentId, mode});
      setRequestCount(prev => prev + 1);
      setPaymentStatusLoading(true);
      dispatch(setPurchaseIsLoading(true));
    }
  }, [dispatch, paymentId, getPurchaseState]);

  const handleResetStates = useCallback(() => {
    clearTimeout(timeoutListener.current);
    setPaymentStatusLoading(false);
    dispatch(setPurchaseIsLoading(false));
    dispatch(setPaymentId(null));
    dispatch(setPurchaseType(null));
    setRequestCount(0);
  }, [dispatch]);

  useEffect(() => {
    if (!paymentId) return;
    if (!stateResponse?.content.state) return;
    const state = stateResponse?.content.state;
    if (state === PurchaseState.WAITING && requestCount < 10) {
      timeoutListener.current = setTimeout(sendQuery, 1000);
    }
    if (purchaseType === PurchaseType.TARIFF_FREEZE) {
      dispatch(setFreezeWaiting(true));
    }
    if (state === PurchaseState.ENDED) {
      timeoutListener.current = setTimeout(() => {
        if (purchaseType === PurchaseType.AUTO_RENEWAL) {
          onSetNotification();
        }
        if (purchaseType === PurchaseType.CATALOGUE_INIT) {
          dispatch(addNotification({icon: "validation", text: t("state.waiting_payment")}));
        }
        handleResetStates();
        refetch();
      }, 1500);
    } else if (requestCount >= 10) {
      handleResetStates();
      dispatch(addNotification({icon: "validation", text: t("state.waiting_payment")}));
      refetch();
    }
  }, [dispatch, stateResponse, requestCount, sendQuery, purchaseType, handleResetStates, refetch, onSetNotification, t, paymentId]);

  useEffect(() => {
    if (!paymentId) return;
    sendQuery();

    return () => {
      clearTimeout(timeoutListener.current);
      setRequestCount(0);
      handleResetStates();
    };
  }, [handleResetStates, paymentId, sendQuery]);

  return {paymentStatusLoading};
}
