import React, {
  ChangeEvent,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
}                                                 from "react";
import {useNavigate}                              from "react-router-dom";
import {useTranslation}                           from "react-i18next";
import {
  useCatalogueServicesSaveMutation,
  useGetCatalogueServicesQuery,
}                                                 from "services/specialists/catalogue";
import {SkeletonLayout}                           from "entities/skeleton";
import {PageLayout}                               from "entities/general";
import {
  useNativeHandler,
  setActionForNative,
  useSkeletonThrottling,
  onParentClick,
}                                                 from "shared/lib";
import {NativeActions}                            from "shared/model";
import {CheckboxInput, ButtonDefault, Icon, List} from "shared/ui";
import {selectPurchaseIsLoading}                  from "store/features";
import {useAppSelector}                           from "store";
import styles                                     from "./styles.module.scss";


function ListItemRender({id, index, title, isChecked, currentRef, onChange, onParentClick}: {
  id: number;
  index: number;
  title: string;
  isChecked: boolean;
  currentRef: MutableRefObject<HTMLInputElement[]>;
  onParentClick: (event: MouseEvent<HTMLDivElement>, index: number) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}) {
  const handleParentClick = (event: MouseEvent<HTMLDivElement>) => onParentClick(event, index);

  return <List
    title={title}
    onClick={handleParentClick}
    controlIcon={
      <CheckboxInput
        ref={(element) => {
          if (element) {
            currentRef.current[index] = element
          }
        }}
        id={`${id}`}
        checked={isChecked}
        onChange={onChange}
      />
    }
  />
}

const i18n_prefix = "page.specialist.my_category."
const PurchaseCatalogueServicesListPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const checkboxListRefs = useRef<HTMLInputElement[]>([]);
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);

  const {
    data:       services,
    isLoading:  servicesIsLoading,
    isFetching: servicesIsFetching,
    refetch:    refetchServices,
  } = useGetCatalogueServicesQuery();

  const {extendedLoading} = useSkeletonThrottling({isLoading: servicesIsLoading || servicesIsFetching});
  const [saveServices, {isLoading: saveServicesLoading, isSuccess}] = useCatalogueServicesSaveMutation();

  const [checkedServices, setCheckedServices] = useState<number[]>([]);

  const handleBackClick = () => navigate("/tariffs/catalogue");

  const handleParentClick = (event: MouseEvent<HTMLDivElement>, index: number) => onParentClick(event, index, checkboxListRefs);

  const handleServicesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newIds = new Set(checkedServices);
    if (event.target.checked) {
      newIds.add(+event.target.id);
    } else {
      newIds.delete(+event.target.id);
    }
    setCheckedServices([...newIds]);
  }

  const handleSaveServices = () => saveServices(checkedServices);

  const handleMoveToCategoriesClick = () => setActionForNative(NativeActions.OPEN_NEWSLINE_FILTERS);

  useEffect(() => {
    if (services?.content.services) {
      const newIds: number[] = [];
      services.content.services.map((service) => service.is_selected ? newIds.push(service.id) : service);
      setCheckedServices(newIds);
    }
  }, [services]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/tariffs/catalogue");
    }
  }, [isSuccess, navigate]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (purchaseInProgress) return;
    return handleBackClick();
  });

  useNativeHandler<{ is_changed: boolean }>("tariffs", NativeActions.OPEN_NEWSLINE_FILTERS, data => {
    if (data.is_changed) {
      refetchServices();
    }
  });

  const footer = <>
    {extendedLoading ? <SkeletonLayout height={48} theme="dark" /> :
      services?.content.services && services.content.services.length > 0 && <div className={styles.footer}>
        <div>{t(`${i18n_prefix}footer`)}</div>

        <ButtonDefault disabled={extendedLoading} loading={saveServicesLoading} onClick={handleSaveServices}>
          {t(`${i18n_prefix}button`)}
        </ButtonDefault>
      </div>}
  </>

  return <PageLayout
    headerTitle={t(`${i18n_prefix}header.title`)}
    hasSpaceChildren
    headerSubtitle={t(`${i18n_prefix}header.subtitle`)}
    leftIcon={<Icon onClick={handleBackClick}>keyboard_arrow_left</Icon>}
    footer={footer}
    isLoading={extendedLoading}
  >
    <>
      {extendedLoading
        ? <>{[...Array(3)].map((_, index) => <SkeletonLayout key={index} height={22} />)}</>

        : !services?.content.services.length ?
          <div className={styles.period_plug}>
            <div className={styles.plug_content}>
              <Icon size={40} className="text gray mgb-3">search</Icon>
              <div className={styles.plug_content_text}>
                <div className={styles.plug_content_title}>{t(`${i18n_prefix}plug.title`)}</div>
                <div className={styles.plug_content_description}>
                  {t(`${i18n_prefix}plug.subtitle`)}
                </div>
              </div>
              <ButtonDefault
                onClick={handleMoveToCategoriesClick}
                buttonRound
              >
                {t(`${i18n_prefix}plug.button`)}
              </ButtonDefault>
            </div>
          </div>

          : services?.content.services.map((service, index) =>
            <ListItemRender
              index={index}
              key={service.id}
              id={service.id}
              title={service.name}
              currentRef={checkboxListRefs}
              isChecked={checkedServices.includes(service.id)}
              onParentClick={handleParentClick}
              onChange={handleServicesChange}
            />,
          )}
    </>
  </PageLayout>
};

export default PurchaseCatalogueServicesListPage;