import React, {forwardRef, InputHTMLAttributes} from "react";
import cn                                       from "classnames";
import styles                                   from "./styles.module.scss";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  checkboxSize?: "small" | "normal";
}

const CheckboxInput = forwardRef<HTMLInputElement, Props>(({checkboxSize = "normal", ...props}: Props, ref) => {

  return <div className={cn(styles.checkbox, {[styles.small]: checkboxSize === "small"})}>
    <input ref={ref} id={props.id} type="checkbox" {...props} />
    <label
      htmlFor={props.id}
      className={cn(styles.checkbox_custom, {[styles.isDisabled]: props.disabled}, {[styles.small]: checkboxSize === "small"})}></label>
  </div>
})

export default CheckboxInput;