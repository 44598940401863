import * as React                                 from "react";
import {useEffect, useState}                      from "react";
import {CropperComponent}                         from "components/CropperComponent";
import {IdentityVerification}                     from "components/IdentityVerification";
import {CloseRegistrationInApp}                   from "components/CloseRegistrationInApp";
import {useNativeHandler, setActionForNative}     from "shared/lib";
import {Nullable, NativeActions}                  from "shared/model";
import {ButtonDefault, List, PageHeader}          from "shared/ui";
import styles                                     from "./styles.module.scss";
import {useAppSelector}                           from "store";
import {selectMethodType, selectRegistrationType} from "store/features";
import {
  RegistrationMethodType,
  RegistrationType,
  useUploadNonResidentPhotoDocumentMutation,
}                                                 from "services/registration/registration";
import i18next                                    from "i18next";
import {MainLayout}                               from "entities/general";
import {ImageLoadError, ImageLoading}             from "entities/registration";
import {selectBiometricUrl}                       from "store/features/registration";


const {t} = i18next;

const i18n_prefix = "page.registration.document."

function MainPage({onNext, registrationMethod}: {
  onNext: () => void,
  registrationMethod: Nullable<RegistrationType>
}) {
  const methodType = useAppSelector(selectMethodType);

  return <MainLayout>
    <PageHeader
      title={methodType === RegistrationMethodType.VERIFICATION
        ? t("page.verification.header")
        : t(`${i18n_prefix}header.title`)}
      subtitle={registrationMethod === RegistrationType.NON_RESIDENT
        ? t(`${i18n_prefix}header.description_non_resident`)
        : t(`${i18n_prefix}header.description`)}
      rightIcon={<CloseRegistrationInApp />}
    />

    <div className={styles.content}>
      <div className={styles.content_illustration}>
        <img src={"/illustrations/user-id-1.svg"} alt="img" />
      </div>

      <List
        icon="validation"
        title={registrationMethod !== RegistrationType.NON_RESIDENT
          ? t(`${i18n_prefix}list.item_1`)
          : t(`${i18n_prefix}list.non_resident_item_1`)}
      />
      <List
        icon="validation"
        title={registrationMethod !== RegistrationType.NON_RESIDENT
          ? t(`${i18n_prefix}list.item_2`)
          : t(`${i18n_prefix}list.non_resident_item_2`)}
      />
      <List
        icon="validation"
        title={registrationMethod !== RegistrationType.NON_RESIDENT
          ? t(`${i18n_prefix}list.item_3`)
          : t(`${i18n_prefix}list.non_resident_item_3`)}
      />

      {registrationMethod === RegistrationType.NON_RESIDENT &&
        <List
          icon="validation"
          title={t(`${i18n_prefix}list.non_resident_item_4`)}
        />}
    </div>

    <div className={styles.footer}>
      <ButtonDefault onClick={onNext}>
        {t("common.next")}
      </ButtonDefault>
    </div>
  </MainLayout>
}

const WithDocument = () => {
  const [step, setStep] = useState("1");
  const registrationMethod = useAppSelector(selectRegistrationType);
  const biometricUrlFromMethods = useAppSelector(selectBiometricUrl);

  const [digest, setDigest] = useState<Nullable<string>>(null);
  const [documentPhoto, setDocumentPhoto] = useState<Nullable<string>>(null);
  const [photoIsLoading, setPhotoIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const [uploadDocumentPhoto, {isSuccess}] = useUploadNonResidentPhotoDocumentMutation();

  const handleMakeDocPhotoClick = () => setActionForNative(NativeActions.MAKE_DOC_PHOTO);

  useNativeHandler<{ digest: string }>("registration", NativeActions.MAKE_DOC_PHOTO, data => {
    if (registrationMethod !== RegistrationType.NON_RESIDENT) {
      setDigest(data.digest);
    }
    if (registrationMethod === RegistrationType.NON_RESIDENT) {
      setDocumentPhoto(data.digest);
    }
  });

  const handleMakeFacePhoto = () => setActionForNative(NativeActions.MAKE_FACE_PHOTO);

  const handleIdentityClick = () => {
    handleMakeFacePhoto();
  }

  useEffect(() => {
    if (isSuccess) {
      setStep("3");
      setPhotoIsLoading(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (documentPhoto) {
      uploadDocumentPhoto({document_front: documentPhoto});
    }
  }, [documentPhoto, uploadDocumentPhoto]);

  useEffect(() => {
    if (digest) {
      setStep("2");
      setPhotoIsLoading(false);
    }
  }, [digest]);

  useNativeHandler("registration", NativeActions.OPEN_UPLOAD_LOADER, () => {
    if (step === "3") return;
    if (!digest || !documentPhoto) {
      setPhotoIsLoading(true);
    }
  });

  useNativeHandler("registration", NativeActions.OPEN_UPLOAD_ERROR, () => {
    if (step === "3") return;
    if (!digest || !documentPhoto) {
      setUploadError(true);
    }
  });

  const handleTryAgain = () => {
    setUploadError(false);
    setPhotoIsLoading(false);
  }

  useEffect(() => {
    if (biometricUrlFromMethods) {
      setStep("3");
    }
  }, [biometricUrlFromMethods]);

  const renderPage = () => {
    switch (step) {
      case "1":
        return <MainPage onNext={handleMakeDocPhotoClick} registrationMethod={registrationMethod} />;
      case "2":
        return <CropperComponent setStep={setStep} digest={digest} onNext={() => setStep("3")} setDigest={setDigest} />;
      case "3":
        return <IdentityVerification onClick={handleIdentityClick} />
      default:
        return null;
    }
  }

  return <>
    {uploadError && <ImageLoadError onClick={handleTryAgain} />}
    {photoIsLoading && step !== "3" ? <ImageLoading /> : renderPage()}
  </>;
}

export default WithDocument;