import React            from "react";
import {List}           from "shared/ui/List";
import {SkeletonLayout} from "entities/skeleton";
import styles           from "./InitialPage.module.scss";


interface Props {
  isLoading: boolean;
  options?: string[];
}

const ContentSkeleton = Array.from({length: 2}, (_, index) => {
  return <SkeletonLayout key={index} height={40} />
});

export default function InitialPage({isLoading, options}: Props) {
  return <>
    {isLoading
      ? ContentSkeleton
      : <>
        <img src="/illustrations/saving-money-1.svg" className={styles.image} alt="" />
        {options?.map((data, index) => {
          return <List key={index} title={data} icon="validation" />
        })}
      </>}
  </>
}