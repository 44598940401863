import React             from "react";
import Lottie            from "lottie-react";
import {loaderAnimation} from "../../../assets/lotties";
import styles            from "./styles.module.scss";


export default function LoadingScreen() {
  return <>
    <div className={styles.loading_background} />
    <div className={styles.loading_holder}>
      <Lottie
        autoPlay
        animationData={loaderAnimation}
        style={{width: 32, height: 32}}
        loop
      />
    </div>
  </>
};