import * as React                                                 from "react";
import {useState}                                                 from "react";
import {useNavigate}                                              from "react-router-dom";
import {ButtonType, ButtonColor, TextBlock, ButtonDefault, InApp} from "shared/ui";
import styles                                                     from "./styles.module.scss";
import {useAppDispatch, useAppSelector}                           from "store";
import {selectMethodType, setIsNotMeFreedom, setRegistrationType} from "store/features";
import {useTranslation}                                           from "react-i18next";
import {RegistrationMethodType}                                   from "services/registration";


const CloseRegistrationInApp = ({isFreedom}: { isFreedom?: boolean }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const methodType = useAppSelector(selectMethodType);

  const [open, setOpen] = useState(false);

  const handleBackSelectRegistration = () => {
    if (isFreedom) {
      dispatch(setIsNotMeFreedom(true));
    }
    navigate("/registration");
  };

  const handleCloseInApp = () => {
    setOpen(false);
    dispatch(setRegistrationType(null));
  };

  return <>
    <div
      className={styles.close_icon}
      onClick={() => setOpen(true)}
    >
      close
    </div>

    <InApp open={open} onClose={handleCloseInApp}>
      <div className={styles.inApp_wrapper}>

        <div className={styles.inApp_image}>
          <img src="/illustrations/too-busy-1.svg" alt="img" />
        </div>

        <TextBlock
          title={methodType !== RegistrationMethodType.VERIFICATION
            ? t("in_app.close_registration.title")
            : t("in_app.close_verification.title")}
          description={methodType !== RegistrationMethodType.VERIFICATION
            ? t("in_app.close_registration.description")
            : t("in_app.close_verification.description")}
        />

        <div className={styles.inApp_buttons}>
          <ButtonDefault
            buttonType={ButtonType.WRAPPED}
            buttonColor={ButtonColor.GRAY}
            onClick={handleCloseInApp}
          >
            {t("common.cancel")}
          </ButtonDefault>

          <ButtonDefault
            hasSpace
            onClick={handleBackSelectRegistration}
          >
            {t("common.interrupt")}
          </ButtonDefault>
        </div>
      </div>
    </InApp>
  </>
}

export default CloseRegistrationInApp;