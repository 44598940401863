import React  from "react";
import {Icon} from "shared/ui";
import styles from "./SystemResponse.module.scss";


interface Props {
  title: string;
  description: string;
  icon: string;
  children?: React.ReactNode;
}

export default function SystemResponse({children, icon, title, description}: Props) {
  return <div className={styles.wrapper}>
    <Icon className={styles.icon}>{icon}</Icon>
    <div className={styles.text}>
      <span>{title}</span>
      <span>{description}</span>
    </div>

    {children}
  </div>
};