import React, {useEffect, useState}             from "react";
import {useTranslation}                         from "react-i18next";
import {Icon}                                   from "shared/ui";
import {useToggleSpecialistToFavouriteMutation} from "shared/api";
import {Nullable}                               from "shared/model";
import {addNotification}                        from "store/features";
import {useAppDispatch}                         from "store";


interface Props {
  id: Nullable<number>;
  workId?: Nullable<number>;
  isFavourite?: boolean;
  isLoading?: boolean;
}

export default function SpecialistProfileFavourite({
  id,
  workId,
  isFavourite: propIsFavourite,
  isLoading:   extendedLoading,
}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const [isFavourite, setIsFavourite] = useState(false);

  const [toggleFavourite, {isLoading, isSuccess, reset}] = useToggleSpecialistToFavouriteMutation();

  const handleToggleFavourite = () => {
    if (isLoading) return;
    if (extendedLoading) return;
    if (!id) return;

    const action = isFavourite ? "delete" : "add";

    if (!isFavourite) {
      return toggleFavourite({specialist_id: id, work_id: workId ? workId : undefined, action})
    }
    return toggleFavourite({specialist_id: id, work_id: workId ? workId : undefined, action});
  }

  useEffect(() => {
    if (typeof propIsFavourite === "boolean") {
      setIsFavourite(propIsFavourite);
    }
  }, [propIsFavourite]);

  useEffect(() => {
    if (isSuccess) {
      if (isFavourite) {
        dispatch(addNotification({text: t("page.specialist.profile.favourite.delete")}));
        setIsFavourite(false);
      } else {
        dispatch(addNotification({text: t("page.specialist.profile.favourite.add")}));
        setIsFavourite(true);
      }
    }
    reset();
  }, [isSuccess, isFavourite]);

  return <Icon onClick={handleToggleFavourite} className={`text ${isFavourite ? "warning" : ""}`}>
    {isFavourite ? "star_filled" : "star"}
  </Icon>;
};