import React, {ChangeEvent, useEffect, useState} from "react";
import {useNavigate}                             from "react-router-dom";
import {useTranslation}                          from "react-i18next";
import {PageLayout, InAppLayout}                 from "entities/general";
import {
  ButtonColor,
  ButtonType,
  ButtonDefault,
  GalleryMediaItem,
  Icon,
  Textarea,
  GalleryWrapper,
}                                                from "shared/ui";
import {useNativeHandler, setActionForNative}    from "shared/lib";
import {
  SpecialistProfileDataMediaItem,
  NativeActions,
  Nullable,
  setProfile,
  setProfileIsCashed,
}                                                from "shared/model";
import {useProfileMediaCreateMutation}           from "shared/api";
import {useAppDispatch, useAppSelector}          from "store";
import {addNotification, selectLocale}           from "store/features";
import cn                                        from "classnames";
import styles                                    from "./ProfileMediaUpload.module.scss";


const MEDIA_LIMIT = 5;
const i18n_prefix = "page.specialist.profile.media_upload."
export default function ProfileMediaUpload() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const locale = useAppSelector(selectLocale);

  const [createMedia, {isLoading, isSuccess}] = useProfileMediaCreateMutation();

  const [limit, setLimit] = useState(0);
  const [mediaList, setMediaList] = useState<SpecialistProfileDataMediaItem[]>([]);
  const [openRemoveMediaInApp, setOpenRemoveMediaInApp] = useState(false);
  const [closePageInAppOpen, setClosePageInAppOpen] = useState(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<Nullable<number>>(null);
  const [description, setDescription] = useState("");

  const handleToggleClosePage = () => setClosePageInAppOpen(prev => !prev);

  const handleBackClick = () => navigate("/profile");

  const handleCloseClick = () => {
    if (!mediaList.length) {
      return handleBackClick();
    }
    return handleToggleClosePage();
  }

  const handleAddMediaClick = () => {
    if (limit === MEDIA_LIMIT) return;
    const currentLimit = MEDIA_LIMIT - limit;
    setActionForNative(NativeActions.OPEN_PORTFOLIO_UPLOAD, {limit: currentLimit});
  }

  const handleSaveMedia = () => {
    createMedia({media: mediaList, description: description.trim() !== "" ? description : undefined});
  }

  const handleToggleDeleteItem = () => setOpenRemoveMediaInApp(prev => !prev);

  const handleMediaClick = (mediaIndex: number) => {
    setRemoveItemIndex(mediaIndex);
    handleToggleDeleteItem();
  }

  const handleRemoveMediaClick = () => {
    setMediaList(prev => prev.filter((_, index) => index !== removeItemIndex));
    setLimit(prev => prev - 1);
    handleToggleDeleteItem();
  }

  const handleSetDescription = (event: ChangeEvent<HTMLTextAreaElement>) => setDescription(event.currentTarget.value);

  const uploadButtonText = locale === "ru" ? t(`${i18n_prefix}upload_button`, {count: limit}) : t(`${i18n_prefix}upload_button`);

  useNativeHandler<{
    media: SpecialistProfileDataMediaItem[]
  }>("profile", NativeActions.OPEN_PORTFOLIO_UPLOAD, (data) => {
    if (data.media) {
      setMediaList(prev => ([...prev, ...data.media]));
      setLimit(prev => prev + data.media.length);
    }
  });

  useEffect(() => {
    return () => {
      setMediaList([]);
      setLimit(0);
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(addNotification({text: t(`${i18n_prefix}toast_message`)}));
      handleBackClick();
      dispatch(setProfile(null));
      dispatch(setProfileIsCashed(false));
    }
  }, [isSuccess]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return navigate(-1);
  });

  return <PageLayout
    headerTitle={t(`${i18n_prefix}title`)}
    headerSubtitle={t(`${i18n_prefix}subtitle`, {count: limit})}
    rightIcon={<Icon onClick={handleCloseClick}>close</Icon>}
    footer={<ButtonDefault
      disabled={!mediaList.length || isLoading}
      loading={isLoading}
      onClick={handleSaveMedia}
    >
      {t("common.save")}
    </ButtonDefault>}
  >
    <GalleryWrapper>
      <ul className={styles.wrapper}>
        <li onClick={handleAddMediaClick} className={cn(styles.add_button, {[styles.disabled]: limit === MEDIA_LIMIT})}>
          <Icon className={styles.add_button_icon}>add</Icon>

          <div className={styles.add_button_text}>{uploadButtonText}</div>
        </li>

        {mediaList.map((media, index) => {
          return <li key={index}>
            <GalleryMediaItem hasOpen={false} size={92} type={media.type} digest={media.digest} />
            <div onClick={() => handleMediaClick(index)} className={styles.delete_button}>delete</div>
          </li>
        })}
      </ul>
    </GalleryWrapper>

    <div className={styles.textarea_wrapper}>
      <span className={styles.textarea_title}>{t(`${i18n_prefix}text.label`)}</span>
      <Textarea
        placeholder={t(`${i18n_prefix}text.placeholder`)}
        hasSymbolCounter={false}
        value={description}
        onChange={handleSetDescription}
      />
    </div>

    <InAppLayout
      isOpen={openRemoveMediaInApp}
      img={"/illustrations/delete-4.svg"}
      title={t(`${i18n_prefix}remove_media.title`)}
      description={t(`${i18n_prefix}remove_media.description`)}
      onClose={handleToggleDeleteItem}
    >
      <ButtonDefault
        onClick={handleToggleDeleteItem}
        buttonColor={ButtonColor.GRAY}
        buttonType={ButtonType.WRAPPED}
      >
        {t("common.cancel")}
      </ButtonDefault>

      <ButtonDefault onClick={handleRemoveMediaClick} buttonColor={ButtonColor.ERROR} hasSpace>
        {t("common.delete")}
      </ButtonDefault>
    </InAppLayout>

    <InAppLayout
      isOpen={closePageInAppOpen}
      img={"/illustrations/no-conversation-3.svg"}
      title={t(`${i18n_prefix}close_page.title`)}
      description={t(`${i18n_prefix}close_page.description`)}
      onClose={handleToggleClosePage}
    >
      <ButtonDefault
        onClick={handleToggleClosePage}
        buttonColor={ButtonColor.GRAY}
        buttonType={ButtonType.WRAPPED}
      >
        {t("common.cancel")}
      </ButtonDefault>

      <ButtonDefault onClick={handleBackClick} buttonColor={ButtonColor.ERROR} hasSpace>
        {t("common.close")}
      </ButtonDefault>
    </InAppLayout>
  </PageLayout>
};