import {Nullable}                                                           from "shared/model";
import {useFetchProfileAppPricesMutation, useFetchProfileProPricesMutation} from "shared/api";
import {AppMode}                                                            from "store/features";

export const fetchPrices = ({appMode}: { appMode: Nullable<string> }) => {
  const [getPrices, {data: pricesFromTabs}] = (() => {
    const [getPricesPro, {data: proPrices}] = useFetchProfileProPricesMutation();
    const [getPricesApp, {data: appPrices}] = useFetchProfileAppPricesMutation();


    if (appMode === AppMode.SPECIALIST) {
      return [getPricesPro, {data: proPrices}];
    }
    return [getPricesApp, {data: appPrices}];
  })();

  return {getPrices, pricesFromTabs};
}