import React                                           from "react";
import {useTranslation}                                from "react-i18next";
import {useNavigate}                                   from "react-router-dom";
import {ButtonColor, ButtonType, ButtonDefault, Label} from "shared/ui";
import {Nullable}                                      from "shared/model";
import {AppMode}                                       from "store/features";
import {SkeletonLayout}                                from "entities/skeleton";
import cn                                              from "classnames";
import styles                                          from "./styles.module.scss";


interface Props {
  id: Nullable<number>;
  about?: Nullable<string>;
  appMode: Nullable<string>;
  isViewMode?: boolean;
  isLoading?: boolean;
  moderated?: boolean;
}

const i18n_prefix = "page.specialist.profile.about."
export default function AboutBlock({
  about,
  appMode,
  id: specialistId,
  isViewMode = false,
  isLoading,
  moderated,
}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleEditClick = () => navigate("/profile/about");

  return <div className={styles.wrapper}>
    {isLoading
      ? <SkeletonLayout height={22} borderRadius={6} width={130} theme="dark" />
      : <span className={styles.header}>{t(`${i18n_prefix}title`)}</span>}

    {isLoading
      ? <div className={styles.skeleton_text}>
        <SkeletonLayout height={20} borderRadius={6} />
        <SkeletonLayout height={20} borderRadius={6} />
        <SkeletonLayout height={20} borderRadius={6} />
        <SkeletonLayout height={20} borderRadius={6} width={"60%"} />
      </div>
      : <div className={styles.text_wrapper}>
        {!moderated && appMode === AppMode.SPECIALIST &&
          <Label labels={[{title: `${t(`${i18n_prefix}on_moderation`)}`, style: "gray"}]} clasNames={styles.label} />}
        <span
          className={cn(styles.text, {[styles.without_text]: !about}, {[styles.moderated]: !moderated && appMode === AppMode.SPECIALIST})}>
        {about && about}
          {appMode === AppMode.CLIENT && !about && t(`${i18n_prefix}empty.client`)}
          {appMode === AppMode.SPECIALIST && !about && t(`${i18n_prefix}empty.specialist`)}
    </span>
      </div>}

    {!specialistId && appMode === AppMode.SPECIALIST && !isViewMode && <>
      {isLoading
        ? <SkeletonLayout height={46} theme="dark" />
        : <ButtonDefault buttonType={ButtonType.WRAPPED} buttonColor={ButtonColor.GRAY} onClick={handleEditClick}>
          {t(`${i18n_prefix}button.${!about ? "add" : "update"}`)}
        </ButtonDefault>}
    </>}
  </div>
};