import React, {useEffect, useRef, useState} from "react";
import BaseInput                            from "../BaseInput";
import BaseInputProps                       from "../BaseInputProps";
import styles                               from "./styles.module.scss";


interface Props extends Omit<BaseInputProps, "onChange" | "value"> {
  value: string;
  handleChange: (value: string) => void;
  $valueLength?: number;
  errorMessage?: string;
  triggerBlur?: boolean;
}

export default function OtpInput({
  value = "",
  handleChange,
  $valueLength = 6,
  errorMessage,
  triggerBlur,
  ...props
}: Props) {
  const [innerValue, setInnerValue] = useState("");
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const onHiddenInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.slice(0, $valueLength);
    setInnerValue(newValue);
    handleChange(newValue);
  };

  useEffect(() => {
    if (triggerBlur) {
      hiddenInputRef.current?.blur();
    }
  }, [triggerBlur]);

  return (
    <div className={styles.wrapper} onClick={() => hiddenInputRef.current?.focus()}>
      <input
        ref={hiddenInputRef}
        type="tel"
        value={innerValue}
        onChange={onHiddenInputChange}
        className={styles.hidden_input}
        maxLength={$valueLength}
        {...props}
      />
      <div className={styles.inputs_holder}>
        {Array.from({length: $valueLength}, (_, i) => innerValue[i] || "").map((digit, index) => (
          <BaseInput
            key={index}
            error={!!errorMessage}
            value={digit}
            readOnly
          />
        ))}
      </div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </div>
  );
}