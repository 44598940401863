import {useCallback, useState} from "react";

export function useIntersectionObserver<T extends Element>({
  root = null,
  rootMargin = "0px",
  threshold = 0,
} = {}) {
  const [observer, setObserver] = useState<IntersectionObserver>();
  const [isIntersecting, setIntersecting] = useState(false);

  const measureRef = useCallback(
    (node: T | null) => {
      if (node) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setIntersecting(entry.isIntersecting);
          },
          {root, rootMargin, threshold},
        );

        observer.observe(node);
        setObserver(observer);
      }
    },
    [root, rootMargin, threshold],
  );

  return {measureRef, isIntersecting, observer};
};