import styles from "./LoadingIcon.module.scss";
import {Icon} from "shared/ui";


interface Props {
  classNames?: string;
  iconSize?: number;
  isAbsolutePosition?: boolean;
}

export default function LoadingIcon({classNames, iconSize, isAbsolutePosition = true}: Props) {
  return <div
    style={{position: isAbsolutePosition ? "absolute" : undefined}}
    className={`${styles.loading} ${classNames}`}
  >
    <Icon size={iconSize}>loading</Icon>
  </div>
}