import React, {useEffect, useRef, useState}                  from "react";
import {useTranslation}                                      from "react-i18next";
import {useNavigate, useParams}                              from "react-router-dom";
import {useGetSelfReviewsTabsQuery}                          from "services/reviews";
import {ReviewFilterTypes, ReviewsListItem, ReviewsOverview} from "services/reviews/types";
import {useLazyGetReviewTabsQuery}                           from "services/reviews/reviews";
import {Icon, Tabs}                                          from "shared/ui";
import {
  useIntersectionObserver,
  useDebounce,
  useNativeHandler,
  useSetHeaders,
  formatNumberWithSpaces,
}                                                            from "shared/lib";
import {Nullable, NativeActions}                             from "shared/model";
import {ReviewItem, ReviewRating}                            from "entities/reviews";
import {SkeletonTabs, SkeletonLayout}                        from "entities/skeleton";
import {PageLayout}                                          from "entities/general";
import {ReviewsSort}                                         from "components/ReviewsSort";
import styles                                                from "./styles.module.scss";


interface Props {
  id: number | null;
  page: number;
  serviceId?: number | null;
  mode: "specialist" | "client" | "self";
  isLoading: boolean;
  hasMore?: boolean;
  overview?: ReviewsOverview;
  filters: ReviewFilterTypes;
  reviews?: ReviewsListItem[];
  onSetServiceId?: (value: number | null) => void;
  onSetPage: React.Dispatch<React.SetStateAction<number>>;
  setFilters: React.Dispatch<React.SetStateAction<ReviewFilterTypes>>;
}

export default function ReviewsList({
  id,
  page,
  serviceId,
  mode,
  isLoading,
  hasMore,
  overview,
  filters,
  reviews,
  onSetServiceId,
  onSetPage,
  setFilters,
}: Props) {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {service_id: paramServiceId} = useParams();
  const service_id = paramServiceId ? parseInt(paramServiceId) : null;
  const firstRenderRef = useRef(false);

  const {isSuccess} = useSetHeaders();
  const {measureRef, isIntersecting, observer} = useIntersectionObserver();

  const [getTabs, {
    data:      reviewTabs,
    isLoading: tabsIsLoading,
    isSuccess: getTabsIsSuccess,
  }] = useLazyGetReviewTabsQuery();
  const {
    data:      selfReviewTabs,
    isLoading: selfTabsIsLoading,
  } = useGetSelfReviewsTabsQuery({id: id as number, service_id}, {skip: mode !== "self" || !isSuccess});

  const [openSort, setOpenSort] = useState(false);
  const [tabCount, setTabCount] = useState(4);

  const tabs = mode === "self" ? selfReviewTabs : reviewTabs;
  const tabsLoading = mode === "self" ? selfTabsIsLoading : tabsIsLoading;
  const stars = [
    overview?.star5 ?? 0,
    overview?.star4 ?? 0,
    overview?.star3 ?? 0,
    overview?.star2 ?? 0,
    overview?.star1 ?? 0,
  ];

  const handleCloseSort = () => setOpenSort(false);

  const handleOnCloseClick = () => {
    if (mode === "client") {
      return navigate(`/client-profile/${id}`);
    }
    return navigate(`/profile${id ? `/${id}` : ""}`);
  }

  const handleFetchMore = useDebounce(() => {
    if (!hasMore) return;
    onSetPage(page => page + 1);
  }, 200);

  const handleTabClick = (id: Nullable<number>, count: number) => {
    setTabCount(count);
    onSetServiceId && onSetServiceId(id);
  }

  useEffect(() => {
    if (!firstRenderRef.current && !isLoading) {
      firstRenderRef.current = true;
    }
  }, [isLoading]);

  useEffect(() => {
    if (!id || mode === "client" || mode === "self") return;
    if (getTabsIsSuccess) return;
    getTabs({id: id as number, serviceId: service_id})
  }, [id, service_id, getTabsIsSuccess]);

  useEffect(() => {
    if (isIntersecting) {
      handleFetchMore();
      observer?.disconnect();
    }
  }, [isIntersecting]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (openSort) return;
    return handleOnCloseClick();
  });

  return <PageLayout
    headerTitle={t("page.reviews.title")}
    leftIcon={<Icon onClick={handleOnCloseClick}>keyboard_arrow_left</Icon>}
    isLoading={!firstRenderRef.current ? isLoading : tabsLoading}
    rightIcon={<div
      onClick={() => setOpenSort(true)}
      className={styles.header_button}
    >
      sort
    </div>}
  >
    <div className={styles.content_holder}>
      {mode !== "client" && <div className={styles.tabs}>
        {(!firstRenderRef.current ? isLoading : tabsLoading)
          ? <SkeletonTabs />
          : <>
            {tabs?.tabs.map((tab) => <Tabs
              key={tab.id}
              onClick={() => handleTabClick(tab.id, tab.count)}
              activeTab={tab.id === serviceId}>
              {`${tab.text} (${formatNumberWithSpaces(tab.count)})`}
            </Tabs>)}
          </>}
      </div>}

      {isLoading && page === 1 ?
        <SkeletonLayout
          height={102}
          borderRadius={12}
          count={tabCount}
          containerClass={styles.container_reviews}
        />

        : <>
          <ReviewRating
            rate={overview?.rate}
            nmbReviews={overview?.nmb_reviews}
            stars={stars}
          />

          {!reviews?.length ?
            <span className={styles.no_reviews}>{t("page.reviews.not_reviews")}</span>
            : null}

          {reviews?.map((review, index, array) => {
            if (array.length === index + 3) {
              return <div key={review.id} ref={measureRef}>
                <ReviewItem
                  key={review.id}
                  name={review.name}
                  date={review.created_at}
                  rating={review.rate}
                  review_text={review.text}
                  media={review.media}
                  price={review.sum}
                  isApproved={review.is_approved}
                />
              </div>
            }

            return <ReviewItem
              key={review.id}
              name={review.name}
              date={review.created_at}
              rating={review.rate}
              review_text={review.text}
              media={review.media}
              price={review.sum}
              isApproved={review.is_approved}
            />
          })}
        </>}

      <ReviewsSort
        isOpen={openSort}
        filters={filters}
        setFilters={setFilters}
        onSetPage={onSetPage}
        onClose={handleCloseSort}
      />
    </div>
  </PageLayout>
}