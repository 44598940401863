import * as React                                            from "react";
import {useEffect, useState}                                 from "react";
import {useParams}                                           from "react-router-dom";
import {ReviewFilterTypes, ReviewsOverview, ReviewsListItem} from "services/reviews/types";
import {ReviewsList}                                         from "components/ReviewsList";
import {useSetHeaders, useSkeletonThrottling}                from "shared/lib";
import {useGetSpecialistProfileReviewsMutation}              from "services/reviews";


const SelfReviews = () => {
  const {service_id: paramServiceId} = useParams();
  const service_id = paramServiceId ? parseInt(paramServiceId) : null;

  const [serviceId, setServiceId] = useState<number | null>(null);
  const [filters, setFilters] = useState<ReviewFilterTypes>(ReviewFilterTypes.DATE_DESC);
  const [page, setPage] = useState(1);

  const [reviewsList, setReviewsList] = useState<ReviewsListItem[]>([]);
  const [overviews, setOverviews] = useState<ReviewsOverview>();

  const [getSpecialistReviews, {data, isLoading}] = useGetSpecialistProfileReviewsMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading});

  const {isSuccess} = useSetHeaders();

  useEffect(() => {
    if (!isSuccess) return;
    getSpecialistReviews({page, service_id: serviceId, filter: filters});
  }, [filters, serviceId, page, getSpecialistReviews, isSuccess]);

  useEffect(() => {
    if (!data?.reviews_overview) return;
    if (data?.reviews_overview) {
      setOverviews(data.reviews_overview);
    }
  }, [data?.reviews_overview]);

  useEffect(() => {
    if (data?.reviews) {
      setReviewsList(prev => ([...prev, ...data?.reviews]));
    }
  }, [data?.reviews, data?.has_more]);

  useEffect(() => {
    if (serviceId) {
      setReviewsList([]);
      setPage(1);
    }
    if (!serviceId) {
      setReviewsList([]);
      setPage(1);
    }
  }, [serviceId]);

  useEffect(() => {
    if (filters) {
      setReviewsList([]);
    }
  }, [filters]);

  useEffect(() => {
    if (!service_id) return;
    setServiceId(service_id);
  }, [service_id]);

  const handleSetServiceId = (id: number | null) => {
    setServiceId(id);
  }

  return <ReviewsList
    hasMore={data?.has_more}
    mode="self"
    serviceId={serviceId}
    onSetServiceId={handleSetServiceId}
    id={null}
    page={page}
    reviews={reviewsList}
    overview={overviews}
    isLoading={extendedLoading}
    onSetPage={setPage}
    filters={filters}
    setFilters={setFilters}
  />
}

export default SelfReviews;