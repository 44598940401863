import * as React        from "react";
import {Route, Routes}   from "react-router-dom";
import {Credits}         from "pages/Specialist/Credits";
import {CreditsSchedule} from "pages/Specialist/Credits/CreditsSchedule";


const CreditPagesRoutes = () => {
  return <Routes>
    <Route path="/" element={<Credits />} />
    <Route path="/schedule/:id" element={<CreditsSchedule />} />
  </Routes>
};

export default CreditPagesRoutes;