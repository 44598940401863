import {isRejectedWithValue, type Middleware, type MiddlewareAPI} from "@reduxjs/toolkit";
import {setErrorMessage}                                          from "store/features/registration";
import {setActionForNative}                                       from "shared/lib";
import {NativeActions}                                            from "shared/model";

export const rtkQueryErrorLogger: Middleware = ({dispatch}: MiddlewareAPI) => (next) => (action) => {

  if (isRejectedWithValue(action)) {
    console.warn("We got a rejected action!", action);
  }

  if (action.payload && action?.payload?.message) {
    dispatch(setErrorMessage(action?.payload?.message));
  }

  if (!action.payload || typeof action.payload !== "object" || !("data" in action.payload)) return next(action);
  const {data} = action.payload as { data: { status: boolean, message: string } }
  const response = action.meta?.baseQueryMeta?.response;

  if (data && !data.status && data.message) {
    dispatch(setErrorMessage(data.message));
  }

  if (response?.status === 403) {
    setActionForNative(NativeActions.LOGOUT);
  }

  return next(action)
}
