import React  from "react";
import styles from "./styles.module.scss";

const ratingStars = [1, 2, 3, 4, 5];

export default function RatingStars({rating}: { rating: number }) {
  return <div className={styles.wrapper}>
    {ratingStars.map((star) => <div key={star} className={star <= rating ? styles.star_active : styles.star}>
      star_filled
    </div>)}
  </div>
}
