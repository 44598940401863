import React, {useEffect}               from "react";
import {useTranslation}                 from "react-i18next";
import {PageLayout}                     from "entities/general";
import {SkeletonLayout}                 from "entities/skeleton";
import {Icon, ButtonDefault, Accordion} from "shared/ui";
import {
  useNativeHandler,
  useSetHeaders,
  copyToClipboard,
  setActionForNative,
  useSkeletonThrottling,
}                                       from "shared/lib";
import {NativeActions}                  from "shared/model";
import {useAppDispatch}                 from "store";
import {addNotification, AppMode}       from "store/features";
import {checkProgress, useAnalytics}    from "./lib";
import {useFetchInvitationInfoMutation} from "./api";
import styles                           from "./Referral.module.scss";


const i18n_prefix = "page.customers.invitation."
export default function Referral() {
  const {t} = useTranslation();
  const {isSuccess, appMode, entryPoint} = useSetHeaders();

  const [fetchInvitationInfo, {data: invitationInfo, isLoading}] = useFetchInvitationInfoMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading});

  const handleOnCloseClick = () => setActionForNative(NativeActions.TERMINATE);

  useEffect(() => {
    if (isSuccess && appMode) {
      fetchInvitationInfo({appMode: appMode as AppMode});
    }
  }, [isSuccess, appMode]);

  useAnalytics({appMode, entryPoint});

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return handleOnCloseClick();
  });

  return <PageLayout
    headerTitle={t(`${i18n_prefix}title`)}
    rightIcon={<Icon onClick={handleOnCloseClick}>close</Icon>}
    toastPosition={82}
    footer={<FooterPage
      isLoading={extendedLoading}
      sharing={invitationInfo?.content.sharing}
      code={invitationInfo?.content.promocode}
      copyButton={t(`${i18n_prefix}copy_button`)}
      submitButton={t(`${i18n_prefix}submit_button`)}
      toastMessage={t(`${i18n_prefix}copy_toast`)}
    />}
    isLoading={extendedLoading}
  >
    {extendedLoading
      ? <SkeletonLayout height={80} />
      : <div className={styles.invite_count_wrapper}>
        <span className={styles.invite_count_header}>{invitationInfo?.content.bar.title ?? ""}</span>

        <span className={styles.indicator_container}>
        <span
          className={styles.indicator_bar}
          style={{width: `${checkProgress(invitationInfo?.content.bar.total, invitationInfo?.content.bar.current)}%`}}
        />
      </span>

        <span className={styles.invite_count_description}>{invitationInfo?.content.bar.description}</span>
      </div>}

    {extendedLoading
      ? <div className={styles.bonuses_info_wrapper}>
        <SkeletonLayout height={22} borderRadius={6} />

        <div className={`${styles.bonuses_info_list} mgt-3`}>
          <SkeletonLayout width={250} height={90} />
          <SkeletonLayout width={250} height={90} />
        </div>
      </div>
      : <>
        {!invitationInfo?.content.cards.length
          ? null
          : <div className={styles.bonuses_info_wrapper}>
            <span className={styles.content_header}>{t(`${i18n_prefix}bonuses`)}</span>

            <div className={styles.bonuses_info_list}>
              {invitationInfo.content.cards.map((card, index) => {
                return <div key={index} className={styles.info_card_wrapper}>
                  <span className={styles.info_card_title}>{card.title}</span>

                  <span
                    className={styles.info_card_description}>{card.description}</span>
                </div>
              })}
            </div>
          </div>}
      </>}

    {extendedLoading
      ? <div className={styles.questions_wrapper}>
        <SkeletonLayout height={22} borderRadius={6} />

        <div className="mgt-3">
          <SkeletonLayout height={56} />
        </div>
      </div>
      : <>
        {!invitationInfo?.content.faqs.length
          ? null
          : <div className={styles.questions_wrapper}>
            <span className={styles.content_header}>{t(`${i18n_prefix}questions`)}</span>

            {invitationInfo?.content.faqs.map((faq, index) => {
              return <Accordion
                key={index}
                title={faq.question}
                description={faq.answer}
              />
            })}
          </div>}
      </>}
  </PageLayout>
};

function FooterPage({isLoading, sharing, code, copyButton, submitButton, toastMessage}: {
  isLoading?: boolean,
  sharing?: string,
  code?: string,
  copyButton: string,
  submitButton: string,
  toastMessage: string,
}) {
  const dispatch = useAppDispatch();

  const handlePromoCodeClick = () => {
    copyToClipboard(code ?? "");
    dispatch(addNotification({text: toastMessage}));
  }

  const handleShareClick = () => setActionForNative(NativeActions.OPEN_SHARE, {text: sharing});

  return <div className={styles.footer_wrapper}>
    {isLoading
      ? <SkeletonLayout height={62} />
      : <div className={styles.copy_code_wrapper} onClick={handlePromoCodeClick}>
        <span>{code ?? ""}</span>

        <span>{copyButton ?? ""}</span>
      </div>}

    {isLoading
      ? <SkeletonLayout height={46} />
      : <ButtonDefault onClick={handleShareClick}>{submitButton ?? ""}</ButtonDefault>}
  </div>
}