import React, {TextareaHTMLAttributes, useEffect, useRef} from "react";
import cn                                                 from "classnames";
import styles                                             from "./Textarea.module.scss";
import {Icon}                                             from "shared/ui";


interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hint?: string;
  errorMessage?: string;
  hasSymbolCounter?: boolean;
  counterCount?: number;
}

export default function Textarea({hint, errorMessage, hasSymbolCounter = true, counterCount, ...props}: Props) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);


  const handleInput = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const maxHeight = window.innerHeight * 0.5;
    textarea.style.height = "8.8rem";

    const newHeight = textarea.scrollHeight <= maxHeight ? textarea.scrollHeight : maxHeight;
    textarea.style.height = `${newHeight}px`;
    textarea.style.overflowY = textarea.scrollHeight > maxHeight ? "scroll" : "hidden";
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.addEventListener("input", handleInput);
    handleInput();

    return () => {
      textarea.removeEventListener("input", handleInput);
    };
  }, [textareaRef]);

  const textCounterCn = cn(
    styles.text_counter,
    {[styles.error]: textareaRef.current && textareaRef.current.value.length > 300},
  );

  const textareaCn = cn(
    styles.textarea,
    {[styles.error]: hasSymbolCounter && textareaRef.current && textareaRef.current.value.length > 300},
  );

  return <div className={styles.wrapper}>

    <div className={styles.textarea_holder}>
      <textarea
        ref={textareaRef}
        {...props}
        className={textareaCn}
        style={{height: "8.8rem", overflowY: "hidden"}}
      />
      {hasSymbolCounter && <span className={textCounterCn}>{`${counterCount ?? 0}/300`}</span>}
    </div>

    {!!hint && !errorMessage && <div className={styles.hint_wrapper}>
      <Icon className={styles.hint_icon}>info</Icon>
      <span className={styles.hint_text}>{hint}</span>
    </div>}

    {!!errorMessage && <span className={styles.error_message}>{errorMessage}</span>}
  </div>
};