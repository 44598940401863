import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {Nullable}                        from "shared/model";


export enum PurchaseType {
  AUTO_RENEWAL = "autoRenewal",
  TARIFF_INIT = "tariffInit",
  TARIFF_FREEZE = "tariffFreeze",
  TARIFF_SUBSCRIBE_ERROR = "tariffSubscribeError",
  CATALOGUE_INIT = "catalogueInit",
  CREDIT_PREPAYMENT = "creditPrepayment",
}

interface AutoRenewalInfo {
  id?: number;
  list?: string[];
  has_gift?: boolean;
  gift_text?: string;
}

const initialState: {
  paymentId: Nullable<string>,
  purchaseType: Nullable<PurchaseType>,
  autoRenewalCancelInfo: Nullable<AutoRenewalInfo>,
  tariffDays: Nullable<string>,
  purchaseIsLoading: boolean,
  freezeWaiting: boolean,
  inAppInfoIsOpen: boolean,
  purchaseFreezeUnfreezeIsLoading: boolean,
} = {
  paymentId:                       null,
  purchaseType:                    null,
  autoRenewalCancelInfo:           null,
  tariffDays:                      null,
  purchaseIsLoading:               false,
  freezeWaiting:                   false,
  inAppInfoIsOpen:                 false,
  purchaseFreezeUnfreezeIsLoading: false,
};

const slice = createSlice({
  name:     "purchases",
  initialState,
  reducers: {
    setPaymentId(state, action: PayloadAction<Nullable<string>>) {
      state.paymentId = action.payload
    },
    setPurchaseType(state, action: PayloadAction<Nullable<PurchaseType>>) {
      state.purchaseType = action.payload
    },
    setAutoRenewalCancelInfo(state, action: PayloadAction<Nullable<AutoRenewalInfo>>) {
      state.autoRenewalCancelInfo = action.payload
    },
    setTariffDays(state, action: PayloadAction<Nullable<string>>) {
      state.tariffDays = action.payload
    },
    setPurchaseIsLoading(state, action: PayloadAction<boolean>) {
      state.purchaseIsLoading = action.payload
    },
    setFreezeWaiting(state, action: PayloadAction<boolean>) {
      state.freezeWaiting = action.payload
    },
    setInAppInfoIsOpen(state, action: PayloadAction<boolean>) {
      state.inAppInfoIsOpen = action.payload
    },
    setPurchaseFreezeUnfreezeIsLoading(state, action: PayloadAction<boolean>) {
      state.purchaseFreezeUnfreezeIsLoading = action.payload
    },
  },
})

export const {
  setPaymentId,
  setPurchaseType,
  setAutoRenewalCancelInfo,
  setTariffDays,
  setPurchaseIsLoading,
  setFreezeWaiting,
  setInAppInfoIsOpen,
  setPurchaseFreezeUnfreezeIsLoading,
} = slice.actions;
export default slice.reducer;

export const selectPaymentId = (state: { purchases: typeof initialState }) => state.purchases.paymentId;
export const selectPurchaseType = (state: { purchases: typeof initialState }) => state.purchases.purchaseType;

export const selectAutoRenewalCancelInfo = (state: {
  purchases: typeof initialState
}) => state.purchases.autoRenewalCancelInfo;

export const selectTariffDays = (state: {
  purchases: typeof initialState
}) => state.purchases.tariffDays;

export const selectPurchaseIsLoading = (state: { purchases: typeof initialState }) => state.purchases.purchaseIsLoading;

export const selectFreezeWaiting = (state: { purchases: typeof initialState }) => state.purchases.freezeWaiting;

export const selectInAppInfoIsOpen = (state: { purchases: typeof initialState }) => state.purchases.inAppInfoIsOpen;

export const selectPurchaseFreezeUnfreezeIsLoading = (state: {
  purchases: typeof initialState
}) => state.purchases.purchaseFreezeUnfreezeIsLoading;

