import React, {forwardRef, useId} from "react";
import BaseInputProps             from "./BaseInputProps";
import cn                         from "classnames";
import styles                     from "./styles.module.scss";


const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(({
  error = false,
  subtext,
  subtextIcon,
  icon,
  rightIcon,
  label,
  labelIcon,
  disabled,
  readOnly = false,
  isPhoneType = false,
  hasSearch = false,
  onClearClick,
  onReadOnlyClick = () => {
  },
  ...props
}, ref) => {
  const id = useId();

  const handleReadOnlyClick = () => {
    if (readOnly) {
      onReadOnlyClick();
    }
  }

  return <div className={styles.wrapper}>
    {label && <div className={styles.label}>
      {labelIcon && <div
        className={labelIcon === "asterix" ? `${styles.label_icon} ${styles.label_asterix}` : styles.label_icon}>{labelIcon}</div>}
      {label}
    </div>}

    <div
      className={cn(styles.input_wrapper, {[`${styles.error}`]: error}, {[`${styles.phone_type}`]: isPhoneType})}
      onClick={handleReadOnlyClick}
    >
      {icon &&
        <div className={typeof icon === "string" ? cn(styles.icon, {[`${styles.error}`]: error}) + " mgr-3" : ""}>
          {icon}
        </div>}

      <input
        autoComplete="off"
        id={id}
        ref={ref}
        readOnly={readOnly}
        className={cn(styles.input, {[`${styles.error}`]: error}, {[`${styles.disabled}`]: disabled})}
        {...{...props}}
      />

      {hasSearch && onClearClick && <span className={`icon ${styles.clear_button}`} onClick={onClearClick}>
        cancel
      </span>}

      {rightIcon &&
        <div className={typeof rightIcon === "string" ? cn(styles.icon, {[`${styles.error}`]: error}) + " mgl-3" : ""}>
          {rightIcon}
        </div>}
    </div>

    {subtext && <div className={cn(styles.subtext, {[`${styles.error}`]: error})}>
      {typeof subtext === "string"
        ? <>
          {subtextIcon && <div className={styles.subtext_icon}>{subtextIcon}</div>}
          {subtext}
        </>
        : subtext}
    </div>}
  </div>
})

export default BaseInput;