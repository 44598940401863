import React, {useCallback, useEffect, useRef}      from "react";
import {useAppDispatch, useAppSelector}             from "store";
import {removeNotification, selectNotificationList} from "store/features";
import {Icon}                                       from "shared/ui";
import styles                                       from "./styles.module.scss";


export default function Toast({bottomPosition}: { bottomPosition?: number }) {
  const dispatch = useAppDispatch();
  const timeoutListener = useRef<NodeJS.Timeout>();
  const notificationMessages = useAppSelector(selectNotificationList);

  const handleRemoveNotification = useCallback(() => dispatch(removeNotification()), [dispatch]);

  useEffect(() => {
    if (notificationMessages) {
      timeoutListener.current = setTimeout(() => {
        handleRemoveNotification();
      }, 2750);
    } else {
      clearTimeout(timeoutListener.current);
    }

    return () => {
      clearTimeout(timeoutListener.current);
    }
  }, [handleRemoveNotification, notificationMessages]);

  return <>
    {notificationMessages &&
      <div onClick={handleRemoveNotification} style={{bottom: `${bottomPosition}px`}} className={styles.wrapper}>
        {notificationMessages?.icon && <Icon size={16} className="mgr-2">{notificationMessages?.icon ?? "info"}</Icon>}
        <div className={styles.text}>{notificationMessages?.text}</div>
      </div>}
  </>
};