import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {Nullable}                        from "shared/model";

interface NotificationItem {
  icon?: string;
  text: string;
}

const initialState: {
  notifications: Nullable<NotificationItem>,
} = {
  notifications: null,
};

const slice = createSlice({
  name:     'generals',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<Nullable<NotificationItem>>) {
      state.notifications = action.payload
    },
    removeNotification(state) {
      state.notifications = null
    },
  },
})

export const {addNotification, removeNotification} = slice.actions;
export default slice.reducer;

export const selectNotificationList = (state: { generals: typeof initialState }) => state.generals.notifications;

