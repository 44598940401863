import {useEffect}               from "react";
import {AppMode}                 from "store/features";
import {setActionForNative}      from "shared/lib";
import {NativeActions, Nullable} from "shared/model";


interface Props {
  appMode: Nullable<AppMode>;
  entryPoint: Nullable<string>;
}

export const useAnalytics = ({appMode, entryPoint}: Props) => {
  useEffect(() => {
    if (appMode === AppMode.SPECIALIST) {
      const entry = entryPoint && entryPoint !== "" ? entryPoint : "none";
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist referral open",
        properties: {
          "entry point": entry,
        },
      });
    }
  }, [appMode, entryPoint]);

  useEffect(() => {
    if (appMode === AppMode.CLIENT) {
      const entry = entryPoint && entryPoint !== "" ? entryPoint : "none";
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "client referral open",
        properties: {
          "entry point": entry,
        },
      });
    }
  }, [appMode, entryPoint]);
}