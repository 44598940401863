import * as React                                               from "react";
import {useEffect, useState}                                    from "react";
import {SubmitHandler, useForm}                                 from "react-hook-form";
import {TextBlock, BaseInput, ButtonDefault, PageHeader, InApp} from "shared/ui";
import {IdentityVerification}                                   from "components/IdentityVerification";
import {CloseRegistrationInApp}                                 from "components/CloseRegistrationInApp";
import {useAppSelector}                                         from "store";
import {selectSsn}                                              from "store/features";
import {useTransferPersonalDataMutation}                        from "services/registration";
import {setActionForNative}                                     from "shared/lib";
import {NativeActions}                                          from "shared/model";
import styles                                                   from "./styles.module.scss";
import {useTranslation}                                         from "react-i18next";
import {InAppLayout, MainLayout}                                from "entities/general";


const i18n_prefix = "page.registration.transfer.";
const TransferProfile = () => {
  const {t} = useTranslation();
  const contextSsn = useAppSelector(selectSsn);

  const [transferPersonalData, {data, isSuccess}] = useTransferPersonalDataMutation();

  const [ssnErrorInApp, setSsnErrorInApp] = useState(false);
  const [ssnBlockedInApp, setSsnBlockedInApp] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: {isValid},
    setValue,
    trigger,
  } = useForm<{ ssn: string }>({
    defaultValues: {
      ssn: "",
    },
  });

  useEffect(() => {
    if (data && data.errors?.profile === "ssn_not_found") {
      return setSsnErrorInApp(true);
    }
    if (data && data.errors?.profile === "ssn_blocked") {
      return setSsnBlockedInApp(true);
    }
  }, [data]);

  const onSubmit: SubmitHandler<{ ssn: string }> = (data) => transferPersonalData({ssn: data.ssn});

  const handleCancel = () => {
    setValue("ssn", "");
    setSsnErrorInApp(false);
    setSsnBlockedInApp(false);
  }

  const handleOpenBiometric = () => {
    if (data && data.content.url) {
      setActionForNative(NativeActions.OPEN_BIOMETRIC, {url: data.content.url});
    }
  }

  useEffect(() => {
    if (contextSsn) {
      if (contextSsn !== "") {
        setValue("ssn", contextSsn);
        trigger("ssn");
      }
    }
  }, [contextSsn, setValue, trigger]);

  return <>
    {!isSuccess || !data?.status ? <MainLayout>
        <PageHeader
          title={t(`${i18n_prefix}header.title`)}
          subtitle={t(`${i18n_prefix}header.description`)}
          rightIcon={<CloseRegistrationInApp />}
        />

        <form id="hook_form" className={styles.content} onSubmit={handleSubmit(onSubmit)}>
          <BaseInput
            label={t(`${i18n_prefix}form.ssn_label`)}
            maxLength={12}
            type="tel"
            labelIcon="asterix"
            placeholder={t(`${i18n_prefix}form.ssn_placeholder`)}
            subtext={t(`${i18n_prefix}form.ssn_subtext`)}
            {...register("ssn", {required: true, minLength: 12})}
          />
        </form>

        <div className={styles.footer}>
          <ButtonDefault
            form="hook_form"
            disabled={!isValid || watch("ssn").length === 0}
            type="submit"
          >
            {t("common.next")}
          </ButtonDefault>
        </div>

        <InApp open={ssnErrorInApp} onClose={handleCancel} hasHeader>
          <div className={styles.inApp_wrapper}>

            <img src="/illustrations/no-conversation-3.svg" alt="img" />

            <TextBlock
              title={t("in_app.ssn_error.title")}
              description={t("in_app.ssn_error.description")}
            />

            <div className={styles.inApp_buttons}>
              <ButtonDefault hasSpace onClick={handleCancel}>
                {t("in_app.ssn_error.button")}
              </ButtonDefault>
            </div>
          </div>
        </InApp>

        <InAppLayout
          isOpen={ssnBlockedInApp}
          img={"/illustrations/no-conversation-3.svg"}
          title={t("in_app.ssn_blocked.title")}
          description={t("in_app.ssn_blocked.description")}
          onClose={handleCancel}
        >
          <ButtonDefault hasSpace onClick={handleCancel}>
            {t("in_app.ssn_blocked.button")}
          </ButtonDefault>
        </InAppLayout>
      </MainLayout>

      : <IdentityVerification onClick={handleOpenBiometric} />}
  </>
}

export default TransferProfile;