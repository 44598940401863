import React, {useEffect, useState}                   from "react";
import {useTranslation}                               from "react-i18next";
import {InAppLayout}                                  from "entities/general";
import {Icon, ButtonColor, ButtonDefault, ButtonType} from "shared/ui";
import {useRemoveCurrentWorkPriceMutation}            from "shared/api";
import {setProfile, setProfileIsCashed}               from "shared/model";
import {useAppDispatch}                               from "store";
import {addNotification}                              from "store/features";
import styles                                         from "./styles.module.scss";


interface Props {
  id: number;
}

const i18n_prefix = "page.specialist.profile.prices.service."
export default function RemoveWorkPrice({id}: Props) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const [removeWorkPrice, {isLoading, isSuccess}] = useRemoveCurrentWorkPriceMutation();

  const [inAppOpen, setInAppOpen] = useState(false);

  const handleToggleInApp = () => setInAppOpen(prev => !prev);

  const handleRemoveWorkPrice = () => removeWorkPrice(id);

  useEffect(() => {
    if (isSuccess) {
      dispatch(addNotification({text: t(`${i18n_prefix}remove.toast`)}));
      dispatch(setProfile(null));
      dispatch(setProfileIsCashed(false));
      handleToggleInApp();
    }
  }, [isSuccess]);

  return <>
    <Icon iconColor="#ADADC2" onClick={handleToggleInApp}>delete</Icon>

    <InAppLayout
      isOpen={inAppOpen}
      img={"/illustrations/delete-4.svg"}
      title={t(`${i18n_prefix}remove.title`)}
      description={t(`${i18n_prefix}remove.description`)}
      onClose={handleToggleInApp}
    >
      <div className={styles.button_wrapper}>
        <ButtonDefault buttonType={ButtonType.WRAPPED} buttonColor={ButtonColor.GRAY} onClick={handleToggleInApp}>
          {t("common.cancel")}
        </ButtonDefault>

        <ButtonDefault
          hasSpace
          onClick={handleRemoveWorkPrice}
          buttonColor={ButtonColor.ERROR}
          disabled={isLoading}
          loading={isLoading}
        >
          {t(`${i18n_prefix}remove.confirm`)}
        </ButtonDefault>
      </div>
    </InAppLayout>
  </>
};