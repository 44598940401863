import React                                          from "react";
import {useTranslation}                               from "react-i18next";
import {useNavigate, useParams}                       from "react-router-dom";
import {SkeletonLayout}                               from "entities/skeleton";
import {PageLayout}                                   from "entities/general";
import {ReviewItem, ReviewRating}                     from "entities/reviews";
import {ButtonColor, ButtonType, ButtonDefault, Icon} from "shared/ui";
import {
  formatRate,
  setActionForNative,
  useNativeHandler,
  useSetHeaders,
  useSkeletonThrottling,
}                                                     from "shared/lib";
import {useFetchClientProfileQuery}                   from "shared/api";
import {NativeActions}                                from "shared/model";
import {useAppSelector}                               from "store";
import {selectLocale}                                 from "store/features";
import styles                                         from "./ClientProfile.module.scss";


const i18n_prefix = "page.client.profile."
export default function ClientProfile() {
  const {t} = useTranslation();
  const {id: paramId} = useParams();
  const id = paramId ? parseInt(paramId) : null;
  const navigate = useNavigate();
  const locale = useAppSelector(selectLocale);

  const {isSuccess} = useSetHeaders();

  const {data, isLoading} = useFetchClientProfileQuery(id as number, {skip: !id && !isSuccess});

  const {extendedLoading} = useSkeletonThrottling({isLoading});

  const stars = [
    data?.reviews_overview?.star5 ?? 0,
    data?.reviews_overview?.star4 ?? 0,
    data?.reviews_overview?.star3 ?? 0,
    data?.reviews_overview?.star2 ?? 0,
    data?.reviews_overview?.star1 ?? 0,
  ];

  const handleBackClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleShowAllReviewsClick = () => navigate(`/client-reviews/${id}`);

  const reviewsCount = locale === "ru"
    ? `(${t(`${i18n_prefix}rating.value.keyWithCount`, {count: data?.reviews_overview.nmb_reviews})})`
    : t(`${i18n_prefix}rating`, {count: data?.reviews_overview.nmb_reviews});

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    handleBackClick();
  });

  return <PageLayout leftIcon={<Icon onClick={handleBackClick}>keyboard_arrow_left</Icon>}>
    <>
      <div className={styles.profile_info}>
        {extendedLoading
          ? <SkeletonLayout
            borderRadius={60}
            width={120}
            height={120}
            theme="dark"
            containerClass={styles.avatar_holder}
          />

          : <div className={styles.avatar_holder}>
            <Icon className={styles.avatar}>user</Icon>
          </div>}

        <div className={styles.name_holder}>
          {extendedLoading
            ? <SkeletonLayout theme="dark" width={180} height={28} borderRadius={6} />
            : <span className={styles.name}>{data?.client.name}</span>}

          {extendedLoading
            ? <div className={styles.skeleton_rating}>
              <SkeletonLayout height={17} borderRadius={6} theme="dark" />
              <SkeletonLayout height={17} borderRadius={6} theme="dark" />
            </div>

            : <div className={styles.info_content_rating}>
              <Icon size={12} className="mgr-1">star_filled</Icon>

              {!data?.reviews_overview.nmb_reviews
                ? <span className={styles.newbie}>{t(`${i18n_prefix}newbie`)}</span>
                : <div className={styles.rating}>
                  <span>{formatRate(data?.client?.rate)}</span>

                  <span>{reviewsCount}</span>
                </div>}
            </div>}
        </div>
      </div>

      <div className={styles.content_wrapper}>
        <div className={styles.content}>
          {extendedLoading
            ? <SkeletonLayout height={22} width={130} borderRadius={6} theme="dark" />
            : <span className={styles.header}>{t(`${i18n_prefix}title`)}</span>}

          {extendedLoading
            ? <SkeletonLayout height={102} />
            : <ReviewRating
              rate={data?.client.rate}
              nmbReviews={data?.reviews_overview.nmb_reviews}
              stars={stars}
            />}

          {extendedLoading
            ? <div className={styles.skeleton_reviews}>
              <SkeletonLayout height={179} />
              <SkeletonLayout height={46} theme="dark" />
            </div>

            : data?.reviews?.slice(0, 2).map((review) => {
              return <ReviewItem
                key={review.id}
                name={review.name ?? ""}
                date={review.created_at ?? ""}
                rating={review.rate ?? 0}
                review_text={review.text ?? ""}
                media={review.media ?? []}
                price={review.sum}
                isApproved={review.is_approved ?? false}
              />
            })}

          {!extendedLoading && !data?.reviews && <span className={styles.empty_reviews}>
            {t(`${i18n_prefix}empty_reviews`)}
          </span>}

          {!extendedLoading && <ButtonDefault
            onClick={handleShowAllReviewsClick}
            buttonType={ButtonType.WRAPPED}
            buttonColor={ButtonColor.GRAY}
          >
            {t(`${i18n_prefix}show_all`)}
          </ButtonDefault>}
        </div>
      </div>
    </>
  </PageLayout>
};