import React, {ChangeEvent, useEffect, useMemo, useState}               from "react";
import {useTranslation}                                                 from "react-i18next";
import {useLocation, useNavigate, useParams}                            from "react-router-dom";
import {TFunction}                                                      from "i18next";
import {SystemResponse, PageLayout}                                     from "entities/general";
import {SkeletonPrices, SkeletonLayout}                                 from "entities/skeleton";
import {RemoveWorkPrice}                                                from "features/specialist/profile";
import {NativeActions, setPricesRoute, SpecialistPricesServiceListItem} from "shared/model";
import {List, Icon, BaseInput}                                          from "shared/ui";
import {
  formatMoney,
  useDebounceValue,
  useNativeHandler,
  useSetHeaders,
  useSkeletonThrottling,
}                                                                       from "shared/lib";
import {useFetchSubWorksPricesListQuery}                                from "shared/api";
import {useAppDispatch}                                                 from "store";


const i18n_prefix = "page.specialist.profile.prices.service."
export default function PricesService() {
  const {id: paramId} = useParams();
  const id = paramId ? parseInt(paramId) : null;
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {isSuccess} = useSetHeaders();

  const {data, isLoading} = useFetchSubWorksPricesListQuery(id as number, {
    skip:                      !isSuccess,
    refetchOnMountOrArgChange: true,
  });

  const {extendedLoading} = useSkeletonThrottling({isLoading});

  const [searchValue, setSearchValue] = useState("");

  const debouncingValue = useDebounceValue(searchValue, 1000) as string;
  const memoizedList = useMemo(() => {
    return data?.prices.filter((service) => service.name.toLowerCase().includes(debouncingValue.toLowerCase()));
  }, [data, debouncingValue]);

  const handleSetSearchValue = (event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  const handleBackClick = () => navigate("/profile/prices");

  useEffect(() => {
    dispatch(setPricesRoute(location.pathname));
  }, [location]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return navigate("/profile/prices");
  });

  return <PageLayout
    headerTitle={data?.service.name}
    headerSubtitle={t(`${i18n_prefix}subtitle`, {count_from: data?.service.nb_active, count_to: data?.service.total})}
    leftIcon={<Icon onClick={handleBackClick}>keyboard_arrow_left</Icon>}
    isLoading={extendedLoading}
    toastPosition={12}
  >
    {extendedLoading
      ? <SkeletonLayout height={42} />

      : <BaseInput
        icon="search"
        autoComplete="off"
        value={searchValue}
        onChange={handleSetSearchValue}
        placeholder={t(`${i18n_prefix}search_placeholder`)}
        hasSearch={searchValue.trim() !== ""}
        onClearClick={() => setSearchValue("")}
        disabled={extendedLoading}
      />}

    {!extendedLoading && !memoizedList?.length && !!searchValue && <SystemResponse
      title={t(`${i18n_prefix}empty_search.title`)}
      description={t(`${i18n_prefix}empty_search.description`)}
      icon="search"
    />}

    {extendedLoading ? <SkeletonPrices count={5} hasDark />
      : memoizedList?.map((service) => <ListItem key={service.work_id} service={service} t={t} />)}
  </PageLayout>
};

function ListItem({service, t}: { service: SpecialistPricesServiceListItem, t: TFunction<"translation", undefined> }) {
  const navigate = useNavigate();

  const handleServiceClick = () => navigate(`/profile/prices-work/${service.work_id}`);

  const handleOnListClick = () => {
    if (service.id) return;
    return handleServiceClick();
  }

  return <List
    title={service.name}
    controlIcon={!service.id ? "add" : <RemoveWorkPrice id={service.work_id} />}
    subtitle={!service.id
      ? t(`${i18n_prefix}empty_price`)
      : t(`${i18n_prefix}price`, {price_to: formatMoney(service.sum_to, true), unit: service.unit})}
    link={!service.id ? undefined : {name: t(`${i18n_prefix}link`)}}
    onClick={handleOnListClick}
    onLinkClick={handleServiceClick}
  />
}