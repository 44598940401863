import {NativeActions} from "shared/model";

function postMessageToWebKit(action: NativeActions, data?: any) {
  const message = {action, ...(data && {data})};
  window.webkit?.messageHandlers?.callbackHandler?.postMessage(JSON.stringify(message));
}

function callAndroidMethod(action: NativeActions, data?: any) {
  if (data) {
    window.android[action](JSON.stringify(data));
  } else {
    window.android[action]();
  }
}

export function setActionForNative<T>(action: NativeActions, data?: T) {
  if (window?.webkit) {
    postMessageToWebKit(action, data);
  } else if (window?.android) {
    callAndroidMethod(action, data);
  }
}