import * as React                             from "react";
import {useEffect, useState}                  from "react";
import {useNavigate, useSearchParams}         from "react-router-dom";
import {List}                                 from "shared/ui/List";
import {PageHeader}                           from "shared/ui/PageHeader";
import {NativeActions}                        from "shared/model";
import {setActionForNative, useNativeHandler} from "shared/lib";
import {
  RegistrationMethodType,
  RegistrationType,
  useLazyGetRegistrationMethodsQuery,
}                                             from "services/registration";
import {useAppDispatch, useAppSelector}       from "store";
import {
  selectIsNotMeFreedom,
  selectMethodType,
  selectRegistrationInfo,
  setBiometricUrl,
  setErrorMessage,
  setMethodType,
  setRegistrationInfo,
  setRegistrationType,
  setStep,
}                                             from "store/features/registration";
import {
  selectAppPlatform,
  selectAppVersion,
  selectSdkVersion,
  selectSession,
  setAdId,
  setApiSession,
  setAppCity,
  setAppPlatform,
  setAppVersion,
  setLocale,
  setSdkVersion,
}                                             from "store/features/queries";
import {useTranslation}                       from "react-i18next";
import i18n                                   from "i18next";
import {WaitingScreen}                        from "components/WaitingScreen";
import styles                                 from "./styles.module.scss";
import {FF}                                   from "pages/Registration";
import classNames                             from "classnames";
import {MainLayout}                           from "entities/general";
import {SkeletonLayout}                       from "entities/skeleton";


function defineListIcon(type: RegistrationType | string) {
  switch (type) {
    case RegistrationType.DOCUMENT:
      return "passport";
    case RegistrationType.EGOV:
      return <img width={24} height={24} src={"/images/egov.svg"} alt="icon" />;
    case RegistrationType.FREEDOM:
      return <img width={24} height={24} src={"/images/freedom.svg"} alt="icon" />;
    case RegistrationType.NON_RESIDENT:
      return "user_pin";
    case RegistrationType.TRANSFER:
      return <img width={24} height={24} src={"/images/naimi.svg"} alt="icon" />;
  }
}

const Registration = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const entrypointValue = searchParams.get("entrypoint");
  const localeValue = searchParams.get("locale");
  const adidValue = searchParams.get("adid");
  const versionValue = searchParams.get("version");
  const platformValue = searchParams.get("platform");
  const sdkValue = searchParams.get("sdk");
  const sessionValue = searchParams.get("session");
  const cityValue = searchParams.get("city");
  const methodType = useAppSelector(selectMethodType);
  const isNotMeFreedom = useAppSelector(selectIsNotMeFreedom);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const currentSession = useAppSelector(selectSession);
  const currentAppVersion = useAppSelector(selectAppVersion);
  const currentAppPlatform = useAppSelector(selectAppPlatform);
  const currentSdkVersion = useAppSelector(selectSdkVersion);
  const freedomRegistrationInfo = useAppSelector(selectRegistrationInfo)

  const [getRegistrationMethods, {
    data: registrationMethods,
    isFetching,
    isSuccess,
  }] = useLazyGetRegistrationMethodsQuery();

  const [isPassed, setIsPassed] = useState(false);

  const handlePageClick = (value: RegistrationType, step: number, url?: string, substring?: string) => {
    let analyticsValue = "";
    switch (value) {
      case RegistrationType.DOCUMENT:
        analyticsValue = "document";
        dispatch(setRegistrationType(RegistrationType.DOCUMENT));
        break;
      case RegistrationType.EGOV:
        analyticsValue = "egov";
        dispatch(setRegistrationType(RegistrationType.EGOV));
        break;
      case RegistrationType.FREEDOM:
        analyticsValue = "freedom";
        dispatch(setRegistrationType(RegistrationType.FREEDOM));
        break;
      case RegistrationType.NON_RESIDENT:
        analyticsValue = "non-resident";
        dispatch(setRegistrationType(RegistrationType.NON_RESIDENT));
        break;
      case RegistrationType.TRANSFER:
        dispatch(setRegistrationType(RegistrationType.TRANSFER));
        analyticsValue = "transfer";
    }
    if (methodType !== RegistrationMethodType.VERIFICATION) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist registration method opened",
        properties: {
          "registration method": analyticsValue,
          "registration step":   `${step}`,
        },
      });
    }
    if (step !== 1 && registrationMethods?.content?.url) {
      dispatch(setStep(step));
      dispatch(setBiometricUrl(registrationMethods?.content?.url));
    }
    if (value === RegistrationType.FREEDOM) {
      if (url && substring) {
        return setActionForNative(NativeActions.OPEN_FF_CARD, {url, substring});
      }
    }
    if (value !== RegistrationType.FREEDOM) {
      return navigate(`/${value}`);
    }
  };

  const handleOnCloseClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleOpenGidClick = () => setActionForNative(NativeActions.OPEN_GID);

  const conditionalCheckHeaders = typeof currentSession === "undefined" || typeof currentAppVersion === "undefined"
    || typeof currentAppPlatform === "undefined" || typeof currentSdkVersion === "undefined";

  useEffect(() => {
    if (entrypointValue && methodType !== null && methodType !== RegistrationMethodType.VERIFICATION) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist registration opened",
        properties: {
          "entry point": entrypointValue,
        },
      });
    }
  }, [entrypointValue, methodType]);

  useEffect(() => {
    if (conditionalCheckHeaders) return;
    if (currentSdkVersion) {
      getRegistrationMethods({system_version: currentSdkVersion});
    }
  }, [conditionalCheckHeaders, currentSdkVersion, getRegistrationMethods]);

  useEffect(() => {
    if (localeValue) {
      changeLanguage(localeValue);
      dispatch(setLocale(localeValue));
    }
  }, [dispatch, localeValue]);

  useEffect(() => {
    if (registrationMethods?.status === false && registrationMethods.message) {
      dispatch(setErrorMessage(registrationMethods?.message))
    }
    if (registrationMethods?.content?.profile) {
      dispatch(setRegistrationInfo(registrationMethods?.content?.profile));
    }
  }, [dispatch, registrationMethods]);

  useEffect(() => {
    if (isSuccess && registrationMethods?.status && registrationMethods?.content.type) {
      dispatch(setMethodType(registrationMethods?.content?.type as RegistrationMethodType));
    }
  }, [dispatch, isSuccess, registrationMethods]);

  useEffect(() => {
    return () => setIsPassed(false);
  }, []);

  useNativeHandler<{ is_passed: boolean }>("registration", NativeActions.OPEN_FF_CARD, (data) => {
    setIsPassed(data.is_passed);
  });

  useEffect(() => {
    if (isNotMeFreedom) return;
    if (registrationMethods?.content?.profile?.avatar) {
      dispatch(setRegistrationInfo(registrationMethods?.content?.profile));
    }
  }, [dispatch, navigate, registrationMethods, isNotMeFreedom]);

  useEffect(() => {
    if (adidValue) {
      dispatch(setAdId(adidValue));
    }
    if (versionValue) {
      dispatch(setAppVersion(versionValue));
    }
    if (platformValue) {
      dispatch(setAppPlatform(platformValue));
    }
    if (sdkValue) {
      dispatch(setSdkVersion(sdkValue));
    }
    if (sessionValue) {
      dispatch(setApiSession(sessionValue));
    }
    if (cityValue) {
      dispatch(setAppCity(+cityValue));
    }
  }, [dispatch, adidValue, versionValue, platformValue, sdkValue, sessionValue, cityValue]);

  const rightIconClassNames = classNames(
    styles.close_button,
    {[`${styles.close_button_hidden}`]: !isNotMeFreedom && freedomRegistrationInfo?.avatar},
  );

  return <>
    {!isNotMeFreedom && freedomRegistrationInfo?.avatar && <FF />}
    {isPassed
      ? <WaitingScreen />
      : <MainLayout>
        <PageHeader
          isLoading={isFetching || conditionalCheckHeaders}
          title={methodType === RegistrationMethodType.VERIFICATION ? t("page.verification.header") : t("page.registration.header")}
          rightIcon={<div onClick={handleOnCloseClick} className={rightIconClassNames}>close</div>}
        />

        <div className={styles.list_content}>
          {!isFetching && !conditionalCheckHeaders
            ? registrationMethods?.content?.methods.map((method, index) =>
              <List
                key={index}
                labels={method.labels}
                title={method.title}
                subtitle={method.subtitle}
                disabled={!method.is_active}
                icon={defineListIcon(method.type)}
                controlIcon="keyboard_arrow_right"
                onClick={() => handlePageClick(method.type, method.step, method.webview_url, method.uri_closing_webview)}
              />)

            : <>
              <SkeletonLayout height={64} borderRadius={12} />
              <SkeletonLayout height={64} borderRadius={12} />
              <SkeletonLayout height={64} borderRadius={12} />
              <SkeletonLayout height={64} borderRadius={12} />
            </>
          }
        </div>

        <div className={styles.footer}>
          {!isFetching && !conditionalCheckHeaders ? <div>
              {t("page.registration.footer")} <span className={styles.link}
                                                    onClick={handleOpenGidClick}>naimi.гид</span>
            </div>
            : <>
              <SkeletonLayout height={19} borderRadius={12} />
              <SkeletonLayout height={19} width={"30%"} borderRadius={12} />
            </>}
        </div>
      </MainLayout>}
  </>
}

export default Registration;