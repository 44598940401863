import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {Nullable}                        from "shared/model";


export enum AppMode {
  CLIENT = "client",
  SPECIALIST = "specialist",
}

const initialState: {
  session?: Nullable<string>;
  adId?: Nullable<string>;
  appCity?: Nullable<number>;
  appVersion?: Nullable<string>;
  appPlatform?: Nullable<string>;
  sdkVersion?: Nullable<string>;
  entryPoint?: Nullable<string>;
  locale: string;
  appMode: Nullable<AppMode>;
  ticketId?: Nullable<number>;
  isDemo: boolean;
  workId: Nullable<number>;
} = {
  session:     undefined,
  adId:        undefined,
  appCity:     undefined,
  appVersion:  undefined,
  appPlatform: undefined,
  locale:      "ru",
  sdkVersion:  undefined,
  entryPoint:  null,
  appMode:     null,
  ticketId:    undefined,
  isDemo:      false,
  workId:      null,
};

const slice = createSlice({
  name:     "queries",
  initialState,
  reducers: {
    setApiSession(state, action: PayloadAction<Nullable<string>>) {
      state.session = action.payload;
    },
    setAdId(state, action: PayloadAction<Nullable<string>>) {
      state.adId = action.payload;
    },
    setAppCity(state, action: PayloadAction<Nullable<number>>) {
      state.appCity = action.payload;
    },
    setAppVersion(state, action: PayloadAction<Nullable<string>>) {
      state.appVersion = action.payload;
    },
    setAppPlatform(state, action: PayloadAction<Nullable<string>>) {
      state.appPlatform = action.payload;
    },
    setLocale(state, action: PayloadAction<string>) {
      state.locale = action.payload;
    },
    setSdkVersion(state, action: PayloadAction<Nullable<string>>) {
      state.sdkVersion = action.payload;
    },
    setEntryPoint(state, action: PayloadAction<Nullable<string>>) {
      state.entryPoint = action.payload
    },
    setAppMode(state, action: PayloadAction<Nullable<AppMode>>) {
      state.appMode = action.payload
    },
    setTicketId(state, action: PayloadAction<Nullable<number>>) {
      state.ticketId = action.payload
    },
    setIsDemo(state, action: PayloadAction<boolean>) {
      state.isDemo = action.payload
    },
    setWorkId(state, action: PayloadAction<Nullable<number>>) {
      state.workId = action.payload
    },
  },
})

export const {
  setLocale,
  setAppCity,
  setAdId,
  setApiSession,
  setAppPlatform,
  setAppVersion,
  setEntryPoint,
  setSdkVersion,
  setAppMode,
  setTicketId,
  setIsDemo,
  setWorkId,
} = slice.actions;
export default slice.reducer;

export const selectSession = (state: { queries: typeof initialState }) => state.queries.session;
export const selectAdId = (state: { queries: typeof initialState }) => state.queries.adId;
export const selectAppCity = (state: { queries: typeof initialState }) => state.queries.appCity;
export const selectAppVersion = (state: { queries: typeof initialState }) => state.queries.appVersion;
export const selectAppPlatform = (state: { queries: typeof initialState }) => state.queries.appPlatform;
export const selectLocale = (state: { queries: typeof initialState }) => state.queries.locale;
export const selectSdkVersion = (state: { queries: typeof initialState }) => state.queries.sdkVersion;
export const selectEntryPoint = (state: { queries: typeof initialState }) => state.queries.entryPoint;
export const selectAppMode = (state: { queries: typeof initialState }) => state.queries.appMode;
export const selectTicketId = (state: { queries: typeof initialState }) => state.queries.ticketId;
export const selectIsDemo = (state: { queries: typeof initialState }) => state.queries.isDemo;
export const selectWorkId = (state: { queries: typeof initialState }) => state.queries.workId;
