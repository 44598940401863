import * as React           from "react";
import styles               from "./styles.module.scss";
import {ButtonDefault}      from "shared/ui";
import {setActionForNative} from "shared/lib";
import {NativeActions}      from "shared/model";
import {useTranslation}     from "react-i18next";

const TransferComplete = () => {
  const {t} = useTranslation();
  const handleOpenTickets = () => setActionForNative(NativeActions.OPEN_TICKETS);

  return <div className={styles.inProgressing_wrapper}>
    <div className={styles.inProgressing_icon}>check</div>

    <div className={styles.inProgressing_text}>
      <div className={styles.inProgressing_text_title}>
        {t("page.registration.transfer.complete.title")}
      </div>

      <div className={styles.inProgressing_text_subtitle}>
        {t("page.registration.transfer.complete.subtitle")}
      </div>
    </div>

    <ButtonDefault buttonRound onClick={handleOpenTickets}>
      {t("page.registration.transfer.complete.button")}
    </ButtonDefault>
  </div>
}

export default TransferComplete;