import React, {useEffect} from "react";
import Sheet              from "react-modal-sheet";
import styles             from "./InApp.module.scss";


interface Props {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  contentDrag?: boolean;
  hasHeader?: boolean;
  headerText?: string;
  backdropClose?: boolean;
}

const NO_TOUCH_ACTION_STYLE = "no_touch_action";
export default function InApp({
  open,
  backdropClose = true,
  onClose,
  children,
  headerText,
  hasHeader = true,
  contentDrag,
}: Props) {

  const handleOnClose = () => {
    document.body.classList.remove(NO_TOUCH_ACTION_STYLE);
    onClose();
  }

  const handleBackdropClick = () => {
    if (backdropClose) {
      handleOnClose();
    }
  }

  useEffect(() => {
    if (open) {
      document.body.classList.add(NO_TOUCH_ACTION_STYLE);
    } else {
      document.body.classList.remove(NO_TOUCH_ACTION_STYLE);
    }
  }, [open]);

  return <Sheet
    detent="content-height"
    prefersReducedMotion={false}
    disableScrollLocking
    isOpen={open}
    onClose={handleOnClose}
  >
    <Sheet.Container>
      <Sheet.Header className={styles.sheet_header} />
      {headerText && <div className={styles.inApp_header_text}>{headerText}</div>}
      {hasHeader && <div>
        <div className={styles.inApp_header_close} onClick={handleOnClose}>close</div>
      </div>}
      <Sheet.Content className={styles.inApp_content} disableDrag={contentDrag}>{children}</Sheet.Content>
    </Sheet.Container>
    <Sheet.Backdrop onTap={handleBackdropClick} />
  </Sheet>
};
