import React, {InputHTMLAttributes, useRef} from "react";
import styles                               from "./styles.module.scss";


interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  disabled?: boolean;
  labelIcon?: React.ReactNode;
}

export default function RadioInput({label, disabled, labelIcon, ...props}: Props) {
  const ref = useRef<HTMLInputElement>(null);

  const handleParentClick = () => {
    if (ref && ref.current) {
      ref.current.click();
    }
  }

  return <div onClick={handleParentClick} className={styles.radio_wrapper}>
    <div className={disabled ? styles.radio_label_disabled : styles.radio_label}>{labelIcon && labelIcon}{label}</div>
    <div className={styles.radio_input_wrapper}>
      <input disabled={disabled} ref={ref} type="radio" {...{...props}} />
      <span className={styles.radio_custom}></span>
    </div>
  </div>
}