import React, {useEffect, useRef, useState} from "react";
import {Icon}                               from "shared/ui";
import cn                                   from "classnames";
import styles                               from "./styles.module.scss";


interface Props {
  title?: string;
  description?: string;
}

export default function Accordion({title, description}: Props) {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [expanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState("0px");

  const handleToggle = () => setExpanded(prev => !prev);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    }
  }, []);

  return <div className={cn(styles.accordion, {[styles.open]: expanded})} onClick={handleToggle}>
    <div className={styles.header}>
      <Icon className={cn(styles.toggle_button, {[styles.toggle_button_open]: expanded})}>add</Icon>

      {title ?? ""}
    </div>
    <div
      ref={contentRef}
      className={styles.content}
      style={{maxHeight: expanded ? contentHeight : "0px"}}
    >
      {description ?? ""}
    </div>
  </div>
}