import React, {useLayoutEffect, useRef, useState}             from "react";
import {useTranslation}                                       from "react-i18next";
import {Label, RatingStars, GalleryMediaItem, GalleryWrapper} from "shared/ui";
import {formatNumberWithSpaces}                               from "shared/lib";
import {ReviewMedia}                                          from "services/reviews/types";
import styles                                                 from "./styles.module.scss";


interface Props {
  name?: string;
  date?: string;
  rating?: number;
  review_text?: string;
  media?: ReviewMedia[];
  isApproved: boolean;
  price: null | number;
  isViewMode?: boolean;
}

const TEXT_LIMIT = 57;
const IMG_SIZE = 60;
export default function ReviewItem({
  review_text,
  name,
  price,
  rating = 0,
  date,
  media,
  isApproved,
  isViewMode = false,
}: Props) {
  const {t} = useTranslation();
  const textBlockRef = useRef<HTMLDivElement>(null);

  const [toggleText, setToggleText] = useState(false);
  const [textBlockHeight, setTextBlockHeight] = useState<number>();

  useLayoutEffect(() => {
    if (textBlockRef && textBlockRef.current) {
      setTextBlockHeight(textBlockRef.current.getBoundingClientRect().height)
    }
  }, []);

  const handleToggleText = () => setToggleText(prev => !prev);

  return <div className={styles.wrapper}>
    {isApproved ? <>
        <div className={styles.header}>
          <div className={styles.header_left}>
            {(price && price !== 0) ?
              <div className={styles.header_left_name}>{`${formatNumberWithSpaces(price)} ₸`}</div> : null}
            <div className={styles.header_left_date}>{date}</div>
          </div>

          <div>
            <RatingStars rating={rating} />
          </div>
        </div>

        {review_text && <div className={""}>
          <div
            ref={textBlockRef}
            className={!toggleText && textBlockHeight && textBlockHeight > TEXT_LIMIT ? `${styles.review_text} ${styles.not_show}` : styles.review_text}>
            {review_text}
          </div>

          {review_text && textBlockHeight && textBlockHeight > TEXT_LIMIT &&
            <div onClick={handleToggleText} className={styles.has_more}>
              {!toggleText ? t("common.toggle.more") : t("common.toggle.collapse")}
            </div>}
        </div>}

        {!media?.length ? null : <GalleryWrapper>
          <ul className={styles.media_wrapper}>
            {media.map((photo, index) => {
              if (index > 2) return <li key={index} style={{display: "none"}}>
                <GalleryMediaItem
                  digest={photo.digest as string}
                  type={photo.type as any}
                  size={IMG_SIZE}
                  hasOpen={!isViewMode}
                />
              </li>;
              if (media.length > 3 && index === 2) {
                return <li key={index}>
                  <GalleryMediaItem
                    digest={photo.digest as string}
                    type={photo.type as any}
                    size={IMG_SIZE}
                    hasOpen={!isViewMode}
                    mediaLength={media.length}
                  />
                </li>
              }
              return <li key={index}>
                <GalleryMediaItem
                  digest={photo.digest as string}
                  type={photo.type as any}
                  size={IMG_SIZE}
                  hasOpen={!isViewMode}
                />
              </li>
            })}
          </ul>
        </GalleryWrapper>}

        {name ? <div className={styles.name_block}>{name}</div> : ""}
      </>

      : <>
        <Label labels={[{title: `${t("page.reviews.not_approved_label")}`, style: "gray"}]} clasNames={styles.label} />

        <div className={styles.not_approved_text}>
          {t("page.reviews.not_approved")}
        </div>
      </>
    }
  </div>
}