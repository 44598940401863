export function generateArray(to: number) {
  const numbers = Array.from({length: to}, (_, i) => (i + 1).toString());
  return ["", ...numbers, ""];
}

export function generateYears(from: number, to: number) {
  const numbers = Array.from({length: to - from + 1}, (_, i) => (i + from).toString());
  return ["", ...numbers, ""];
}

export function generateMaxYear() {
  const currentYear = new Date().getFullYear();
  return currentYear - 18;
}

export function getDaysInMonth(year: number, month: number) {
  const date = new Date(year, month + 1, 0);
  return date.getDate();
}