import React             from "react";
import Lottie            from "lottie-react";
import {useTranslation}  from "react-i18next";
import styles            from "./styles.module.scss";
import {loaderAnimation} from "../../../assets/lotties";


export default function ImageLoading() {
  const {t} = useTranslation();

  return <div className={styles.wrapper}>
    <Lottie
      autoPlay
      animationData={loaderAnimation}
      loop
      style={{width: 40, height: 40}}
      className={styles.loader_spinner}
    />

    <div className={styles.title}>{t("elements.image_loading.title")}</div>

    <div className={styles.description}>{t("elements.image_loading.description")}</div>
  </div>
}