export const profile = {
  more:              {
    opt_1:      "Как видит клиент мою анкету",
    opt_2:      "Поделиться",
    opt_3:      "Удалить анкету",
    open_share: "Привет! Хочу поделиться анкетой отличного специалиста, которого ты можешь нанять для выполнения работы. Вот ссылка на его анкету: {{link}}. Надеюсь, это будет полезно!",
  },
  about:             {
    title:         "О себе",
    on_moderation: "На модерации",
    empty:         {
      client:     "Здесь будет информация, которую специалист укажет “О себе”",
      specialist: "Здесь будет информация, которую вы укажете о себе",
    },
    button:        {
      add:    "Добавить информацию",
      update: "Изменить",
      save:   "Сохранить",
    },
    inApp:         {
      title:       "Вы уверены?",
      description: "Если вы закроете страницу, заполненные вами данные не сохранятся",
      button:      {
        cancel: "Отмена",
        close:  "Закрыть",
      },
    },
    toast:         "Изменения сохранены",
    placeholder:   "Введите текст тут...",
    hint:          "Коротко расскажите о себе, чтобы клиент имел представление о вашей деятельности",
    error:         "Здесь может быть не больше 300 символов, сделайте текст короче",
  },
  reviews:           {
    title:  "Отзывы",
    button: "Смотреть все",
    empty:  {
      client:     {
        none:      "В анкете еще нет отзывов от клиентов. Попробуйте связаться со специалистом, возможно, вам предложат большую скидку взамен на ваш отзыв или просто согласятся на ваш бюджет",
        with_work: "У специалиста еще нет отзывов по этой услуге , но вы можете ознакомиться с отзывами по другим работам, которые выполнил этот специалист",
      },
      specialist: {
        none: "У вас пока нет отзывов. Получите первые отзывы, предложив выгодную цену в отклике или попросив клиентов нанять вас через naimi.kz и оставить оценку",
      },
    },
  },
  prices:            {
    list:    {
      header: {
        specialist:         "Мои услуги",
        client:             "Услуги специалиста",
        specialist_with_id: "Услуги специалиста",
      },
      empty:  {
        client:     {
          with_work: "У специалиста еще не указаны цены на эту услугу",
          none:      "Тут будут услуги, которые предоставляет данный специалист",
        },
        specialist: {
          none:    "Тут будут услуги, которые вы добавите в ленте заявок",
          with_id: "Тут будут услуги, которые предоставляет данный специалист",
        },
      },
      title:  "Услуги специалиста",
      button: {
        show_all: "Смотреть все",
        add: "Добавить услуги",
      },
    },
    update:  {
      title:            "Цены на услуги",
      subtitle:         "Категории из вашей ленты заявок",
      service_subtitle: "Добавлено {{count_from}} из {{count_to}}",
      empty_prices:     {
        title:    "Выберите категории",
        subtitle: "Чтобы указать цены на ваши услуги, выберите интересующие вас категории услуг",
        button:   "Выбрать",
      },
    },
    service: {
      subtitle:           "Выбрано {{count_from}} из {{count_to}}",
      search_placeholder: "Поиск",
      empty_price:        "Цена не указана",
      price:              "До {{price_to}} / {{unit}}",
      link:               "Изменить",
      remove:             {
        title:       "Удалить услугу?",
        description: "Услуга будет удалена, а также информация, которую вы указали будет потеряна",
        confirm:     "Удалить",
        toast:       "Услуга удалена из списка",
      },
      empty_search:       {
        title:       "Ничего не найдено",
        description: "Услуг по данному запросу не найдено",
      },
    },
    work:    {
      title:         "Добавить услугу",
      recommend:     "Рекомендованная цена:",
      details_price: "Детальный прайс на услугу",
      to: "до",
      service_count: {
        value: {
          keyWithCount_zero: "",
          keyWithCount_one:  "{{count}} услуга",
          keyWithCount_few:  "{{count}} услуги",
          keyWithCount_many: "{{count}} услуг",
        },
      },
      cancel_edit:   {
        title:       "Вы уверены?",
        description: "Если вы закроете страницу, заполненные вами данные не сохранятся",
      },
      toast:         "Услуга {{service}} добавлена",
    },
  },
  remove_profile:    {
    title:          "Удаление анкеты",
    footer_text:    "Восстановление анкеты будет стоить 3 000 ₸. Создать новую анкету вы не сможете, так как на один ИИН можно зарегистрировать только одну анкету",
    labels:         {
      ssn: "ИИН",
    },
    placeholders:   {
      ssn: "Введите ИИН",
    },
    buttons:        {
      remove: "Удалить анкету",
    },
    confirm:        {
      list:        [
        "Если вы захотите восстановить анкету, это будет платно",
        "Вы не сможете откликаться на заявки",
        "Вы не сможете создать другую анкету, так как на один ИИН создается только одна анкета",
      ],
      cancel:      "Нет, я передумал",
      remove:      "Удалить анкету",
      test_inaApp: {
        cancel: "Удалить анкету",
      },
    },
    validate_limit: {
      title:       "У вас закончились попытки",
      description: "Вы превысили лимит повторных проверок ИИН. Попробуйте еще раз через 24 часа",
      confirm:     "Понятно",
    },
  },
  media:             {
    title:  "Фото и видео",
    empty:  {
      specialist: "Тут будут фото ваших работ после того, как клиенты оставят отзывы с фото, либо вы сами добавите фото своих работ",
      client:     "Тут будут фото работ специалиста в разных категориях предоставляемых им услуг",
    },
    button: {
      add:      "Добавить фото",
      update:   "Изменить",
      show_all: "Смотреть все",
    },
    list:   {
      header: "Фото и видео",
      button: "Добавить файл",
      remove: {
        title:          {
          one:  "Удалить файл?",
          many: "Удалить файлы",
        },
        description:    {
          value: {
            keyWithCount_zero: "",
            keyWithCount_one:  "Медиафайл удалится безвозвратно",
            keyWithCount_few:  "{{count}} медиафайла удалится безвозвратно",
            keyWithCount_many: "{{count}} медиафайлов удалится безвозвратно",
          },
        },
        select_media:   "Выбор файлов",
        select_all:     "Выбрать все",
        cancel:         "Отмена",
        success_remove: "Медиафайлы удалены",
        delete:         {
          one:  "Удалить",
          many: "Удалить ({{count}})",
        },
      },
      empty:  {
        title:       "Нет медиафайлов",
        description: "Вы не загрузили ни одного медиафайла, попробуйте пополнить галерею ваших работ, тогда клиенты смогут понимать вашу компетенцию",
      },
    },
  },
  change_avatar:     {
    title:        "Загрузить фото профиля",
    subtitle:     "Пример фото",
    button:       "Загрузить фото",
    helper_texts: [
      "Полностью видно ваше лицо",
      "Без солнцезащитных очков и головного убора",
      "На фото только вы без посторонних людей",
      "Фото, не соответствующее требованиям будет отклонено",
    ],
  },
  avatar:            {
    on_moderation: "Фото на модерации",
    newbie:        "Новичок",
    rating:        {
      value: {
        keyWithCount_zero: "",
        keyWithCount_one:  "{{count}} оценка",
        keyWithCount_few:  "{{count}} оценки",
        keyWithCount_many: "{{count}} оценок",
      },
    },
    upload_fail:   {
      title:       "Ошибка загрузки",
      description: "Не удалось загрузить фото. Проверьте соединение с интернетом, и попробуйте снова",
      buttons:     {
        cancel:    "Отмена",
        try_again: "Попробовать снова",
      },
    },
  },
  favourite:         {
    add:    "Специалист добавлен в “Избранное”",
    delete: "Вы убрали специалиста из “Избранное”",
  },
  button:            {
    message: "Написать",
    call:    "Позвонить",
    unblock: "Разблокировать",
  },
  call:              "Позвонить",
  input_placeholder: "Сообщение...",
  media_upload:      {
    title:         "Добавить файл",
    subtitle:      "{{count}} из 5",
    text:          {
      label:       "Краткое описание",
      placeholder: "Краткое описание добавленных медиафайлов и выполненной работы",
    },
    upload_button: "Добавить {{count}} из 5",
    remove_media:  {
      title:       "Удалить файл?",
      description: "Ваш медиафайл удалится безвозвратно",
    },
    close_page:    {
      title:       "Вы уверены?",
      description: "Если вы закроете страницу, загруженные файлы и заполненные данные не сохранятся",
    },
    toast_message: "Медиафайлы добавлены",
  },
}