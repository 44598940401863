import React           from "react";
import ButtonBase      from "../ButtonBase";
import BaseButtonProps from "../BaseButtonProps";
import styles          from "./styles.module.scss";


interface Props extends BaseButtonProps {
  icon?: string;
  rightIcon?: string;
}

export default function ButtonDefault({icon, rightIcon, children, ...props}: Props) {
  return <ButtonBase {...{...props}}>
    <div className={props.loading ? `${styles.children_wrapper_hidden}` : `${styles.children_wrapper}`}>
      {icon && <span>{icon}</span>}
      {children}
      {rightIcon && <span>{rightIcon}</span>}
    </div>
  </ButtonBase>
}