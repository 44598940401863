import React, {useEffect, useState}                              from "react";
import {useTranslation}                                          from "react-i18next";
import {SpecialistProfileUi}                                     from "services/api";
import Lottie                                                    from "lottie-react";
import {loaderAnimation}                                         from "assets/lotties";
import {useNativeHandler, setActionForNative}                    from "shared/lib";
import {ChatInput, Icon, ButtonColor, ButtonDefault, ButtonType} from "shared/ui";
import {SpecialistAlertItem, NativeActions, Nullable}            from "shared/model";
import {useClientCartInitMutation}                               from "shared/api";
import {InAppLayout}                                             from "entities/general";
import {SkeletonLayout}                                          from "entities/skeleton";
import {setPaymentId}                                            from "store/features";
import {useAppDispatch}                                          from "store";
import styles                                                    from "./ProfileFooter.module.scss";


interface Props {
  isLoading: boolean;
  isDemo: boolean;
  ui?: SpecialistProfileUi;
  id?: number;
  ticketId?: Nullable<number>;
  workId: Nullable<number>;
  alert?: SpecialistAlertItem;
  croppedPhone?: Nullable<string>;
}

const i18n_prefix = "page.specialist.profile."
export default function ProfileFooter({
  ui,
  workId,
  ticketId,
  id,
  isDemo,
  alert,
  isLoading: externalLoading,
  croppedPhone,
}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openGetContact, setOpenGetContact] = useState(false);

  const [cartInit, {data: cartInitResponse}] = useClientCartInitMutation();

  const handleToggleGetContact = () => setOpenGetContact(prev => !prev);

  const handleGetContact = () => {
    if (isDemo) return;
    if (!alert) return;
    return handleToggleGetContact();
  }

  const handleButtonClick = (link: string, linkData: Record<string, string | number | boolean>) => {
    if (link === "naimi://payments") {
      cartInit({...linkData});
    }
    if (link === "naimi://order/create") {
      setActionForNative(NativeActions.OPEN_VACANCY_CREATION, {work_id: linkData.work_id});
    }
    setOpenGetContact(false);
  }

  const handleCallToSpecialist = () => {
    if (!id) return;
    if (!isDemo && alert) {
      return handleGetContact();
    } else {
      setActionForNative(NativeActions.CALL_TO_SPECIALIST, {
        specialist_id: id,
        ticket_id:     ticketId ?? undefined,
        work_id:       workId ?? undefined,
        cropped_phone: croppedPhone,
      });
    }
  }

  const handleWriteToSpecialist = () => {
    if (!id) return;
    if (!isDemo && alert) {
      return handleGetContact();
    } else {
      setActionForNative(NativeActions.WRITE_TO_SPECIALIST, {
        specialist_id: id,
        ticket_id:     ticketId ?? undefined,
        work_id:       workId ?? undefined,
        message,
      });
    }
  }

  useNativeHandler("profile", NativeActions.WRITE_LOADER_OPEN, () => {
    setIsLoading(true);
    setMessage("");
  });

  useNativeHandler("profile", NativeActions.WRITE_LOADER_CLOSE, () => {
    setIsLoading(false);
  });

  useNativeHandler<{ is_payed: boolean }>("profile", NativeActions.OPEN_PAYMENTS, (data) => {
    if (data.is_payed && cartInitResponse?.payment_id) {
      dispatch(setPaymentId(cartInitResponse.payment_id));
    }
  });

  useEffect(() => {
    if (cartInitResponse && cartInitResponse.payment_id) {
      setActionForNative(NativeActions.OPEN_PAYMENTS, {payment_id: cartInitResponse.payment_id});
    }
  }, [cartInitResponse]);

  return <>
    {externalLoading ? <SkeletonLayout height={46} /> : <>
      {!ui?.contact && <ButtonDefault onClick={handleCallToSpecialist}>{t(`${i18n_prefix}call`)}</ButtonDefault>}

      {ui?.contact && <div className={styles.wrapper} onClick={handleGetContact}>
        <Icon onClick={(event) => {
          event.stopPropagation();
          handleCallToSpecialist();
        }} className={styles.connect_icon}>phone</Icon>
        <ChatInput
          placeholder={t(`${i18n_prefix}input_placeholder`)}
          value={message}
          disabled={!!alert}
          onChange={(event) => {
            if (isLoading) return;
            setMessage(event.currentTarget.value)
          }}
        />
        {!message.trim() && !isLoading
          ? <Icon className={styles.disable_send_icon}>arrow_up</Icon>
          : <div className={styles.send_icon} onClick={handleWriteToSpecialist}>
            {!isLoading ? "arrow_up" : <Lottie
              autoPlay
              loop
              animationData={loaderAnimation}
              className={styles.send_icon_loading}
            />}
          </div>}
      </div>}
    </>}

    {alert && <InAppLayout
      isOpen={openGetContact}
      img={"/illustrations/payment-with-card-2.png"}
      title={alert.title ?? ""}
      description={alert.text}
      onClose={handleToggleGetContact}
    >
      {alert.buttons.map((button, index, array) => {
        if (array.length - 1 === index) {
          return <ButtonDefault
            key={index}
            hasSpace
            onClick={() => handleButtonClick(button.link, button.link_data)}
          >
            {button.title}
          </ButtonDefault>
        }
        return <ButtonDefault
          key={index}
          onClick={() => handleButtonClick(button.link, button.link_data)}
          buttonColor={ButtonColor.GRAY}
          buttonType={ButtonType.WRAPPED}
        >
          {button.title}
        </ButtonDefault>
      })}
    </InAppLayout>}
  </>
};