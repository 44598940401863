import React, {MouseEvent, useEffect, useState} from "react";
import {LabelItem}                              from "shared/model";
import {Icon, Label}                            from "shared/ui";
import cn                                       from "classnames";
import styles                                   from "./styles.module.scss";

interface Props {
  link?: { name?: string, url?: string };
  title: string;
  subtitle?: string;
  controlIcon?: string | React.ReactNode;
  disabled?: boolean;
  labels?: LabelItem[];
  icon?: React.ReactNode | string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onLinkClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  iconColor?: string;
  subtitleError?: boolean;
  children?: React.ReactNode;
  childrenIsOpen?: boolean;
}

export default function List({
  controlIcon,
  title,
  subtitle,
  disabled,
  link,
  labels,
  icon,
  iconColor,
  subtitleError,
  onClick,
  children,
  childrenIsOpen,
  onLinkClick = () => {
  },
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    onClick && onClick(event);
  }

  useEffect(() => {
    if (!children) return;
    if (childrenIsOpen) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }, [childrenIsOpen, children]);

  return <div className={styles.holder}>
    <div className={cn(styles.wrapper, {[styles.disabled]: disabled})} onClick={handleOnClick}>

      {icon && <div className={styles.control} style={{color: `${iconColor}`, marginRight: "1.2rem"}}>{icon}</div>}

      <div className={styles.text}>
        {!labels ? null : <Label labels={labels} />}

        <div className={cn(styles.text_title, {[styles.disabled]: disabled})}>{title}</div>

        {subtitle && <div className={cn(
          styles.text_subtitle,
          {[styles.error]: subtitleError},
          {[styles.disabled]: disabled},
        )}>
          {subtitle}
        </div>}

        {link?.name && <a onClick={onLinkClick} className={styles.text_link}>{link.name}</a>}
      </div>

      {typeof controlIcon === "string" ? <Icon className={styles.control}>{controlIcon}</Icon> : controlIcon}
    </div>

    <div className={styles.children} style={{maxHeight: expanded ? `46px` : "0px"}}>{children}</div>
  </div>
}