import {useEffect, useRef, useState} from "react";

export function useSkeletonThrottling({isLoading}: { isLoading: boolean }) {
  const [extendedLoading, setExtendedLoading] = useState(true);
  const timeoutListener = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isLoading) {
      setExtendedLoading(true);
    } else {
      timeoutListener.current = setTimeout(() => {
        setExtendedLoading(false);
      }, 300);
    }
    return () => {
      clearTimeout(timeoutListener.current);
    }
  }, [isLoading]);

  return {extendedLoading};
}