import api, {BaseResponse, EEndpointTags, RequestMethod, SpecialistProfileUi} from "services/api";
import {
  ProfilePrices,
  RemoveProfileAlert,
  SpecialistMediaList,
  SpecialistPricesListItem,
  SpecialistPricesService, SpecialistProfileDataMediaItem,
  SpecialistProfileItem,
  SpecialistProfileTabsItem,
  SpecialistUpdateWorkPayload,
  SpecialistWorkPriceItem,
  Nullable,
}                                                                             from "shared/model";

const specialistProProfile = api.injectEndpoints({
  endpoints: (build) => ({
    fetchProProfile: build.mutation<BaseResponse<SpecialistProfileItem, SpecialistProfileUi> | undefined, {
      specialist_id: Nullable<number>
    }>({
      query:             ({...params}) => ({
        url:    `pro/profile`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<SpecialistProfileItem, SpecialistProfileUi>) => response.status ? response : undefined,
    }),

    fetchProfileAbout: build.query<{ about: string } | undefined, null>({
      query:             () => ({
        url:    "/pro/profile/about",
        method: RequestMethod.PUT,
      }),
      transformResponse: (response: BaseResponse<{ about: string }>) => response.status ? response.content : undefined,
    }),

    saveAbout: build.mutation<boolean, string>({
      query: (about) => ({
        url:    "pro/profile/about/save",
        method: RequestMethod.PUT,
        body:   {about},
      }),
    }),

    fetchPricesServicesList: build.query<{ services: SpecialistPricesListItem[] } | undefined, null>({
      query:             () => "pro/profile/prices/services",
      transformResponse: (response: BaseResponse<{ services: SpecialistPricesListItem[] }>) =>
                           response.status ? response.content : undefined,
    }),

    fetchSubWorksPricesList: build.query<SpecialistPricesService | undefined, number>({
      query:             (service_id) => ({
        url:    `/pro/profile/prices/all`,
        method: RequestMethod.PUT,
        body:   {service_id},
      }),
      transformResponse: (response: BaseResponse<SpecialistPricesService>) => response.status ? response.content : undefined,
      providesTags:      (result) => result ? [
          ...result.prices.map(({work_id}) => ({
            type: EEndpointTags.SPECIALIST_WORK_PRICES,
            id:   work_id,
          })), {type: EEndpointTags.SPECIALIST_WORK_PRICES, id: "LIST"},
        ]
        : [{type: EEndpointTags.SPECIALIST_WORK_PRICES, id: "LIST"}],
    }),

    fetchCurrentWorkPrices: build.query<SpecialistWorkPriceItem | undefined, number>({
      query:             (work_id) => ({
        url:    `/pro/profile/prices/details`,
        method: RequestMethod.PUT,
        body:   {work_id},
      }),
      transformResponse: (response: BaseResponse<SpecialistWorkPriceItem>) => response.status ? response.content : undefined,
    }),

    updateCurrentWorkPrice: build.mutation<boolean, SpecialistUpdateWorkPayload>({
      query:             ({...params}) => ({
        url:    "pro/profile/prices/update",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response.status,
      invalidatesTags:   (result, _error, {price: {work_id}}) => result ? [
        {type: EEndpointTags.SPECIALIST_WORK_PRICES, id: work_id},
        {type: EEndpointTags.SPECIALIST_WORK_PRICES, id: work_id}] : [],
    }),

    removeCurrentWorkPrice: build.mutation<boolean, number>({
      query:             (work_id) => ({
        url:    "/pro/profile/prices/delete",
        method: RequestMethod.PUT,
        body:   {work_id},
      }),
      transformResponse: (response: BaseResponse<boolean>) => response.status,
      invalidatesTags:   (result, _error, id) => result ? [
        {type: EEndpointTags.SPECIALIST_WORK_PRICES, id},
        {type: EEndpointTags.SPECIALIST_WORK_PRICES, id}] : [],
    }),

    checkSsnFromRemoveProfile: build.mutation<BaseResponse<null>, string>({
      query: (ssn) => ({
        url:    `pro/ssn-phone`,
        method: RequestMethod.PUT,
        body:   {ssn},
      }),
    }),

    removeProfile: build.mutation<BaseResponse<RemoveProfileAlert>, void>({
      query:             () => ({
        url:    `pro/remove-profile`,
        method: RequestMethod.PUT,
      }),
      transformResponse: (response: BaseResponse<RemoveProfileAlert>) => response,
    }),

    fetchSpecialistProPhotoTabs: build.mutation<{ tabs: SpecialistProfileTabsItem[] } | undefined, {
      specialist_id: Nullable<number>
    }>({
      query:             () => ({
        url:    `pro/profile/photos/tabs`,
        method: RequestMethod.PUT,
      }),
      transformResponse: (response: BaseResponse<{
        tabs: SpecialistProfileTabsItem[]
      }>) => response.status ? response.content : undefined,
    }),

    fetchSpecialistProPhotosList: build.mutation<SpecialistMediaList | undefined, {
      specialist_id: Nullable<number>,
      tab_id?: Nullable<number>,
      limit?: number,
      page?: number,
    }>({
      query:             ({...params}) => ({
        url:    `pro/profile/photos`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<SpecialistMediaList>) => response.status ? response.content : undefined,
    }),

    fetchProfileProPrices: build.mutation<ProfilePrices | undefined, {
      specialist_id?: number,
      service_id: Nullable<number>
    }>({
      query:             ({...params}) => ({
        url:    `/pro/profile/prices`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<ProfilePrices>) => response.status ? response.content : undefined,
    }),

    restoreProfileCartInit: build.mutation<{ payment_id: string } | undefined, void>({
      query:             () => ({
        url:    "pro/balance/purchase/profile-restore/cart/init",
        method: RequestMethod.PUT,
      }),
      transformResponse: (response: BaseResponse<{ payment_id: string }>) =>
                           response.status ? response.content : undefined,
    }),

    profileMediaCreate: build.mutation<boolean, { description?: string, media: SpecialistProfileDataMediaItem[] }>({
      query:             ({...params}) => ({
        url:    "pro/profile/photos/create",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response.status,
    }),

    profileMediaDelete: build.mutation<boolean, { photos: string[] }>({
      query:             ({...params}) => ({
        url:    "pro/profile/photos/delete",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response.status,
    }),

    uploadAvatar: build.mutation<boolean, { digest: string }>({
      query: ({...params}) => ({
        url:    "pro/profile/avatar/update",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),
  }),
});

export const {
  useSaveAboutMutation,
  useFetchPricesServicesListQuery,
  useFetchProfileAboutQuery,
  useFetchSubWorksPricesListQuery,
  useFetchCurrentWorkPricesQuery,
  useUpdateCurrentWorkPriceMutation,
  useRemoveCurrentWorkPriceMutation,
  useCheckSsnFromRemoveProfileMutation,
  useFetchSpecialistProPhotosListMutation,
  useRemoveProfileMutation,
  useFetchProfileProPricesMutation,
  useFetchSpecialistProPhotoTabsMutation,
  useFetchProProfileMutation,
  useRestoreProfileCartInitMutation,
  useProfileMediaCreateMutation,
  useProfileMediaDeleteMutation,
  useUploadAvatarMutation,
} = specialistProProfile;