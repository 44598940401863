import * as React                                                 from "react";
import {FC}                                                       from "react";
import {useNavigate}                                              from "react-router-dom";
import {Props}                                                    from "components/WrongPhoneNumberInApp";
import {TextBlock, ButtonDefault, InApp, ButtonColor, ButtonType} from "shared/ui";
import styles                                                     from "./styles.module.scss";
import {useTranslation}                                           from "react-i18next";


const SsnExistInApp: FC<Props> = ({open, onClose}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleBackSelectRegistration = () => navigate("/registration");

  return <InApp hasHeader open={open} onClose={onClose}>
    <div className={styles.inApp_wrapper}>

      <div className={styles.inApp_image}>
        <img src="/illustrations/searching-6.svg" alt="img" />
      </div>

      <TextBlock
        title={t("in_app.wrong_number.title")}
        description={t("in_app.wrong_number.description")}
      />

      <div className={styles.inApp_buttons}>
        <ButtonDefault
          buttonType={ButtonType.WRAPPED}
          buttonColor={ButtonColor.GRAY}
          onClick={onClose}
        >
          {t("in_app.wrong_number.button.try_again")}
        </ButtonDefault>

        <ButtonDefault
          hasSpace
          onClick={handleBackSelectRegistration}
        >
          {t("in_app.wrong_number.button.cancel")}
        </ButtonDefault>
      </div>
    </div>
  </InApp>
}

export default SsnExistInApp;