import * as React              from "react";
import {Route, Routes}         from "react-router-dom";
import {PurchaseListPage}      from "pages/Specialist/Tariffs/PurchaseListPage";
import {PeriodsListPage}       from "pages/Specialist/Tariffs/PeriodsListPage";
import {TariffOptionsPage}     from "pages/Specialist/Tariffs/TariffOptionsPage";
import PurchaseCatalogueRoutes from "./PurchaseCatalogueRoutes";


const SpecialistTariffsRoutes = () => {
  return <Routes>
    <Route path="/" element={<PurchaseListPage />} />
    <Route path="/periods" element={<PeriodsListPage />} />
    <Route path="/options/:period/:is_subscribe?" element={<TariffOptionsPage />} />
    <Route path="/catalogue/*" element={<PurchaseCatalogueRoutes />} />
  </Routes>
};

export default SpecialistTariffsRoutes;