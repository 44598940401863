import React, {useEffect, useState}              from "react";
import {useTranslation}                          from "react-i18next";
import {PageLayout}                              from "entities/general";
import {SkeletonLayout}                          from "entities/skeleton";
import {LoansPageType, InitialPage, StateScreen} from "entities/specialist/cashLoans";
import {Icon, ButtonDefault}                     from "shared/ui";
import {Nullable, NativeActions}                 from "shared/model";
import {
  useNativeHandler,
  setActionForNative,
  useSkeletonThrottling,
  useSetHeaders,
}                                                from "shared/lib";
import {
  useGetSupportProgramInfoMutation,
  useInitSupportProgramMutation,
  useSupportProgramIssueMutation,
}                                                from "./api";
import {selectEntryPoint}                        from "store/features";
import {useAppSelector}                          from "store";
import {loaderAnimation}                         from "assets/lotties";
import Lottie                                    from "lottie-react";
import styles                                    from "./styles.module.scss";


const i18n_prefix = "page.customers.cash_loans."
export default function CashLoans() {
  const {t} = useTranslation();
  const entryPoint = useAppSelector(selectEntryPoint);

  const {isSuccess} = useSetHeaders();
  const [fetchLoansInfo, {data, isLoading: loansInfoIsLoading}] = useGetSupportProgramInfoMutation();
  const [initCashLoan, {
    data:      initCashLoanResponse,
    isLoading: initCashLoanIsLoading,
  }] = useInitSupportProgramMutation();
  const [fetchCashLoanIssue, {
    data:      cashLoanIssueResponse,
    isLoading: cashLoansIssuesIsLoading,
  }] = useSupportProgramIssueMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading: loansInfoIsLoading});
  const [cardId, setCardId] = useState<Nullable<number>>(null);
  const [creditId, setCreditId] = useState<Nullable<number>>(null);
  const [pageState, setPageState] = useState<LoansPageType>(LoansPageType.INITIAL);
  const [responseMessage, setResponseMessage] = useState("");

  const handleInitClick = () => {
    setPageState(LoansPageType.LOADING);
    initCashLoan();

    const paymentAmount = data?.analytics?.["payment amount"];
    const creditType = ["naimi"];

    if (entryPoint) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist credit application opened",
        properties: {
          "entry point":    entryPoint,
          "credit type":    creditType,
          "payment amount": paymentAmount,
        },
      });
    }
  };

  const handleOpenCardsClick = () => {
    setActionForNative(NativeActions.OPEN_CARDS, ({footer_text: data?.content?.details?.description}));

    if (initCashLoanResponse && initCashLoanResponse.analytics) {
      const creditType = initCashLoanResponse.analytics?.["credit type"];
      const paymentAmount = initCashLoanResponse.analytics?.["payment amount"];
      const creditDetails = initCashLoanResponse.analytics?.["credit details"];

      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist credit initiated",
        properties: {
          "credit type":    creditType,
          "payment amount": paymentAmount,
          "credit details": creditDetails,
        },
      });
    }
  }

  const handleOnCloseClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleRefetchLoansInfo = () => {
    window.setTimeout(() => {
      fetchLoansInfo();
    }, 1000)
  }

  useEffect(() => {
    if (isSuccess) {
      fetchLoansInfo();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (data && !data?.content.details) {
      setPageState(LoansPageType.EMPTY_CATEGORIES);
    }
    if (data && data.analytics) {
      setPageState(LoansPageType.INITIAL);

      const creditDetails = data?.analytics?.["credit details"];
      const purchaseDetails = data?.analytics?.["purchase details"];

      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist supprog opened",
        properties: {
          "credit details":   creditDetails,
          "purchase details": purchaseDetails,
        },
      });
    }
  }, [data, loansInfoIsLoading]);

  useEffect(() => {
    if (initCashLoanResponse) {
      if (initCashLoanResponse?.content.status === false) {
        setPageState(LoansPageType.DENIED);
        setResponseMessage(initCashLoanResponse.content.message);
      }
      if (initCashLoanResponse?.content.status === true) {
        setPageState(LoansPageType.SELECT_CARD);
        setResponseMessage(initCashLoanResponse.content.message);
        setCreditId(initCashLoanResponse.content.credit_id);
      }
    }
  }, [initCashLoanResponse]);

  useEffect(() => {
    if (cardId && creditId) {
      fetchCashLoanIssue({card_id: cardId, credit_id: creditId});
    }
  }, [cardId, creditId]);

  useEffect(() => {
    if (cashLoanIssueResponse && cashLoanIssueResponse.content.status) {
      setPageState(LoansPageType.SUCCESS);
      setResponseMessage(cashLoanIssueResponse.content.message);
    }
    if (cashLoanIssueResponse && !cashLoanIssueResponse.content.status) {
      setPageState(LoansPageType.ERROR);
      setResponseMessage(cashLoanIssueResponse.content.message);
    }
  }, [cashLoanIssueResponse]);

  useNativeHandler<{ card_id: number }>("tariffs", NativeActions.OPEN_CARDS, (data) => {
    return setCardId(data.card_id);
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return handleOnCloseClick();
  });

  useNativeHandler<{ is_changed: boolean }>("tariffs", NativeActions.OPEN_NEWSLINE_FILTERS, data => {
    if (data.is_changed) {
      return handleRefetchLoansInfo();
    }
  });

  const footer = pageState !== LoansPageType.INITIAL ? null : <div className={styles.footer}>
    {extendedLoading
      ? <div className={styles.footer_loading}>
        <SkeletonLayout height={62} />
        <SkeletonLayout height={44} />
      </div>
      : <>
        <div className={styles.priceHolder}>
          <span className={styles.price}>{data?.content?.details?.button.title ?? ""}</span>

          <span className={styles.text}>{data?.content?.details?.button.subtitle ?? ""}</span>
        </div>

        <ButtonDefault onClick={handleInitClick}>{t(`${i18n_prefix}button` ?? "")}</ButtonDefault>
      </>}
  </div>

  const loadingTitle = initCashLoanIsLoading
    ? t(`${i18n_prefix}loading_screen.check.title`)
    : t(`${i18n_prefix}loading_screen.loan_process.title`);

  const loadingDescription = initCashLoanIsLoading
    ? t(`${i18n_prefix}loading_screen.check.description`)
    : t(`${i18n_prefix}loading_screen.loan_process.description`);

  return <PageLayout
    headerTitle={pageState === LoansPageType.INITIAL ? data?.content?.details?.title : ""}
    rightIcon={<Icon onClick={handleOnCloseClick}>close</Icon>}
    footer={(!initCashLoanIsLoading && !cashLoansIssuesIsLoading && data?.content.details) ? footer : null}
    isLoading={extendedLoading}
  >
    {initCashLoanIsLoading || cashLoansIssuesIsLoading
      ? <LoadingScreen title={loadingTitle} description={loadingDescription} />
      : <>
        {pageState === LoansPageType.INITIAL && data?.content.details &&
          <InitialPage options={data?.content?.details?.options} isLoading={extendedLoading} />}

        <StateScreen
          state={pageState}
          message={responseMessage}
          onOpenCardClick={handleOpenCardsClick}
        />
      </>}
  </PageLayout>
};

function LoadingScreen({title, description}: { title: string; description: string }) {
  return <div className={styles.centered_content}>
    <div className={styles.loading}>
      <Lottie
        autoPlay
        animationData={loaderAnimation}
        style={{width: 40, height: 40, justifySelf: "center"}}
        loop
      />

      <div className={styles.text_block}>
        <span className={styles.title_text}>{title}</span>
        <span className={styles.description_text}>{description}</span>
      </div>
    </div>
  </div>
}