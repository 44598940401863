import api, {BaseResponse, RequestMethod}                                         from "../api";
import {ReviewFilterTypes, ReviewTabs, ReviewsResponse, SpecialistReviewsRequest} from "./types";
import {Nullable}                                                                 from "shared/model";

const reviewsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getReviewTabs: build.query<ReviewTabs | undefined, { id: number, serviceId?: Nullable<number> }>({
      query:             ({id, serviceId}) => ({
        url:    `app/specialist/reviews/tabs`,
        method: RequestMethod.PUT,
        body:   {
          specialist_id: id,
          service_id:    serviceId,
        },
      }),
      transformResponse: (response: BaseResponse<ReviewTabs>) => response.status ? response.content : undefined,
    }),

    getSpecialistReviews: build.mutation<ReviewsResponse | undefined, SpecialistReviewsRequest>({
      query:             ({...params}) => ({
        url:    `app/specialist/reviews`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<ReviewsResponse>) => response.status ? response.content : undefined,
    }),

    getSelfReviewsTabs: build.query<ReviewTabs | undefined, { id: number, service_id: Nullable<number> }>({
      query:             ({id, service_id}) => ({
        url:    `pro/profile/reviews/tabs`,
        method: RequestMethod.PUT,
        body:   {
          specialist_id: id,
          service_id,
        },
      }),
      transformResponse: (response: BaseResponse<ReviewTabs>) => response.status ? response.content : undefined,
    }),

    getSpecialistProfileReviews: build.mutation<ReviewsResponse | undefined, SpecialistReviewsRequest>({
      query:             ({...params}) => ({
        url:    "pro/profile/reviews",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<ReviewsResponse>) => response.status ? response.content : undefined,
    }),

    getClientReviews: build.mutation<ReviewsResponse | undefined, {
      client_id?: number;
      page?: number;
      filter: ReviewFilterTypes,
    }>({
      query:             ({...params}) => ({
        url:    `pro/client/reviews`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<ReviewsResponse>) => response.status ? response.content : undefined,
    }),
  }),
});

export const {
  useGetSpecialistReviewsMutation,
  useGetReviewTabsQuery,
  useLazyGetReviewTabsQuery,
  useGetClientReviewsMutation,
  useGetSpecialistProfileReviewsMutation,
  useGetSelfReviewsTabsQuery,
} = reviewsApi;