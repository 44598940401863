import {
  useGetSpecialistProfileReviewsMutation,
  useGetSpecialistReviewsMutation,
}                 from "services/reviews";
import {Nullable} from "shared/model";
import {AppMode}  from "store/features";

export const fetchReviews = ({appMode}: { appMode: Nullable<string> }) => {
  const [getReviews, {data: reviewsFromTab}] = (() => {
    const [getReviews, {data: reviewsFromTab}] = useGetSpecialistProfileReviewsMutation();
    const [getReviewsModeClient, {data: reviewsFromTabModeClient}] = useGetSpecialistReviewsMutation();


    if (appMode === AppMode.SPECIALIST) {
      return [getReviews, {data: reviewsFromTab}];
    }
    return [getReviewsModeClient, {data: reviewsFromTabModeClient}];
  })();

  return {getReviews, reviewsFromTab};
}