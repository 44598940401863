import {PurchaseCatalogueListPage}         from "pages/Specialist/PurchaseCatalogue/PurchaseCatalogueListPage";
import {PurchaseCatalogueServicesListPage} from "pages/Specialist/PurchaseCatalogue/PurchaseCatalogueServicesListPage";
import {Route, Routes}                     from "react-router-dom";

const PurchaseCatalogueRoutes = () => {
  return <Routes>
    <Route path="/" element={<PurchaseCatalogueListPage />} />
    <Route path="/services" element={<PurchaseCatalogueServicesListPage />} />
  </Routes>
}

export default PurchaseCatalogueRoutes;