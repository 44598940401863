import React, {memo, useEffect, useRef, useState} from "react";
import PullToRefresh                              from "react-simple-pull-to-refresh";
import Lottie                                     from "lottie-react";
import {PageHeader, Toast}                        from "shared/ui";
import {loaderAnimation}                          from "assets/lotties";
import cn                                         from "classnames";
import styles                                     from "./styles.module.scss";


interface Props {
  isLoading?: boolean;
  hasSpaceChildren?: boolean;
  headerTitle?: string;
  headerSubtitle?: string;
  leftIcon?: React.ReactNode | string;
  rightIcon?: React.ReactNode | string;
  rightIcon2?: React.ReactNode | string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  refetch?: () => void;
  toastPosition?: number;
  bgColor?: string;
}

const PageLayout = memo(({
  isLoading,
  // hasSpaceChildren,
  headerTitle,
  headerSubtitle,
  leftIcon,
  rightIcon,
  rightIcon2,
  children,
  footer,
  refetch,
  toastPosition,
  bgColor,
}: Props) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [hasFooterBorder, setHasFooterBorder] = useState(false);

  const handleRefresh = async () => {
    if (typeof refetch === "undefined") return;
    try {
      await refetch();
    } catch (error) {
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      const content = contentRef.current;

      const observer = new ResizeObserver((entries) => {
        if (entries[0]) {
          const isContentScrollable = content.scrollHeight > content.clientHeight;
          setHasFooterBorder(isContentScrollable);
        }
      });

      observer.observe(content);

      const isContentInitiallyScrollable = content.scrollHeight > content.clientHeight;
      setHasFooterBorder(isContentInitiallyScrollable);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return <PullToRefresh
    isPullable={typeof refetch !== "undefined"}
    onRefresh={handleRefresh}
    pullDownThreshold={80}
    pullingContent={<div style={{display: "none"}} />}
    refreshingContent={<div className={styles.refresh_content}>
      <div className={styles.loading_holder}>
        <Lottie
          autoPlay
          animationData={loaderAnimation}
          style={{width: 32, height: 32}}
          loop
        />
      </div>
    </div>}
  >
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <PageHeader
          hasSpace={false}
          isLoading={isLoading}
          title={headerTitle}
          subtitle={headerSubtitle}
          icon={leftIcon}
          rightIcon={rightIcon}
          rightIcon2={rightIcon2}
        />
      </div>

      <div ref={contentRef} className={styles.content} style={{backgroundColor: bgColor ? bgColor : undefined}}>
        {children}
      </div>

      {footer && <div className={cn(styles.footer, {[styles.has_border]: hasFooterBorder})}>{footer}</div>}

      <Toast bottomPosition={toastPosition} />
    </div>
  </PullToRefresh>
});

export default PageLayout;