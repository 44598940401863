import React             from "react";
import Lottie            from "lottie-react";
import {ButtonColor}     from "./ButtonColor";
import {ButtonType}      from "./ButtonType";
import BaseButtonProps   from "./BaseButtonProps";
import {loaderAnimation} from "../../../assets/lotties";
import cn                from "classnames";
import styles            from "./styles.module.scss";


export default function ButtonBase({
  buttonColor = ButtonColor.PRIMARY,
  buttonType = ButtonType.DEFAULT,
  hasSpace = false,
  loading = false,
  children,
  disabled,
  buttonRound,
  buttonIcon,
  className,
  ...props
}: BaseButtonProps) {
  return <button
    className={cn(
      className,
      styles.button,
      {[`${styles[buttonColor]}`]: buttonColor},
      {[`${styles[buttonType]}`]: buttonType},
      {[styles.space]: hasSpace},
      {[styles.disabled]: disabled},
      {[styles.loading_state]: loading},
      {[styles.round]: buttonRound},
      {[styles.icon_type]: buttonIcon},
    )}
    {...{...props}}
  >
    {loading && <div className={styles.loading_wrapper}>
      <Lottie
        autoPlay
        animationData={loaderAnimation}
        loop
        className={cn(
          styles.loading,
          {[`${styles[buttonColor]}`]: buttonColor},
          {[`${styles[buttonType]}`]: buttonType},
          {[styles.type_icon]: buttonIcon},
        )}
      />
    </div>}

    {children}

  </button>
}