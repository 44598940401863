import * as React           from "react";
import {ManualRegistration} from "pages/Registration";
import {setActionForNative} from "shared/lib";
import {NativeActions}      from "shared/model";
import {useTranslation}     from "react-i18next";


const egovLink = "https://egov.kz/cms/ru/services/pass011_mvd";

const NotCitizen = () => {
  const {t} = useTranslation();

  const data = {url: egovLink};

  const handleInnerLinkClick = () => setActionForNative(NativeActions.OPEN_IN_APP_BROWSER, data);

  const subtitle = <div>
    {t("page.registration.non_resident.text.chunk_1")} <a
    onClick={handleInnerLinkClick}>{t("page.registration.non_resident.text.chunk_2")}</a>
  </div>

  return <ManualRegistration
    type={"nonResident"}
    navSubtitle={t("page.registration.non_resident.header.description")}
    ssnSubtitle={subtitle}
  />
}

export default NotCitizen;