import {createSlice, type PayloadAction}          from "@reduxjs/toolkit";
import {Nullable}                                 from "shared/model";
import {RegistrationMethodType, RegistrationType} from "services/registration";


export interface RegistrationProfile {
  avatar?: string;
  birth_date?: string;
  first_name?: string;
  last_name?: string;
  ssn?: string;
  state?: ProfileState;
  phone?: string;
}

export enum ProfileState {
  GUEST = "guest",
  REGISTERED = "registered",
  APPROVED = "approved",
}

const initialState: {
  ssn: string;
  registrationType: Nullable<RegistrationType>;
  biometricUrl: Nullable<string>;
  errorMessage: Nullable<string>;
  step?: Nullable<number>;
  methodType: Nullable<RegistrationMethodType>;
  registrationInfo: Nullable<RegistrationProfile>;
  freedomData: Nullable<{ url?: string, substring?: string }>;
  isNotMeFreedom: boolean;
} = {
  ssn:              "",
  biometricUrl:     null,
  registrationType: null,
  errorMessage:     null,
  step:             null,
  methodType:       null,
  registrationInfo: null,
  freedomData:      null,
  isNotMeFreedom:   false,
};

const slice = createSlice({
  name:     "registration",
  initialState,
  reducers: {
    setSsn(state, action: PayloadAction<string>) {
      state.ssn = action.payload;
    },
    setRegistrationType(state, action: PayloadAction<Nullable<RegistrationType>>) {
      state.registrationType = action.payload;
    },
    setBiometricUrl(state, action: PayloadAction<Nullable<string>>) {
      state.biometricUrl = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<Nullable<string>>) {
      state.errorMessage = action.payload;
    },
    setStep(state, action: PayloadAction<Nullable<number>>) {
      state.step = action.payload;
    },
    setMethodType(state, action: PayloadAction<Nullable<RegistrationMethodType>>) {
      state.methodType = action.payload;
    },
    setRegistrationInfo(state, action: PayloadAction<Nullable<RegistrationProfile>>) {
      state.registrationInfo = action.payload;
    },
    setFreedomData(state, action: PayloadAction<Nullable<{ url?: string, substring?: string }>>) {
      state.freedomData = action.payload
    },
    setIsNotMeFreedom(state, action: PayloadAction<boolean>) {
      state.isNotMeFreedom = action.payload
    },
  },
})

export const {
  setRegistrationType,
  setBiometricUrl,
  setErrorMessage,
  setStep,
  setMethodType,
  setRegistrationInfo,
  setFreedomData,
  setIsNotMeFreedom,
  setSsn,
} = slice.actions;
export default slice.reducer;

export const selectSsn = (state: { registration: typeof initialState }) => state.registration.ssn;

export const selectRegistrationType = (state: {
  registration: typeof initialState
}) => state.registration.registrationType;

export const selectBiometricUrl = (state: {
  registration: typeof initialState
}) => state.registration.biometricUrl;

export const selectErrorMessage = (state: {
  registration: typeof initialState
}) => state.registration.errorMessage;

export const selectStep = (state: {
  registration: typeof initialState
}) => state.registration.step;

export const selectMethodType = (state: {
  registration: typeof initialState
}) => state.registration.methodType;

export const selectRegistrationInfo = (state: {
  registration: typeof initialState
}) => state.registration.registrationInfo;

export const selectFreedomData = (state: {
  registration: typeof initialState
}) => state.registration.freedomData;

export const selectIsNotMeFreedom = (state: {
  registration: typeof initialState
}) => state.registration.isNotMeFreedom;
