import React       from "react";
import {LabelItem} from "shared/model";
import cn          from "classnames";
import styles      from "./styles.module.scss";

enum ColorNames {
  GRAY = "gray",
  PRIMARY = "primary",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  ACCENT1 = "accent_1",
  ACCENT2 = "accent_2",
  ACCENT3 = "accent_3",
  SHADES = "shades",
  GRAY_DARK = "gray_dark"
}


export interface Props {
  labels: LabelItem[];
  clasNames?: string;
}

function defineColor(colorName: ColorNames) {
  if (!colorName) return "";
  switch (colorName) {
    case ColorNames.GRAY:
      return "#EAEAEF"
    case ColorNames.PRIMARY:
      return "#0A84FF"
    case ColorNames.SUCCESS:
      return "#32D74B"
    case ColorNames.WARNING:
      return "#FFD60A"
    case ColorNames.ERROR:
      return "#FF1507"
    case ColorNames.ACCENT1:
      return "#840AFE"
    case ColorNames.ACCENT2:
      return "#FE0A84"
    case ColorNames.ACCENT3:
      return "#FE840A"
    case ColorNames.GRAY_DARK:
      return "#828291"
    case ColorNames.SHADES:
      return ""
  }
}

export default function Label({labels, clasNames}: Props) {
  return <div className={cn(styles.wrapper, clasNames)}>
    {labels.map((label, index) => <div
      key={index}
      className={styles.label_wrapper}
      style={{backgroundColor: label.style ? defineColor(label.style as ColorNames) : ""}}
    >
      {label.icon && <div className={styles.label_icon}>{label.icon}</div>}

      {label.text ?? label.title}

      {label.rightIcon && <div className={styles.label_icon}>{label.rightIcon}</div>}
    </div>)}
  </div>
}