import {useEffect}     from "react";
import {NativeActions} from "shared/model";

function parseOrReturn(data: any) {
  if (typeof data === "string") {
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  }
  return data;
}

export function useNativeHandler<T>(module: string | null, action: NativeActions, callback: (data: T) => void) {
  useEffect(() => {
    function handleNativeMessage(event: any) {
      const {module: eventModule, action: eventAction, data} = event.detail;
      const parsedData = parseOrReturn(data);

      if (eventAction === action && (!module || module === eventModule)) {
        callback(parsedData);
      }
    }

    window.addEventListener("nativeMessage", handleNativeMessage);

    return () => {
      window.removeEventListener("nativeMessage", handleNativeMessage);
    }
  }, [module, action, callback]);
}