import * as React                                          from "react";
import {useEffect, useState}                               from "react";
import {useNavigate}                                       from "react-router-dom";
import {useTranslation}                                    from "react-i18next";
import {
  SpecialistPaymentInformationCard,
}                                                          from "components/SpecialistPaymentInformationCard";
import {LoadingScreen, PageLayout}                         from "entities/general";
import {SkeletonLayout}                                    from "entities/skeleton";
import {Icon, ButtonDefault, Tabs}                         from "shared/ui";
import {NativeActions, ButtonRenderType}                   from "shared/model";
import {
  useNativeHandler,
  useSetHeaders,
  setActionForNative,
  useSkeletonThrottling,
  useCheckPaymentStatus,
  useOpenPayments,
}                                                          from "shared/lib";
import {useAppDispatch, useAppSelector}                    from "store";
import {
  AppMode,
  PurchaseType,
  selectAppMode,
  selectEntryPoint,
  selectIsMyCreditsOpened,
  selectPurchaseIsLoading,
  setIsMyCredits,
}                                                          from "store/features";
import {useCreditCartInitMutation, useGetCreditsListQuery} from "services/specialists/credits";
import styles                                              from "./styles.module.scss";


const i18n_prefix = "page.specialist.credits."
const Credits = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);
  const entryPoint = useAppSelector(selectEntryPoint);
  const myCreditsIsOpened = useAppSelector(selectIsMyCreditsOpened);
  const appMode = useAppSelector(selectAppMode);

  const [currentTab, setCurrentTab] = useState<number>(1);

  const {isSuccess} = useSetHeaders();

  const {
    data:       credits,
    isLoading:  creditsIsLoading,
    isFetching: creditsIsFetching,
    refetch,
  } = useGetCreditsListQuery({id: currentTab, appMode: appMode as AppMode}, {skip: !isSuccess});

  const {extendedLoading} = useSkeletonThrottling({isLoading: creditsIsLoading || creditsIsFetching});
  const [creditCartInit, {data: creditCartInitResponse}] = useCreditCartInitMutation();
  const {paymentStatusLoading} = useCheckPaymentStatus({refetch});

  const handleTabButtonClick = (id: number, type: ButtonRenderType) => {
    switch (type) {
      case ButtonRenderType.CREDIT_PREPAYMENT:
        return creditCartInit({id, type});
      case ButtonRenderType.CREDIT_ERROR:
        return creditCartInit({id, type});
    }
  }

  const handleBuyTariffClick = () => navigate("/tariffs");

  const handleBackClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleSetCurrentTabClick = (id: number) => setCurrentTab(id);

  useOpenPayments({
    paymentId: creditCartInitResponse?.content.payment_id,
    type:      PurchaseType.CREDIT_PREPAYMENT,
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (purchaseInProgress) return;
    return setActionForNative(NativeActions.TERMINATE);
  });

  useEffect(() => {
    if (myCreditsIsOpened) return;
    const analytics = credits?.analytics?.["credit details"];
    if (analytics && entryPoint) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist my credits opened",
        properties: {
          "entry point":    entryPoint,
          "credit details": analytics,
        },
      });
      dispatch(setIsMyCredits(true));
    }
  }, [credits, dispatch, entryPoint, myCreditsIsOpened]);

  const showHeader = (appMode === AppMode.CLIENT && credits?.content?.credits && credits.content.credits.length > 0)
    || (appMode === AppMode.SPECIALIST && currentTab === 2 && credits?.content?.credits && credits.content.credits.length > 0);

  const footer = <>{showHeader && <div className={styles.footer}>{t(`${i18n_prefix}footer`)}</div>}</>

  return <>
    {paymentStatusLoading && <LoadingScreen />}

    <PageLayout
      headerTitle={t(`${i18n_prefix}header`)}
      leftIcon={<Icon onClick={handleBackClick}>keyboard_arrow_left</Icon>}
      footer={footer}
    >
      <>
        {appMode === AppMode.SPECIALIST && <div className={styles.tabs}>
          {extendedLoading ? <>{[...Array(2)].map((_, index) =>
              <SkeletonLayout width={80} containerClass="mgr-2" borderRadius={30} key={index} height={34} />)}
            </>

            : credits?.content.tabs.map((tab) =>
              <Tabs
                key={tab.id}
                activeTab={currentTab === tab.id}
                onClick={() => handleSetCurrentTabClick(tab.id)}
              >
                {tab.name}
              </Tabs>)}
        </div>}

        {credits?.content.credits && !credits?.content.credits.length && !extendedLoading
          && <div className={styles.empty_credits}>
            <div className={styles.empty_icon}>add_to_cart</div>

            <div className={styles.text_block}>
              <div className={styles.text_block_header}>{t(`${i18n_prefix}plug.title`)}</div>

              <div className={styles.text_block_description}>{t(`${i18n_prefix}plug.subtitle`)}</div>
            </div>

            {appMode === AppMode.SPECIALIST &&
              <ButtonDefault
                onClick={handleBuyTariffClick}
                buttonRound
              >
                {t(`${i18n_prefix}plug.button`)}
              </ButtonDefault>}
          </div>}

        {extendedLoading ? <SkeletonLayout height={148} />

          : credits?.content.credits.map((credit) =>
            <SpecialistPaymentInformationCard
              key={credit.id}
              type="credit"
              title={credit.title}
              subtitle={credit.subtitle}
              description={credit.description}
              color={credit.color}
              buttons={credit.buttons}
              onClick={handleTabButtonClick}
            />)}
      </>
    </PageLayout>
  </>
};

export default Credits;